@mixin font-face($name, $path, $format) {
  @font-face {
    font-family: $name;
    font-style: normal;
    src: url($path) format($format);
  }
}

@include font-face("Muli", "../fonts/Muli/Muli-Regular.woff", "woff");
@include font-face("MuliLight", "../fonts/Muli/Muli-Light.woff", "woff");
@include font-face("MuliBold", "../fonts/Muli/Muli-Bold.woff", "woff");
@include font-face("MuliSemiBold", "../fonts/Muli/Muli-SemiBold.woff", "woff");
@include font-face("MuliExtraBold", "../fonts/Muli/Muli-ExtraBold.woff", "woff");
@include font-face("MuliBlack", "../fonts/Muli/Muli-Black.woff", "woff");
@include font-face("ITC", "../fonts/ITCAvantGardeStd-Bold.woff", "woff");