/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-04
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.footer-controller {
  .main-nav {
    position: fixed;
    width: 100%;
    justify-content: center;
background-color: rgba(31, 31, 31, 1);
    align-items: center;
    z-index: 5;

    @media (min-width:768px) and (max-width:1280px) {
      display: flex !important;
    }

    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    @include media-breakpoint-down(sm) {
      position: unset;
      margin-top: 0px;
      width: 100%;
      overflow-x: auto;
      box-sizing: content-box;
      z-index: 1000;
      white-space: nowrap;
      display: block;
      overflow-y: hidden;
      scrollbar-width: auto;
      scrollbar-width: thin;
      scrollbar-width: none;
      overflow: hidden;

      &:-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }

      & > div {
        display: block;
        margin-bottom: -16px;
        overflow-x: scroll;
      }
    }

    li {
      text-align: center;

      @include media-breakpoint-down(md) {
        display: table-cell;
        position: relative;
      }

      .nav-link {
        color: white;
        height: 100% !important;
        padding: 1rem 1.8rem;
        font-family: 'Muli';
        font-size: 18px;
        font-weight: bold;
        display: block;
        width: 100%;
        white-space: nowrap;
        display: flex;
        align-items: center;
        align-content: center;
        border-radius: 0;

        @include media-breakpoint-down(md) {
          padding: 15px 18px;
        }
      }
    }

    .section-Name {
      font-size: 14pt;
      margin-right: 50px;
      color: white;
      height: 100% !important;
      vertical-align: middle;
      padding: 1rem .5rem;

      @media (min-width: 2500px) {
        font-size: 18pt;
      }

      @include media-breakpoint-down(sm) {
        font-size: 11pt;
        margin-right: 50px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 10pt;
        margin-right: 25px;
        display: none;
      }
    }

    a {
      height: 100%;
      color: white;
    }

    .active {
      background: transparent;
      border-bottom: 3px solid #4cb4e7 !important;
    }
  }
}