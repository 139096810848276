body.force-untransparent {
  .main-navbar.navbar.transparent {
    background-color: #171717 !important;
    background-image: none;
  }
  @media (min-width: 1200px) {
    .live-video {
      margin-top: 97px;
      height: calc((100vw / (16 / 9)) * 0.85);
    }
  }
  @media (min-width: 561px) and (max-width: 1199px) and (orientation: landscape) {
    .live-video {
      margin-top: 60px;
      height: calc((100vw / (16 / 9)) * 0.85);
    }
  }
  @media (min-width: 561px) and (max-width: 1199px) and (orientation: portrait) {
    .live-video {
      margin-top: 60px;
      height: calc((100vw / (16 / 9)) * 0.85);
    }
  }
  .open-chatbox {
    white-space: nowrap;
    color: white;
  }
}
.live-video {
  height: calc((100vw / (16 / 9)) *.95);
}
.live-now, .event-page {
  position: relative;
  // margin-top: 80px;
  width: 100vw;
  height: calc(100vw / (16 / 9));
  max-height: calc(100vh - 0px);
  overflow: hidden;
  background-color: #000;
  display: flex;
  align-items: stretch;

  @media (max-width: 560px) {
    height: auto;
    flex-direction: column;
    max-height: none;
    margin-top: 0px;
  }

  .video-container {
    flex: 1;

    @media (max-width: 560px) {
      height: calc(100vw / (16 / 9));
    }
  }

  .open-chatbox {
    position: absolute;
    right: 0;
    top: 70px;
    background-color: $darker-gray;
    padding: 14px 24px;
    color: $radivision-blue;
    cursor: pointer;
    border-radius: 8px 0 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 160px;
    max-width: 260px;
    z-index: 10;

    @media (max-width: 769px) {
      width: 30%;
    }

    @media (max-width: 560px) {
      width: 55%;
      top: 8px;
      padding: 8px 16px;
    }

    .icon {
      position: relative;
      margin-right: 16px;

      .badge {
        background-color: $radivision-blue;
        position: absolute;
        right: -12px;
        top: -12px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        border: 4px solid $darker-gray;
      }

      svg {
        fill: #fff;
      }

      span {
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
}
.comments-container {
  background-color: #181818;
  font-family: "PT Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial",
    sans-serif;
  color: #555f77;
  -webkit-font-smoothing: antialiased;
  width: 30%;
  max-width: 386px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(80% - 0px);
  margin-top: 0px;

  input,
  textarea {
    outline: none;
    border: none;
    display: block;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    font-family: "PT Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial",
      sans-serif;
    font-size: 1rem;
    color: #555f77;
  }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #ced2db;
  }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #ced2db;
  }
  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #ced2db;
  }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #ced2db;
  }

  p {
    line-height: 1.3125rem;
  }

  .comment-box-head {
    display: flex;
    flex-direction: column;
    padding: 12px 12px;
    padding-right: 28px;
    background: #212121;
    position: relative;
    z-index: 1;
    
    .cmtbx-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      svg {
        width: auto;
        height: 18px;
        fill: #aaa;
        transition: all 0.3s;
        cursor: pointer;
  
        &:hover {
          fill: #fff;
        }
      }
  
      span {
        margin-left: 8px;
        color: white;
      }
  
      .cmnt-head-bk {
        cursor: pointer;
        color: white;
        font-family: MuliSemiBold;
        font-size: 18px;
  
        &:hover {
          svg {
            fill: #fff;
          }
        }
      }
    }

    .cmtbx-nav {
      .nav.nav-tabs.panel-nav, .nav.dropdown {
        margin-bottom: 0;
      }

      .nav-tabs {
        border-bottom-color: #303030;

        .nav-link {
          font-size: 13px;
          color: #fff;
        }
        
        .nav-link.active {
          background-color: transparent;
          color: $radivision-blue;
        }
      }
    }

    &::before {
      width: 100%;
      background-color: #212121;
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      content: "";
      z-index: -1;
      top: 0;
      left: 0;
    }

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      height: 40px;
      bottom: 0px;
      box-shadow: 0 0 35px #000;
      opacity: 0.3;
      border-radius: 100%;
      z-index: -2;
    }
  }

  .yt-livechat {
    width: 100%;
    flex: 1;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .comments-wrap {
    direction: rtl;
    overflow-y: auto;
    flex: 1;
    width: 100%;
    padding-right: 6px;

    &::-webkit-scrollbar {
      // width: 6px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #0f0f0f;
    }
    &::-webkit-scrollbar-track {
      background-color: #181818;
    }
  }

  .comments {
    direction: ltr;

    &.flex-box {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .photo {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .displayname {
      font-weight: bold;
      color: #ababad;
      flex: 1;
    }
  }

  .photo .avatar {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
    margin-right: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .comment-block {
    padding: 1rem;
    background-color: #0f0f0f;
    display: table-cell;
    vertical-align: top;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    position: relative;
  }
  .comment-block textarea {
    width: 100%;
    resize: none;
  }

  .comment-text {
    color: #fff;
    margin-bottom: 0;
  }

  .comment-date {
    font-size: 12px;
    color: #666;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .report {
    cursor: pointer;
    margin-left: 8px;

    svg {
      fill: #000;
      transition: all 0.3s;
      width: 18px;
      height: 18px;
    }

    &:hover {
      svg {
        fill: #dc3545;
      }
    }
  }

  .comment-wrap {
    padding: 8px 16px;
    padding-top: 2px;
    display: table;
    width: 100%;
    min-height: 5.3125rem;

    &.info {
      padding: 18px 16px;

      .comment-block {
        background-color: #1c313a;
        text-align: center;
        padding: 24px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
      }
    }
  }

  .empty-inbox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      fill: #0f0f0f;
      width: 50%;
      height: auto;
      margin-bottom: 24px;
    }

    p {
      color: $gray;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .new-comment {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;

    .photo {
      padding-top: 0;
      width: auto;
      margin-bottom: 0;

      .avatar {
        margin-right: 0;
      }
    }

    form {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      .comment-block {
        flex: 1;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
        padding: 0 12px;

        textarea {
          background-color: #0f0f0f;
          border-radius: 6px;
          color: #fff;
          padding: 12px;
          height: 56px;
        }
      }

      button {
        background-color: $radivision-blue;
        border-radius: 6px;
        align-self: stretch;

        svg {
          fill: #fff;
        }
      }
    }
  }
}
@media (max-width: 560px) {
  .live-video {
    height: calc(((100vw / (16 / 9)) * 2) + 50px)!important;
    .live-now {
      justify-content: center;
      align-items: center;
    }
    .video-panel {
      height: calc(((100vw / (16 / 9)) * 2) + 50px)!important;
      .video-container {
        display: flex;
        flex-grow: 1;
        height: calc((100vw / (16 / 9)) + 50px)!important;
        max-height: calc((100vw / (16 / 9)))!important;
      }
      .comments-container {
        width: 100%;
        max-width: none;
        flex-shrink:1;
        height: calc((80vw / (16 / 9)) + 50px)!important;
      }
    }
    .open-chatbox {
      top: 50px;
    }
    .text-block {
      position: relative!important;
      height: 60px!important;
      width: 100%!important;
      margin: 0!important;
      .info {
        height: 60px!important;
        display: flex!important;
        justify-content: center!important;
        align-items: center!important;
        padding:0px!important;
        left: auto!important;
        width: 100%!important;
        margin-bottom: 0!important;
        padding-top: 0!important;
        left:0px!important;
        right:0px!important;
        bottom: 10px!important;
        button {
          padding:5px 30px!important;
          font-size: 15px!important;
        }
      }
    }
  }
  body.force-untransparent {
    .open-chatbox {
      top: 60px;
    }
  }
}
@media (max-width: 1000px) and (orientation: landscape) {
  .open-chatbox {
    width: 50px!important;
    span {
      display: none;
    }
  }
}
