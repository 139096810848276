#main-navbar {
  &.transparent .nav-placeholder {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}
.main-navbar {
  &.navbar {
    background-color: $dark-mode-bg;
    padding: 0;
    display: flex;
    align-items: center;
    height: auto;
    max-height: 60px;
    z-index: 99;

    @media (min-width: 1200px) {
      max-height: unset;
      .nav-item {
        padding: 27px 0px;
      }
    }
    @media (min-width: 2500px) {
      height: 115px;
    }

    @media (max-width: 2499px) {
      height: 97px;
    }

    // @media (width: 1500px) {
    //   height: 85px;
    // }

    // @media (max-width: 1499px) {
    //   height: 92px;
    // }

    // @include media-breakpoint-down(md) {
    //   height: 82px;
    // }

    @include media-breakpoint-down(sm) {
      z-index: 101;
      min-height: 50px;
    }

    &.transparent {
      @include media-breakpoint-up(md) {
        background-color: rgba(22, 22, 22, 0);
        background-image: -webkit-linear-gradient(0, rgba(22, 22, 22, 0), #161616);
        background-image: -moz-linear-gradient(0, rgba(22, 22, 22, 0), #161616);
        background-image: -o-linear-gradient(0, rgba(22, 22, 22, 0), #161616);
        background-image: linear-gradient(0, rgba(22, 22, 22, 0), #161616);
      }

      &.highIndex {
        z-index: 0;
      }
    }

    &.darkBlue {
      background: $radivision-dk-gray !important;
    }

    &.nigntyOpacity {
      @include media-breakpoint-up(md) {
        background-color: rgba(50, 50, 50, 0.9);
        background-image: none;
      }

      &.highIndex {
        z-index: 0;
      }
    }

    &.thertyOpacity {
      @include media-breakpoint-up(md) {
        background-color: rgba(50, 50, 50, 0.3);
      }

      &.highIndex {
        z-index: 0;
      }
    }

    transition: background linear 0.3s;

    &.dark-background {
      background-color: $radivision-dk-gray;
    }

    .search-form {
      width: 100%;
      right: unset;
      left: 0;
      overflow: hidden;

      &.search-form-shown {
        top: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      height: auto;
    }

    &.search-toggled {
      .nav-item:not(.hamburger-dropdown),
      .profile-img {
        display: none;
      }
    }

    .navbar-brand {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      min-width: 220px;
      max-width: 220px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-left: 70px;
      margin-right: 34px;
      padding-top: 6px;
      padding-bottom: 14px;
      z-index: 10;

      h5 {
        font-size: 17px;
        font-family: "MuliBold";
        color: #4cb4e7;
        margin: 0;
        margin-left: 12px;
        margin-top: 5px;
        padding-bottom: 0.3125rem;
        text-align: right;
        text-transform: uppercase;

        @media (min-width: 2000px) {
          font-size: 24px;
        }

        @media (max-width: 1501px) {
          display: none;
        }

        // @media (min-width: 997px) and (max-width: 1300px) {
        //   width: 235px;
        //   margin-top: 0;
        // }

        // @media (min-width: 768px) and (max-width: 880px) {
        //   width: 155px;
        //   max-width: 155px;
        //   font-size: 12px !important;
        // }

        // @include media-breakpoint-down(md) {
        //   font-size: 14px;
        //   margin-top: 0;
        // }

        // @include media-breakpoint-down(sm) {
        //   margin-left: 0;
        //   font-size: 0.85rem;
        // }

        // @media (max-width: 1500px) {
        //   width: 286px;
        //   margin-top: 0;
        // }
      }

      @include media-breakpoint-only(lg) {
        margin-left: 35px;
      }

      @media (max-width: 560px) {
        margin-left: 24px;
        margin-right: 10px;
        padding-bottom: 4px;
        padding-top: 2px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include media-breakpoint-down(md) {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .nav-item {
      &:focus,
      &:hover,
      &:active {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          top: 90px;

          @include media-breakpoint-down(sm) {
            display: block;
          }
        }
      }

      .btn-radivision {
        font-size: 14px;
        font-family: Muli;
        padding: 7px 0px;
        margin: 10px 20px;
        background-image: none;
        text-transform: none;
        background-color: transparent;
        border: 1px solid $radivision-blue;
        color: $radivision-blue;
        width: calc(100% - 40px);
        box-sizing: border-box;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          font-size: 18px;
          margin: 10px 35px;
          width: calc(100% - 50px);
          font-family: MuliSemiBold;
        }
        &:hover {
          color: white;
          background-image: $radivision-blue;
          background-color: $radivision-blue;
          i svg {
            fill: white;
          }
        }
        .hoverOverlay {
          background: none;
          opacity: 0;
        }
        & > span {
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            width: 12px;
            flex-shrink: 1;
            flex-grow: 0;
            margin-right: 6px;
            svg {
              height: 12px;
              width: 12px;
              fill: $radivision-blue;
            }
          }
        }
      }

      .nav-link {
        color: white;
        margin: auto 0;
        margin-right: 32px;
        padding: 0;
        text-align: center;
        height: 100%;
        cursor: pointer;
        font-size: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        align-content: center;
        font-family: "Muli";
        border-top: 3px solid transparent;
        border-top-color: transparent !important;
        transition: all 0.3s;
        border-bottom: 3px solid transparent;

        &:hover,
        &.active {
          border-bottom-color: $radivision-blue;
        }

        svg {
          fill: #fff;
          width: auto;
          height: 19px;
          margin-right: 10px;
          margin-top: 3px;
        }

        &.editorials {
          svg {
            @include media-breakpoint-up(xl) {
              width: 14px;
              margin-top: 0px;
            }
          }
        }

        &.more-dropdown {
          border-bottom-color: transparent !important;

          svg {
            height: 25px;
            width: auto;
            margin-top: 10px;
          }
        }

        @include media-breakpoint-down(lg) {
          margin-right: 22px;
        }

        @include media-breakpoint-down(md) {
          font-size: 12px;
          margin: auto 0px;
          padding: 1.9rem 0.4rem;
        }

        @media (width: 880px) {
          font-size: 13px;
          margin: 0 5px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 13px;
          text-align: left;
          padding: 0.5rem 35px;
          padding-right: 35px;
          padding-left: 35px;
        }

        &::after {
          content: none;
          border: 0;
        }

        &.semibold {
          @include media-breakpoint-up(md) {
            font-weight: 300;
          }
        }

        &:focus,
        &:hover {
          color: white;

          &.profile-picture {
            background-color: transparent;
          }

          .arrow-down {
            fill: white;
          }
        }

        &.active {
          @include media-breakpoint-up(md) {
            border-bottom: 3px solid $radivision-blue;

            &.profile-picture {
              border-bottom: 0;
            }
          }

          @include media-breakpoint-down(sm) {
            color: $radivision-blue;

            svg {
              fill: $radivision-blue;
            }
          }
        }

        &.arrow-menu {
          border: none;
        }

        .arrow-down {
          fill: $radivision-blue;
          width: 10px;
          min-width: 10px;
          height: 16px;
          margin-top: 6px;
          vertical-align: middle;
          margin-left: 6px;

          @media (min-width: 2000px) {
            width: 13px;
            min-width: 13px;
            margin-left: 12px;
          }
        }
      }
    }

    .nav-right-menu {
      margin-right: 60px;

      .search-btn {
        padding: 0;
        border: none;
        margin-right: 38px;
        width: auto;
        @include media-breakpoint-down(sm) {
          margin: 10px 0px 0px 0px;
        }
        svg {
          width: auto;
          height: 20px;

          path {
            stroke-width: 1px;
            stroke: #fff;
          }
        }
      }

      .navbar-browse {
        display: flex;
        flex-direction: column;

        h5.browse {
          color: $radivision-blue;
          margin-left: 8px;
          cursor: pointer;
          font-size: 16px;
          font-family: "MuliSemiBold";

          @media (max-width: 684px) {
            display: none;
          }
        }
      }

      .nav-link {
        border: none;
        font-size: 15px;
        font-family: "MuliExtraBold";
        font-weight: 800;
        height: auto;
        line-height: 28px;
        padding: 0;

        &.join {
          background-color: $radivision-blue;
          border-radius: 32px;
          padding: 0 26.5px;
          margin-right: 0;
        }
      }

      @include media-breakpoint-down(lg) {
        margin-right: 35px;
      }

      @include media-breakpoint-down(md) {
        margin-right: 60px;
      }

      @include media-breakpoint-down(sm) {
        ul.navbar-nav {
          max-height: 60px;
          flex-direction: row;
        }

        .search-btn {
          padding-top: 0px;
          margin-right: 24px;
        }
      }

      @media (max-width: 684px) {
        margin-right: 60px;

        .search-btn {
          margin-right: 0;
          margin-top: 4px;

          svg {
            width: 32px;
            height: 34px;
          }
        }

        ul.navbar-nav {
          display: none;
        }
      }
    }

    .nav-right-menu,
    .xs-user-login {
      .nav-link {
        border: none;
        font-size: 15px !important;
        font-family: "MuliExtraBold";
        font-weight: 800;
        height: auto;
        line-height: 28px;
        padding: 0 !important;

        &.join {
          background-color: $radivision-blue;
          border-radius: 32px;
          padding: 0 26.5px !important;
          margin-right: 20px;

          @include media-breakpoint-down(lg) {
            margin-left: 12px !important;
          }
        }
      }
    }

    // .dropdown.show {
    //   color: white;
    //   background-color: $radivision-blue;
    // }

    .dropdown-menu {
      z-index: 99;
      border-radius: 0;
      left: -37px;
      top: 150%;
      padding: 15px 0;
      // width: 100%;
      border: 0;
      margin: 0;
      opacity: 0;
      transition: all 0.4s;
      display: block;
      visibility: hidden;
      min-width: 12rem;
      color: #fff !important;
      background-color: $dark-mode-black;
      border-radius: 16px;
      box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.34);

      &::before {
        display: block;
        content: "";
        position: absolute;
        top: -28px;
        left: 22%;
        z-index: 1;
        width: 0;
        height: 0;
        border-color: transparent;
        border-width: 8px;
        border-top-width: 14px;
        border-style: solid;
        border-bottom: 14px solid $dark-mode-black;
      }

      @media (min-width: 2000px) {
        min-width: 18rem;
      }

      @media (max-width: 770) {
        display: none;
        visibility: hidden;
        opacity: 0;
      }

      .insideList {
        list-style-type: none;
        padding: 0.5rem 1.5rem;
        color: #4cb3e7;
        font-size: 14px;
        margin: 7px 0;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 0.075rem;

        @media (min-width: 2000px) {
          font-size: 22.5px;
        }

        @media (max-width: 1024px) {
          font-size: 12px;
        }

        @include media-breakpoint-down(sm) {
          padding-left: 3rem;
          font-size: 12px;
          font-weight: 600;
        }

        & ~ a {
          background-color: white;
        }
      }

      .exclusive-event-form {
        padding: 0px 7px;

        @media (max-width: 1200px) {
          padding: 0px 24px;
        }

        input {
          border: 1px solid #737373;
          background-color: #2e2e2e;
          padding: 0px 7px 0px 22px;
          margin-bottom: 5px;
          width: 100%;
          min-width: 150px;
          border-radius: 15px;
          font-family: Muli;
          font-size: 14px;
          color: #fff;
          height: 40px;
          position: relative;
        }
      }

      .dropdown-item {
        padding: 14px 1.5rem;
        cursor: pointer;
        font-size: 18px;
        font-family: "Muli";
        background-color: transparent !important;
        color: #fff;
        line-height: 17px;

        &:focus,
        &:hover {
          color: $radivision-blue;

          svg {
            color: $radivision-blue !important;
          }
        }

        &.soon {
          color: #898991;
          cursor: default;
          padding: 0px 1.5rem;
        }

        @media (min-width: 2000px) {
          font-size: 22.5px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 12px;
          font-weight: 600;
        }

        &.disable {
          pointer-events: none;
          cursor: default;
          color: $dark-empty-placeholder;
        }

        &.active {
          color: $radivision-blue;
        }

        &.entity-item {
          font-size: 14px;
          hyphens: auto;
          padding: 6px 1rem 6px 2rem;
          color: #bbbbbb;
          white-space: break-spaces;
          max-width: 100%;
          &:hover {
            color: $radivision-blue;
          }
          &:last-child() {
            margin-bottom: 20px;
          }
        }
      }

      &.hamburger {
        left: auto;
        right: 0;
        min-width: 10rem;

        @include media-breakpoint-down(sm) {
          display: block;
          visibility: visible;
          opacity: 1;
        }

        .dropdown-item {
          padding-left: 0.5rem;

          @include media-breakpoint-down(sm) {
            font-size: 13px;

            &:first-child {
              font-weight: 800;
            }
          }
        }

        @media (min-width: 2500px) {
          width: auto;
        }
      }
      &.entity-item {
        font-size: 13px;
      }
    }

    .nav-item.picture-dropdown .dropdown-menu {
      top: 85px;
      right: -37px;
      left: auto;

      &::before {
        right: 42%;
        left: auto;
      }

      @include media-breakpoint-down(md) {
        top: 66px;
        right: -55px;

        &::before {
          right: 31%;
          left: auto;
        }
      }
    }

    .showdropdown-menu {
      min-width: 18rem;

      @media (min-width: 2000px) {
        min-width: 24rem;
      }
    }

    .search-btn {
      background-color: transparent;
      transition: background-color linear 0.3s;
      width: 70px;
      align-self: stretch;

      @media (min-width: 2000px) {
        width: 80px;
      }

      svg {
        fill: white;
        width: 24px;

        @include media-breakpoint-down(md) {
          width: 21px;
        }

        &:hover {
          fill: $radivision-blue;
        }
      }

      &.btn-close {
        background-color: $radivision-blue;
        border-radius: 0;
        position: relative;

        svg {
          width: 16px;

          &.close {
            margin: auto;
            display: inline-block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0.75;
          }

          &:hover {
            fill: white;
          }
        }
      }

      @media (min-width: 2500px) {
        width: 100px;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .hamburger-dropdown {
      @include media-breakpoint-up(md) {
        background-color: $radivision-blue;
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 30px;
        margin-left: 30px;

        .dropdown-toggle {
          display: none;
        }

        .hamburger {
          border-top: 1px solid lightgray;
          border-bottom: 1px solid lightgray;
        }
      }

      .nav-link {
        padding: 0;
        margin: 0;
        min-width: 0;
      }

      svg {
        width: 60px;
        fill: white;
        height: 100%;

        @media (min-width: 2000px) {
          width: 70px;
        }

        @media (min-width: 999px) and (max-width: 1024px) {
          width: 65px;
        }
      }
    }

    //styling for mobile screens
    .navbar-toggler {
      border: none;
      border-radius: 0;
      width: 60px;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 15px;
      cursor: pointer;
      position: absolute;
      right: 0;
      z-index: 100;
      transition: all 0.3s;

      // Normalize (<button>)
      font: inherit;
      color: #fff;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible;

      @include media-breakpoint-down(sm) {
        padding-top: 8px;
      }

      &:hover {
        opacity: 0.7;
      }

      .hamburger-box {
        width: 30px;
        height: (3px * 3) + (6px * 2);
        display: inline-block;
        position: relative;
        margin-top: 5px;
      }

      .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: calc(3px / -2);
        top: calc(3px / 2);
        transition-duration: 0.275s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &,
        &::before,
        &::after {
          width: 30px;
          height: 3px;
          background-color: #fff;
          border-radius: 3px;
          position: absolute;
          transition-property: transform;
          transition-duration: 0.15s;
          transition-timing-function: ease;
        }

        &::before,
        &::after {
          content: "";
          display: block;
        }

        &::before {
          top: (3px + 6px);
          transition: opacity 0.125s 0.275s ease;
        }

        &::after {
          top: (3px * 2) + (6px * 2);
          transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
      }

      &.is-active {
        .hamburger-inner {
          $y-offset: 6px + 3px;

          transform: translate3d(0, $y-offset, 0) rotate(135deg);
          transition-delay: 0.075s;

          &::before {
            transition-delay: 0s;
            opacity: 0;
          }

          &::after {
            transform: translate3d(0, $y-offset * -2, 0) rotate(-270deg);
            transition-delay: 0.075s;
          }
        }
      }

      @media (min-width: 1200px) {
        display: none;
      }

      @include media-breakpoint-down(lg) {
        display: inline-block;
      }

      &.hide {
        @media (min-width: 684px) {
          display: none;
        }
      }
    }

    .navbar-collapse {
      flex: none;
      height: 100%;

      .navbar-nav {
        height: 100%;
      }

      @include media-breakpoint-down(lg) {
        display: none !important;
        position: fixed;
        top: 50px;
        right: 0;
        width: 100vw;
        height: calc(100vh - 50px);
        background: rgba(0, 0, 0, 0.75);
        z-index: 1000;

        .navbar-collapse-backdrop {
          display: block;
          position: fixed;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
        }

        ul.navbar-nav {
          width: 100vw;
          display: flex;
          flex-direction: column;
          max-height: 100vh;
          overflow-y: auto;
          background: $dark-mode-bg;
          transform: translateX(100%);
          transition: all 0.3s ease-in;
          animation-name: menuSlideIn;
          animation-duration: 0.1s;
          animation-timing-function: ease-out;
          animation-direction: reverse;
          animation-fill-mode: forwards;

          @include media-breakpoint-down(sm) {
            padding-bottom: 6rem;
          }

          &::-webkit-scrollbar-track {
            width: 0px;
            background-color: transparent;
          }

          &::-webkit-scrollbar {
            width: 0px;
            border-radius: 6px;
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.5);
          }

          li.nav-item {
            .nav-link {
              font-size: 24px;
              font-family: MuliBold;
              margin: auto 0px;
              padding: 5px 24px;
              border-bottom: 2px solid $dark-mode-bg;
              height: 50px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              outline: none;
              .icon {
                display: none;
                width: 45px;
                justify-content: center;
                svg {
                  width: 24px;
                  height: auto;
                }
              }
            }

            &.dropdown {
              outline: none;
              i {
                svg {
                  width: 16px;
                  height: 16px;
                  margin: 0 !important;
                  margin-left: 12px !important;
                  fill: $dark-input-placeholder;
                  transform: rotate(90deg);
                }
              }
            }

            div.dropdown-menu {
              height: 0px;
              overflow: hidden;
              transition: all 0.3s;
              padding: 0;
              opacity: 0;
              width: 100%;
              position: relative;
              left: unset;
              top: unset;
              border-radius: unset;
              box-shadow: unset;

              &::before {
                content: unset;
                display: none;
              }
            }

            &.show {
              div.dropdown-menu {
                padding: 0px 0;
                height: auto;
                display: block;
                visibility: visible;
                opacity: 1;
                margin-bottom: 16px;
                background-color: $dark-mode-bg;

                a.dropdown-item {
                  font-size: 20px;
                  font-family: MuliSemiBold;
                }
              }
            }
          }
        }

        &.expanded {
          display: flex !important;

          ul.navbar-nav {
            animation-direction: normal;
            padding-top: 24px;
            .nav-link {
              &.active {
                color: $radivision-blue;
              }
            }
          }
        }
        @keyframes menuSlideIn {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
      }

      @media (max-width: 684px) {
        .xs-user-login {
          display: flex !important;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          a {
            text-decoration: none;
            cursor: pointer;
          }
          div.login-btn {
            a {
              color: $radivision-blue;
            }
          }
          div.join-btn {
            min-width: 50%;
            width: 50%;
            display: flex;
            margin: 30px 0px 10px 0px;
            a {
              flex: 1;
              padding: 10px 30px;
              text-align: center;
              border-radius: 20px;
              background-color: white;
              color: black;
            }
          }
        }
      }
    }

    .picture-dropdown {
      width: 80px;

      @include media-breakpoint-down(md) {
        width: auto;
      }

      .profile-picture {
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        align-self: center;
        height: 100%;

        .lazyload-placeholder {
          height: auto !important;
        }

        .arrow-down {
          left: 40px;
          right: auto;

          @include media-breakpoint-down(sm) {
            margin-top: 0;
          }
        }

        img,
        .lazyload-placeholder {
          width: 36px;
          height: 36px;
          min-width: 36px;
          border-radius: 50%;
          border: 2px solid white;
          background-color: white;

          @media (min-width: 2000px) {
            width: 46px;
            height: 46px;
            min-width: 46px;
          }
        }
      }

      .dropdown-menu {
        left: 0;
        min-width: 14rem;

        @include media-breakpoint-down(sm) {
          left: 40px;
          min-width: 10rem;
          display: none;
          top: -30px;
          background-color: transparent;
          opacity: 1;
          visibility: visible;
        }

        .dropdown-item {
          @include media-breakpoint-down(sm) {
            display: block;
            width: auto;
            padding-top: 5px;
            padding-bottom: 5px;

            &:first-of-type {
              border-right: 0px solid #7c7c7c;
            }

            &:last-of-type {
              padding-left: 3rem;
            }
          }
        }
      }

      @media (min-width: 2500px) {
        width: 160px;
        position: relative;

        .dropdown-menu {
          width: 15rem;
        }
      }

      .companyProfiles {
        svg {
          fill: #7c7c7c !important;
          width: 10px;
          margin-top: 5px;
          vertical-align: middle;
          margin-left: 6px;
        }
      }

      #companyProfiles {
        .card {
          background-color: transparent;
          border-left: 0;
          border-right: 0;
          padding: 0;
          min-height: 50px;

          a {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .profile-img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid white;
      position: absolute;
      left: 34%;
      top: 50%;
      margin-top: -10px;

      @include media-breakpoint-down(sm) {
        width: 35px;
        height: 35px;
      }
    }
    li.moreDropdownMenu {
      z-index: 101;
      @include media-breakpoint-down(sm) {
        // display: none;
      }
    }
    li.more-sm-item {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }
}

svg.newIcone,
i.newIcone svg {
  width: 16px;
  height: auto;
  fill: white;
  margin-right: 10px;

  @media (min-width: 2000px) {
    width: 22.5px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

svg.featured,
i.featured svg {
  width: 12px;
  vertical-align: baseline;

  @media (min-width: 2000px) {
    width: 19.5px;
  }
}

.addTopMargin {
  margin-top: 45px;

  @media (min-width: 2000px) {
    margin-top: 64px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 40px;
  }
}

.fixed-top {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.navbar-nav .ml-auto {
  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }
}
