/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-12
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.profile-header {
  margin-bottom: 7.5px; /// 99px minues the profile data bottom 91.5 px elevation

  .form-group {
    .input-with-icon {
      position: relative;
      .input-icon {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        width: 32px;
        display: flex;
        align-items: center;
        svg {
          width: 10px;
          fill: white;
        }
      }
    }
  }

  .profile-header-container {
    position: relative;
    color: #fff;
    @media (min-width: 1200px) {
      margin-bottom: 70px;
    }

    .profile-header-background {
      height: 220px;
      overflow: hidden;
      position: absolute;
      right: 0px;
      left: 0px;
      background-color: #333;
      @media (min-width: 1600px) {
        height: 160px;
      }
      @media (min-width: 1200px) {
        height: 160px;
      }

      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40%;
        opacity: 0.3;
        background-image: linear-gradient(rgba(22, 22, 22, 0) 0%, #161616 100%);

        @include media-breakpoint-down(lg) {
          height: 100%;
          opacity: 0.2;
        }
      }
    }

    .pageData {
      display: flex;
      position: relative;
      z-index: 10;
      align-items: flex-start;
      @media (min-width: 1200px) {
        position: relative;
        width: 1100px;
        margin: 0px auto 0px auto;
        transform: translateY(80px);
        .justify-content-start {
          justify-content: flex-end !important;
        }
      }
      @media (min-width: 1600px) {
        transform: translateY(80px);
        margin: 0px auto 0px auto;
        width: 1500px;
      }

      .personImage {
        width: 140px;
        height: 140px;
        flex: 0 0 140px;
        border: 2px solid #fff;
        @media (min-width: 1200px) {
          width: 160px;
          height: 160px;
          flex: 0 0 160px;
          border: 4px solid #171717;
        }
        @media (min-width: 1600px) {
          width: 160px;
          height: 160px;
          flex: 0 0 160px;
          border: 4px solid #171717;
        }
        margin-right: 30px;
        border-radius: 50%;
        position: relative;
        background-color: $dark-empty-placeholder;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        .empty-image {
          width: 100%;
          height: 100%;
          display: block;
        }

        .change-avatar {
          background-color: rgba(0, 0, 0, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: all 0.3s;
          cursor: pointer;
          position: absolute;
          top: 0;
          border-radius: 50%;

          svg {
            width: 40%;
            height: auto;
            fill: #fff;
          }
        }

        &:hover {
          .change-avatar {
            opacity: 1;
          }
        }
      }

      .social-tab {
        @include media-breakpoint-down(lg) {
          display: flex;
          align-items: center;
          align-content: center;
          align-self: center;
          flex-direction: column;
        }
      }

      .personData {
        display: flex;
        flex: 1;
        position: relative;
        flex-direction: column;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        .profile-info-top {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          padding-bottom: 12px;
          justify-content: space-between;
          height: 91.5px;
          @media (min-width: 1200px) {
            height: 160px;
            align-items: center;
            padding-bottom: 0px;
            justify-content: flex-end;
          }
          @media (min-width: 1600px) {
            height: 160px;
          }

          .custom-file {
            display: none;
          }

          // for accoutnt settings page
          .acc-setting-btn {
            border-radius: 22px;
            font-family: "MuliBold";
            color: rgba(255, 255, 255, 0.9);
            font-size: 14px;
            padding: 9px 35px;
            margin-right: 15px;
            margin-bottom: 0;
            margin-top: 0;
            background-color: #2e2e2e;
            border: none;
            cursor: pointer;

            &.btn-accent {
              background-color: $radivision-blue;
              margin-left: 7px;
              color: #fff;
              font-family: "MuliExtraBold";
              min-width: 103px;
            }
          }

          .profile-info,
          .profile-name {
            display: flex;
            flex-direction: column;
            padding: 6px 0;
            flex: 1;
            @media (min-width: 1200px) {
              display: none;
            }

            .profile-type {
              font-size: 16px;
              font-family: "MuliBold";
              color: rgba(255, 255, 255, 0.5);
              margin-bottom: 8px;
            }

            .name {
              font-size: 26px;
              line-height: 37px;
              font-family: "MuliBold";

              &.entity {
                margin-bottom: 0;
                hyphens: auto;
              }

              &.org-profile {
                text-transform: uppercase;
              }

              .name-placeholder {
                display: block;
                width: 60%;
                height: 26px;
                margin: 5px 0;
                background-color: $dark-empty-placeholder;

                @include media-breakpoint-down(lg) {
                  margin: auto;
                }
              }

              @media (min-width: 2500px) {
                font-size: 40px;
              }
            }

            .title {
              font-family: "MuliBold";
              font-size: 16px;
              overflow: hidden;
              margin-bottom: 0;

              .title-placeholder {
                display: block;
                width: 40%;
                height: 18px;
                background-color: $dark-empty-placeholder;
              }

              @media (min-width: 2500px) {
                font-size: 24px;
              }
            }
          }

          .xl-profile-actions {
            display: none;
            height: 36px;
            justify-content: flex-end;
            & > .action-button {
              margin-left: 10px;
              cursor: pointer;
            }
            .dropdown-menu.more-menu {
              transform: translate3d(20px, -30px, 0px) !important;
            }
            span.more-menu {
              position: relative;
              border-radius: 18px;
              margin-left: 10px;
              width: 36px;
              height: 36px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              &:focus,
              &:hover,
              &:active {
                .dropdown-menu {
                  visibility: visible;
                  opacity: 1;
                  top: 50px;

                  @include media-breakpoint-down(lg) {
                    display: block;
                  }
                }
              }
              a {
                color: #272727;
              }
              svg {
                fill: #272727;
                width: 18px;
                height: 18px;
              }
            }
            .action-button {
              display: flex;
              align-items: center;
              background-color: #fff;
              border-radius: 18px;
              color: #272727;
              padding: 0px 20px;
              max-width: unset;
              font-family: MuliBold;
              font-size: 15px;
              transition: all 0.3s ease-in-out;
              background-image: none;
              text-decoration: none;
              margin: 0px;
              svg {
                margin-right: 10px;
              }
            }
            @media (min-width: 1200px) {
              display: flex;
            }
            .dropdown-menu {
              border-radius: 0;
              top: 60px;
              right: -90%;
              left: unset;
              padding: 15px 0;
              width: 100%;
              border: 0;
              margin: 0;
              opacity: 0;
              transition: all 0.2s;
              display: block;
              visibility: hidden;
              min-width: 12rem;
              max-width: 200px;
              color: #fff !important;
              background-color: $dark-mode-black;
              border-radius: 16px;
              box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.34);

              &::before {
                display: block;
                content: "";
                position: absolute;
                top: -28px;
                right: 22%;
                z-index: 1;
                width: 0;
                height: 0;
                border-color: transparent;
                border-width: 8px;
                border-top-width: 14px;
                border-style: solid;
                border-bottom: 14px solid $dark-mode-black;
              }

              @media (min-width: 2000px) {
                min-width: 18rem;
              }

              @media (max-width: 575.98px) {
                display: none;
                visibility: hidden;
                opacity: 0;
              }

              .insideList {
                list-style-type: none;
                padding: 0.5rem 1.5rem;
                color: #4cb3e7;
                font-size: 14px;
                margin: 7px 0;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 0.075rem;

                @media (min-width: 2000px) {
                  font-size: 22.5px;
                }

                @media (max-width: 1024px) {
                  font-size: 12px;
                }

                @include media-breakpoint-down(lg) {
                  padding-left: 3rem;
                  font-size: 12px;
                  font-weight: 600;
                }

                & ~ a {
                  background-color: white;
                }
              }

              .dropdown-item {
                padding: 14px 1.5rem;
                cursor: pointer;
                font-size: 18px;
                font-family: "Muli";
                background-color: transparent !important;
                color: #fff;
                line-height: 17px;

                &:focus,
                &:hover {
                  color: $radivision-blue;

                  svg {
                    color: $radivision-blue !important;
                  }
                }

                @media (min-width: 2000px) {
                  font-size: 22.5px;
                }

                @include media-breakpoint-down(lg) {
                  font-size: 12px;
                  font-weight: 600;
                }

                &.disable {
                  pointer-events: none;
                  cursor: default;
                  color: $dark-empty-placeholder;
                }

                &.active {
                  color: $radivision-blue;
                }
              }
            }
          }
          .profile-actions {
            @media (min-width: 1200px) {
              display: none;
            }
            .btn-radivision {
              padding: 5px 25px;
              font-family: "MuliExtraBold";
              font-size: 15px !important;
              margin: 0;
              text-transform: capitalize;
              white-space: nowrap;
              background-image: none !important;
              background-color: white;
              color: #000 !important;

              @include media-breakpoint-down(md) {
                margin-right: 0;
              }
            }

            ul.social-links {
              display: flex;
              padding: 0;
              margin: 0;
              align-items: center;

              li {
                list-style-type: none;
                padding-right: 1rem;
                display: flex;
                align-items: center;

                &:last-child {
                  padding-right: 0;
                }

                a {
                  height: 17px;
                  display: flex;
                  align-items: center;
                }

                svg {
                  fill: white;
                  width: 17px;
                  height: 17px;

                  @media (min-width: 2500px) {
                    width: 23px;
                    height: 23px;
                    margin-top: 5px;
                  }
                }

                &.twitter-icon {
                  svg {
                    padding-top: 2px;
                  }
                }

                .active {
                  svg {
                    fill: white;
                    cursor: pointer;
                  }
                }

                & > svg {
                  opacity: 0.4;
                }

                &:empty {
                  padding-right: 0;
                }
              }

              @include media-breakpoint-down(lg) {
                display: none;
              }
            }

            .claim-profile {
              .btn-radivision {
                margin-right: 0;
                min-width: 138.83px;
              }
            }
          }
          .profile-actions > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include media-breakpoint-down(md) {
              flex-direction: column;
            }

            @include media-breakpoint-down(lg) {
              align-items: center;
              margin-top: 12px;
            }
          }
        }

        .profile-info-bottom {
          padding-top: 15px;

          .websiteURL,
          .website,
          .location {
            color: $radivision-blue;
            font-size: 16px;
            font-family: "Muli";
            display: block;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.5rem;
            svg {
              width: 14px;
              height: auto;
              margin-right: 11px;
              fill: $radivision-blue;
            }

            @media (min-width: 2500px) {
              font-size: 18px;
            }
          }

          .description {
            color: white;
            font-size: 16px;
            margin-bottom: 16px;

            .description-placeholder {
              display: block;
              width: 80%;
              height: 22px;
              background-color: $dark-empty-placeholder;

              @include media-breakpoint-down(lg) {
                margin: auto;
              }
            }

            @media (min-width: 2000px) {
              font-size: 18px;
            }
          }

          .social-links {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
        }
      }
      @media (min-width: 1200px) {
        .profile-info-bottom {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      height: auto;

      .profile-header-background {
        position: absolute;
        width: 100%;
        height: 20vh;
        top: 0px;
        z-index: -1;
      }

      .pageData {
        width: 98%;
        bottom: unset;
        flex-direction: column;
        margin-top: 12.5vh;
        align-items: center;

        .personImage {
          margin-right: 0;
          margin-bottom: 21px;
          width: 120px;
          height: 120px;
          max-width: 120px;
          max-height: 120px;
          border-radius: 60px;
          flex: 0 0 179px;
        }

        .personData {
          .profile-info-top {
            flex-wrap: wrap;
            height: auto;
            align-items: center;
            flex-direction: column;

            .profile-name {
              flex: 0 0 100%;
              text-align: center;
              width: 100%;
            }

            .profile-info {
              flex: 1;
              padding-right: 8px;
              flex: 0 0 100%;
              align-items: center;
            }

            &.company-profile {
              .profile-actions {
                flex: 0 0 100%;
              }
            }
          }

          .profile-info-bottom {
            text-align: center;

            .websiteURL,
            .website,
            .location {
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .header-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-down(lg) {
      object-fit: unset;
    }
  }

  .person-background {
    background: rgb(74, 26, 162);
    background: -moz-linear-gradient(left, rgba(74, 26, 162, 1) 0%, rgba(172, 33, 52, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(74, 26, 162, 1) 0%, rgba(172, 33, 52, 1) 100%);
    background: linear-gradient(to right, rgba(74, 26, 162, 1) 0%, rgba(172, 33, 52, 1) 100%);
  }

  .entity-background {
    background: rgb(35, 57, 116);
    background: -moz-linear-gradient(
      left,
      rgba(35, 57, 116, 1) 0%,
      rgba(35, 57, 116, 1) 21%,
      rgba(35, 57, 116, 1) 21%,
      rgba(15, 105, 123, 1) 50%,
      rgba(11, 31, 49, 1) 79%,
      rgba(11, 31, 49, 1) 99%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(35, 57, 116, 1) 0%,
      rgba(35, 57, 116, 1) 21%,
      rgba(35, 57, 116, 1) 21%,
      rgba(15, 105, 123, 1) 50%,
      rgba(11, 31, 49, 1) 79%,
      rgba(11, 31, 49, 1) 99%
    );
    background: linear-gradient(
      to right,
      rgba(35, 57, 116, 1) 0%,
      rgba(35, 57, 116, 1) 21%,
      rgba(35, 57, 116, 1) 21%,
      rgba(15, 105, 123, 1) 50%,
      rgba(11, 31, 49, 1) 79%,
      rgba(11, 31, 49, 1) 99%
    );
  }

  .img-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    img {
      background-color: $radivision-dk-gray;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }

    svg {
      width: 2rem;
      fill: white;
      position: absolute;
      top: 50%;
      bottom: -150px;
      left: 0;
      right: 0;
      margin: auto;
    }

    label {
      text-align: center;
      cursor: pointer;
      font-size: 0.8rem;
      position: absolute;
      position: absolute;
      top: 90px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 50%;
      font-weight: bold;
      color: white;
    }

    #upload-photo {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

  .profile-info-edit {
    display: inline-grid;

    @include media-breakpoint-down(lg) {
      margin-top: 200px;
    }

    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $snow-white;
      color: white;
    }

    .name {
      font-size: 2rem;
      font-weight: bold;

      @include media-breakpoint-down(lg) {
        font-size: 0.8rem;
      }
    }

    svg {
      width: 1.5rem;
      position: relative;
      bottom: 30px;
      left: 370px;
      fill: white;
      margin-left: 5%;

      @include media-breakpoint-down(lg) {
        left: 11rem;
      }
    }
  }

  .follow-svg {
    width: 25px;
    fill: white;
    margin-right: 8px;
    stroke: white;

    @include media-breakpoint-down(md) {
      width: 17px;
    }
  }

  .edit-btn {
    margin-top: 0.5rem;
    min-width: 17rem;

    @include media-breakpoint-down(lg) {
      min-width: 15rem;
    }
  }

  .btn-extra {
    padding: 13px 25px;
    margin-left: 11px;

    @include media-breakpoint-down(lg) {
      padding: 13px 7px;
    }
  }

  .more-menu-toggle {
    position: relative;
    margin-left: 0px;

    @media (min-width: 992px) and (max-width: 1199.9px) {
      margin-left: 50px;
    }

    // @include media-breakpoint-down(lg) {
    //   margin-left: 50px;
    //   // margin-top: 10px;
    // }

    &::after {
      content: none;
      display: none;
    }

    svg {
      width: 24px;
      max-height: 51px;
      // fill: $radivision-blue;
      fill: white;
    }
  }

  .dropdown-menu.more-menu {
    // position: absolute;
    border: 0;
    margin: 0;
    right: -20px !important;
    left: auto !important;
    top: 91px !important;
    padding: 15px 0;
    transition: all 0.4s;
    color: #fff !important;
    background-color: $dark-mode-black;
    border-radius: 16px;
    box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.34);
    @media (max-width: 576px) {
      transform: translate3d(-190px, 90px, 0px) !important;
    }
    @media (min-width: 576px) and (max-width: 750px) {
      transform: translate3d(-300px, 90px, 0px) !important;
    }
    @media (min-width: 750px) and (max-width: 950px) {
      transform: translate3d(-400px, 90px, 0px) !important;
    }

    @media (min-width: 950px) and (max-width: 1100px) {
      transform: translate3d(-500px, 90px, 0px) !important;
    }
    @media (min-width: 1100px) and (max-width: 1200px) {
      transform: translate3d(-560px, 90px, 0px) !important;
    }

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: -28px;
      right: 12%;
      z-index: 1;
      width: 0;
      height: 0;
      border-color: transparent;
      border-width: 8px;
      border-top-width: 14px;
      border-style: solid;
      border-bottom: 14px solid $dark-mode-black;
    }
    .dropdown-item {
      padding: 14px 1.5rem;
      cursor: pointer;
      font-size: 18px;
      font-family: "Muli";
      background-color: transparent !important;
      color: #fff;
      line-height: 17px;

      &:focus,
      &:hover {
        color: $radivision-blue;

        svg {
          color: $radivision-blue !important;
        }
      }
    }
  }

  .modal {
    .modal-body {
      @include media-breakpoint-down(md) {
        padding: 25px !important;
      }
      .form-side-bar {
        display: none;
      }
      @media (min-width: 1000px) {
        display: flex;
        flex-flow: row nowrap;
        .form-side-bar {
          display: flex;
          border-right: 1px solid #212121;
          width: 120px;
          margin-right: 20px;
          position: relative;
          flex-shrink: 0;
          .form-sections {
            list-style: none;
            position: sticky;
            top: 0px;
            padding: 0px;
            margin: 0px;
            li {
              padding: 0px;
              display: block;
              margin: 5px 0px;
              font-size: 16px;
              cursor: pointer;
              color: #ccc;
              &.active {
                font-family: MuliBold;
                color: white;
              }
              &:hover {
              }
            }
          }
        }
      }
    }

    form {
      flex-grow: 1;
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus,
      ::selection,
      ::-moz-selection {
        background-color: #2e2e2e;
      }
      .separator {
        background-image: none;
        border: 1px solid #2e2e2e;
        text-transform: capitalize;
        text-align: left;
        width: 100%;
        padding: 10px 15px;
        font-weight: 700;
        background-color: #2e2e2e;
        border-radius: 22px;
        font-family: "Muli";
        color: white;
      }

      .media-image-section {
        padding: 0;
      }

      @include media-breakpoint-up(xl) {
        .edit-profile-section {
          display: none;
        }

        &.active-section-page-info {
          .section-page-info {
            display: block;
          }
        }
        &.active-section-account {
          .section-account {
            display: block;
          }
        }
        &.active-section-links {
          .section-links {
            display: block;
          }
        }
        &.active-section-investment {
          .section-investment {
            display: block;
          }
        }
        &.active-section-about {
          .section-about {
            display: block;
          }
        }
        &.active-section-opportunities {
          .section-opportunities {
            display: block;
          }
        }
      }

      .changeBG {
        // padding-top: 2%;

        @include media-breakpoint-down(md) {
          padding-left: 0;
        }

        .row {
          margin-right: 0;
          margin-left: -5px;

          .background-section {
            padding: 0;

            .background {
              display: flex;
              padding: 0;

              canvas {
                width: 100% !important;
                height: 150px !important;
                border: 1px solid #161616;

                &.selected {
                  border: 2px solid #4cb4e7;
                }
              }
            }
          }

          div {
            cursor: pointer;
            padding: 0 5px;

            @include media-breakpoint-down(lg) {
              margin-bottom: 10px;
            }

            .bg-img {
              margin-bottom: 10px;
              padding: 0;
              height: 150px;

              @include media-breakpoint-down(lg) {
                height: 100%;
                width: 100%;
              }

              img {
                object-fit: cover;
                height: 100%;
                border: transparent;
                transition: all 0.3s;
                width: 100%;

                &.selected {
                  border: 2px solid #4cb4e7;
                }
              }
            }

            .img-options {
              width: 45px;
              height: 45px;
              background-color: #4cb4e7;
              position: absolute;
              z-index: 3;
              top: 0;
              right: 5px;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
              display: flex;
              align-items: center;
              display: none;

              svg {
                fill: #fff;
                width: 25px;
                height: 25px;
              }
            }

            &:hover {
              .img-options {
                display: flex;
              }
            }

            &.add-img {
              text-align: center;
              position: relative;
              display: flex;
              flex-direction: column;
              align-content: center;
              align-items: center;
              justify-content: center;
              min-width: 0;
              word-wrap: break-word;
              background-clip: border-box;
              border: 1px solid rgba(0, 0, 0, 0.125);
              background-color: rgba(59, 59, 59, 1);
              border-radius: 10px;
              height: 150px;
              font-family: "Muli";
              font-size: 14px;
              font-weight: bold;
              color: #8b8b8b;

              .selected {
                img {
                  border: 2px solid #4cb4e7;
                }
              }

              input {
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                cursor: pointer;
              }

              svg {
                fill: white;
                width: 40px;
                height: 40px;
                // padding: 10px;
                fill: #8b8b8b;
                // border-radius: 50%;
                margin-bottom: 10px;
              }

              span {
                display: block;

                &.delete-file {
                  background: #e40505;
                  color: #fff;
                  margin-bottom: 5px;
                }

                &.select-file {
                  background: #007bff;
                  color: #fff;
                }
              }

              .img-options {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s;
              }

              &:hover {
                .img-options {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }

      [type="radio"] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* IMAGE STYLES */
      [type="radio"] + img {
        cursor: pointer;
      }

      /* CHECKED STYLES */
      [type="radio"]:checked + img {
        outline: 2px solid #f00;
      }

      .btn-radivision {
        @include media-breakpoint-down(lg) {
          width: 70%;
        }
      }
    }
  }

  #claim-modal-container {
    .modal .modal-body {
      padding-top: 0px;
    }
  }
}

#editProfileModal,
#edit-social-links {
  .modal-footer {
    padding: 0;
    margin-top: 0;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .modal-body {
    min-height: 700px;
  }
  .edit-profile-section {
    margin: 15px;
    @media (max-width: 1000px) {
      padding-bottom: 35px;
      padding-top: 35px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .edit-profile-section-title {
      font-family: "MuliSemiBold";
      font-size: 17px;
      color: #fff;
      margin-bottom: 28px;
    }
    h3.tab-section {
      font-family: "MuliBold";
      font-size: 20px;
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }

  .rightPadding {
    padding-right: 0;
  }

  .leftPadding {
    padding-left: 0;
  }
}

.account-settings,
#editProfileModal {
  .profile-description {
    position: relative;

    textarea {
      padding-right: 64px !important;

      &:-webkit-scrollbar-track {
        width: 0px;
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        width: 0px;
        background-color: transparent;
      }
    }

    .profile-desc-chars {
      position: absolute;
      bottom: 0px;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      color: $dark-empty-placeholder;

      &.full-length {
        color: $radivision-blue;
      }

      &.warn {
        color: $danger;
      }

      i {
        font-style: normal;
      }

      i.profile-desc-charlength {
        margin-right: 4px;
      }
    }
  }
}

.allow-reset {
  position: relative;

  :-internal-autofill-selected {
    background-color: #2e2e2e;
  }
  select {
    background-color: #2e2e2e;
    border: 0;
    color: #fff;
    font-size: 13px;
    height: 43px;
    border-radius: 20px;
    width: 100%;
    padding: 0px 24px;
    -ms-word-break: normal;
    word-break: normal;
    &:focus,
    &:active {
      background-color: #2e2e2e;
      color: #fff;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #2e2e2e;
    background-image: url('data:image/svg+xml;utf8,<svg width="23.999999999999996" height="23.999999999999996" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title><path fill="white" stroke="null" d="m12.35714,15.36905l-5.97619,-7.60715l11.95238,0l-5.97619,7.60715l0.00001,0z" id="svg_1"/></g></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 5px);
    background-position-y: 12px;
  }
  .input-with-reset-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    select {
      background-position-x: calc(100% - 35px);
    }

    input {
      padding-right: 36px;
    }

    .reset-input {
      position: absolute;
      right: 7px;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        width: 28px;
        height: 28px;
        margin: 0 !important;
        fill: #8b8b8b;
      }
    }

    &.rightPadding {
      .reset-input {
        right: 8px;
      }
    }
  }
}

#social-platforms {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  min-height: 40px;
  margin: 0px 0px 10px 10px;
  a.select-platform {
    cursor: pointer;
  }
  a {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #212121;
    display: flex;
    margin: 0px 10px 10px 0px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      svg {
        width: 22px;
        height: 22px;
        fill: #888888;
      }
    }
  }
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23444444" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  filter: invert(1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
  color: #2e2e2e;
  &::placeholder {
    color: #2e2e2e !important;
  }
  & ~ svg {
    fill: #2e2e2e !important;
  }
  & ~ span {
    svg {
      fill: #2e2e2e !important;
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #202020 !important;
  color: #ffffff;
}
