.pitch-video-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  margin: 0px 0px 0px 0px;
  width: 100%;
  a {
    cursor: pointer;
  }
  .pitch-approval-status {
    display: flex;
    flex-flow: column wrap;
    margin: 0px 0px 20px 0px;
    padding: 10px 10px 10px 10px;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 570px) {
      padding: 10px 0px 10px 20px;
      flex-flow: column nowrap !important;
    }
    @media (max-width: 569px) {
      justify-content: center;
    }
    &.missing-video-button {
      flex-flow: row wrap !important;
    }
    .pitch-status-msg {
      display: flex;
      align-self: flex-start;
      align-items: center;
      flex-flow: column;
      @media (min-width: 570px) {
        align-items: flex-start;
        flex-flow: row nowrap;
      }
      @media (max-width: 569px) {
        justify-content: center;
      }
      span {
        min-width: 40px;
        svg {
          width: 32px;
          height: 32px;
          fill: $radivision-blue;
        }
      }
      h3 {
        margin: 10px 0px;
        padding: 0px;
        text-align: center;
        @media (min-width: 570px) {
          margin-left: 20px;
          text-align: left;
          margin: 0px;
        }
        p {
          font-size: 18px;
          margin: 0px 0px 5px 0px;
          padding: 0px;
        }
        span {
          display: block;
          font-size: 15px;
          line-height: 20px;
          margin: 0px;
          padding: 0px;
          color: white;
          a {
            color: $radivision-blue;
          }
        }
        button {
          background-color: transparent;
          border-color: transparent;
          padding: 0px;
          span {
            color: #4cb4e7;
            position: relative;
            top: 0.5px;
          }
        }
      }
    }
    .pitch-actions {
      display: flex;
      flex-grow: 1;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      align-items: center;
      margin: 10px 0px;
      width: 100%;
      @media (min-width: 570px) {
        justify-content: flex-end;
        margin: 20px 0px;
        width: auto;
      }
      .promote-pitch-button {
        button.btn-radivision {
          white-space: nowrap;
          margin: 0px !important;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          margin-right: 10px !important;
        }
      }
      .dropdown-menu {
        background-color: #212121;
        a.dropdown-item {
          color: #d00;
          width: auto;
          display: flex;
          margin: 5px 10px;
          box-sizing: border-box;
          text-align: center;
          &:hover {
            background-color: #d00;
            color: #ffffff;
          }
        }
      }
      #pitch-more-menu-dropdown {
        cursor: pointer;
        &::after {
          display: none;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      button {
        border-radius: 15px;
        height: 30px;
        min-height: unset;
        padding: 0px 20px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        font-size: 14px;
        color: white;
        &:hover {
          text-decoration: none;
        }
        @media (min-width: 570px) {
          padding: 0px 20px;
        }
        @media (max-width: 569px) {
          margin-left: 0px;
        }
        &.edit-pitch {
          border: 1px solid #aaaaaa;
          &:hover,
          &:active {
            border: 1px solid transparent;
            background-color: $radivision-blue;
          }
        }
        &.delete-pitch {
          border: 1px solid #aaaaaa;
          &:hover,
          &:active {
            border: 1px solid transparent;
            background-color: red;
          }
        }
      }
    }
    &.status-CREATED .CREATED,
    &.status-APPROVED .APPROVED,
    &.status-DECLINED .DECLINED {
      background-color: $radivision-blue;
      color: white;
      border: 1px solid transparent !important;
    }
  }
  .loader-container.spinner {
    background: none;
    background-color: none;
  }
  .pitch-card-content {
    width: 45%;
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }
  h3.section-title {
    font-size: 24px;
    font-family: MuliSemiBold;
    margin: 30px 0px 20px 0px;
  }
  .pitch-card {
    color: #fff;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    font-size: 36px;
    margin: 10px 0px;
    position: relative;
    text-align: center;
    justify-content: center;
    .pitch-tool-bar {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .play-in-mini-player-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        opacity: 0.5;
        svg {
          color: white;
          fill: white;
        }
        &.is-playing {
          opacity: 1;
        }
      }
    }
    .pitch-card-text {
      transition: opacity 0.3s ease-in;
      font-size: 15px;
      color: #fff;
      text-align: left;
      padding-bottom: 20px;
      h5 {
        font-size: 18px;
        margin-bottom: 40px;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          font-size: 15px;
          display: flex;
          flex-flow: row wrap;
          label {
            width: 30%;
            color: #ffffff;
          }
          span {
            width: 70%;
            color: #ccc;
          }
        }
      }
    }
    .pitch-card-description {
      font-size: 16px;
      text-align: left;
      padding: 20px 0px;
      h5 {
        margin-top: 30px;
        font-family: MuliBold;
        font-size: 17px;
      }
    }
    .pitch-card-title {
      transition: opacity 0.3s ease-in;
      margin: 0px 0px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      h5 {
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
      div.more-menu-circle {
        position: relative;
        width: 30px;
        margin: 0px 0px 0px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          margin-top: 4px;
          width: 22px;
          fill: white;
        }
        .more-menu-toggle {
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          background-color: #212121;
          border-radius: 20px;
          .dropdown-item {
            color: #fff !important;
            &:hover {
              background-color: transparent !important;
              color: red !important;
            }
          }
        }
      }
      .pitch-card-actions {
        display: flex;
        flex-grow: 0 !important;
        flex-flow: column;
        align-items: flex-end;
        .pitch-card-button {
          text-decoration: none;
          background-color: #19acf7;
          font-size: 14px;
          width: 140px;
          color: white;
          height: 36px;
          border-radius: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 10px;
          margin-right: 10px;
          white-space: nowrap;
          &.outline-button {
            background-color: transparent;
            border: 2px solid #ffffff;
          }
          &.more-info {
            background-color: #313131;
            border: 2px solid transparent;
            color: white;
            svg {
              width: 14px;
              height: 14px;
            }
          }
          svg.arrow-up-right {
            width: 22px;
            height: 22px;
            margin-right: 5px;
            transform: rotate(-45deg);
          }
          &.docsend {
            justify-content: space-evenly;
            background-repeat: no-repeat;
            background-position: center right;
            background-color: #ffffff;
            color: #000000;
            padding: 0px 10px;
            span {
              flex-shrink: 0;
              font-size: 14px;
              margin-right: 10px;
            }
          }
        }
      }
      .profile-picture-name {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        .profile-picture {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 3px solid white;
          margin-right: 10px;
          background-color: white;
          overflow: hidden;
        }
        .profile-name {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
  .pitch-card-video {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    overflow: hidden;
    @media (min-width: 570px) {
      width: 50%;
      &.centered-pitch {
        width: 70%;
      }
    }
  }
  .pitch-video-container {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    overflow: hidden;
  }
  .slick-slide:not(.slick-current) {
    .pitch-poster {
      filter: brightness(50%);
    }
  }
  .slick-current {
    .pitch-video {
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.5);
    }
  }
  .pitch-video-container {
    padding-bottom: 56.25%;
    flex-shrink: 0;
    height: 0px;
    position: relative;
    .pitch-video {
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-color: #171717;
      flex: 1;
      overflow: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }
    .pitch-poster {
      pointer-events: none;
      overflow: hidden;
      transition: all 0.8s linear;
      background-size: cover;
      background-position: center center;
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
    }
  }
  .center {
  }
  .center .pitch-card {
    transition: transform 300ms ease;
    transform: scale(0.95);
  }
  .center .slick-center .pitch-card {
    color: #e67e22;
    transform: scale(1.05);
  }
  .content {
    padding: 0px;
    margin: auto;
  }
  @media (max-width: 700px) {
    .content {
      width: 90%;
    }
  }
  .slick-slide .image {
    padding: 10px;
  }
  .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
    max-width: 80%;
  }
  .slick-slide img.slick-loading {
    border: 0;
  }
  .slick-slider {
    position: relative;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
  .slick-vertical .slick-slide {
    height: 280px;
  }
  .slick-arrow {
    position: absolute;
    display: flex;
    width: 50px;
    height: 50px;
    top: 50%;
  }
  .slick-arrow:hover {
  }
  .slick-arrow:focus {
  }

  .pitch-card-content {
    width: 100%;
    margin: 20px 0px 0px 0px;
    @media (min-width: 570px) {
      margin: 0px 0px 0px 0px;
      width: 45%;
    }
    .button {
      padding: 10px 20px;
      margin: 0px 20px;
      border: none;
      color: white;
      font-size: 20px;
      border-radius: 5px;
      min-height: 45px;
    }
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: calc(50% - 5px);
    width: 50px;
    height: 50px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    z-index: 1;
  }
  .slick-arrow svg {
    color: #ffffff;
    width: 50px;
    height: 50px;
  }
  .slick-next {
    right: 20px;
  }
  .slick-prev {
    left: 20px;
  }
  .slick-arrow:before,
  [dir="rtl"] .slick-prev:before {
    content: "";
    display: none;
  }
  .slick-slide,
  .pitch-card {
    outline: none;
    border-radius: 20px;
  }

  .pitch-video-container {
    background: #171717;
  }
  .pitch-get-discovered {
    top: unset;
    bottom: 40px;
    left: 40px;
    right: unset;
  }
  .pitch-carousel-header {
    display: flex;
    flex-flow: column;
    max-width: none;
    position: relative;
    margin: 20px 40px;
    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      a {
        display: none;
        font-size: 13px;
        margin-left: 20px;
        flex-wrap: nowrap;
        font-weight: normal;
        color: #19acf7;
        text-transform: uppercase;
        cursor: pointer;
        white-space: nowrap;
        svg {
          display: inline;
        }
      }
    }
    p {
      font-size: 13px;
    }
  }
  .is-promoted {
    display: flex;
    flex-flow: row nowrap;
    color: $radivision-blue;
    font-size: 13px;
    svg {
      width: 16px;
      fill: $radivision-blue;
      margin-right: 5px;
    }
  }
  .pitch-card {
    font-size: 24px;
    .pitch-card-title {
      flex-flow: column;
      margin: 0px 0px;
      height: auto;
      .pitch-card-actions {
        flex: 1;
        justify-content: space-between;
        .owner.more-info,
        .notify-me {
          button.btn-radivision {
            background-image: none;
            background-color: #313131;
          }
        }
        button.btn-radivision {
          width: 170px;
          display: flex;
          flex-flow: row nowrap;
          font-size: 15px;
          padding: 7px 20px;
          white-space: nowrap;
          align-items: center;
          justify-content: center;
          margin: 10px 0px;
          svg {
            width: 15px;
            height: 15px;
            fill: white;
            margin-left: 10px;
          }
        }
        .pitch-card-button {
          margin: 10px 0px;
          flex-grow: 1;
          font-size: 12px;
          max-width: calc(50vw - 56px);
          height: 32px;
          border-radius: 16px;
          padding: 7px 20px;
          &.outline-button {
          }
        }
      }
      .profile-picture-name {
        .profile-picture {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 3px solid white;
          margin-right: 10px;
          background-color: white;
          overflow: hidden;
        }
        .profile-name {
          color: white;
          font-size: 15px;
        }
      }
    }
  }
  .slick-arrow {
    width: 20px;
    height: 20px;
    display: flex;
    top: 23vw;
  }
  .slick-prev {
    left: 0px;
    display: flex;
    justify-content: flex-start;
  }
  .slick-next {
    right: 0px;
    display: flex;
    justify-content: flex-end;
  }
  .slick-arrow svg {
    width: 20px;
    height: 20px;
  }
  @media (min-width: 576px) {
    background-position: center bottom;

    .pitch-video-container {
    }
    .pitch-card {
      font-size: 24px;
      height: auto;
    }
    .slick-arrow {
      width: 20px;
      height: 20px;
      display: flex;
    }
    .slick-prev {
      left: 0px;
      display: flex;
      justify-content: flex-start;
    }
    .slick-next {
      right: 0px;
      display: flex;
      justify-content: flex-end;
    }
    .slick-arrow svg {
      width: 20px;
      height: 20px;
    }
  }
  @media (min-width: 768px) {
    padding: 0px 0px 0px 0px;
    background-position: center bottom;
    .pitch-video-container {
    }
    .pitch-card {
      font-size: 24px;
      height: auto;
      .pitch-card-title {
        flex-flow: row nowrap;
        margin: 10px 0px;
        height: auto;
        .pitch-card-actions {
          flex: 1;
          justify-content: space-between;
          .pitch-card-button {
            margin: 10px 0px;
            white-spacing: nowrap;
            flex-grow: 1;
            font-size: 15px;
            max-width: unset;
            height: 32px;
            border-radius: 16px;
            padding: 0px 20px;
            &.outline-button {
            }
          }
        }
        .profile-picture-name {
          .profile-picture {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 3px solid white;
            margin-right: 10px;
            background-color: white;
            overflow: hidden;
          }
          .profile-name {
            color: white;
            font-size: 15px;
          }
        }
      }
    }
    .center {
    }
    .center .slick-center .pitch-card {
      color: #e67e22;
      transform: scale(1.05);
    }
    .center .pitch-card {
      transform: scale(1);
      transition: all 300ms ease;
    }
    .slick-arrow {
      width: 30px;
      height: 30px;
      top: 16vw;
      display: flex;
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-arrow svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (min-width: 768px) {
    background-position: center bottom;
    .pitch-panel-header {
      max-width: 40vw;
    }
  }
  @media (min-width: 950px) {
    background-position: center bottom;
    .pitch-carousel-header {
      max-width: 40vw;
    }
  }
  @media (min-width: 1200px) {
    background-position: center bottom;
    .bottom-shadow {
      background: linear-gradient(
        0deg,
        rgba(22, 22, 22, 1) 0%,
        rgba(22, 22, 22, 1) 2%,
        rgba(22, 22, 22, 0) 50%,
        rgba(22, 22, 22, 0) 100%
      );
    }
    .top-shadow {
      background: linear-gradient(
        180deg,
        rgba(22, 22, 22, 1) 0%,
        rgba(22, 22, 22, 1) 2%,
        rgba(22, 22, 22, 0) 40%,
        rgba(22, 22, 22, 0) 100%
      );
    }
    .pitch-video-container {
    }
    .pitch-get-discovered {
      top: 20px;
      right: 20px;
      left: unset;
      bottom: unset;
    }
    .pitch-carousel-header {
      display: flex;
      flex-flow: column;
      max-width: 40vw;
      position: relative;
      margin: 20px 0px 20px 70px;
      h3 {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        font-weight: 800;
        margin-bottom: 0;
        font-size: 30px;
        letter-spacing: 0.075rem !important;
        font-family: "MuliExtraBold";
        a {
          font-size: 13px;
          display: none;
          margin-left: 20px;
          font-weight: normal;
          white-space: nowrap;
          text-transform: uppercase;
          color: #19acf7;
          svg {
            margin-left: 10px;
            display: flex;
          }
        }
      }
      p {
        font-size: 15px;
        font-weight: normal;
      }
    }
    .slick-slide {
      filter: grayscale(70%);
    }
    .slick-current {
      opacity: 1;
      filter: unset;
    }
    .pitch-card {
      margin: 0px;
      height: auto;
      .pitch-card-text {
        font-size: 16px;
      }
      .pitch-card-title {
        margin: 0px 0px;
        height: auto;
        flex-flow: row;
        .pitch-card-actions {
          justify-content: flex-start;
          .pitch-card-button {
            max-width: unset;
            font-size: 16px;
            flex-grow: 0;
            height: 32px;
            border-radius: 16px;
            padding: 0px 10px;
            margin: 0px 0px 15px 0px;
            &.outline-button {
              background-image: none;
              background-color: #313131;
              color: #ccc;
              border: 0px;
            }
            svg {
              color: white;
              width: 20px;
              height: 20px;
            }
          }
        }
        .profile-picture-name {
          .profile-picture {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 3px solid white;
            margin-right: 10px;
            background-color: white;
            overflow: hidden;
          }
          .profile-name {
            color: white;
            font-size: 15px;
          }
        }
      }
    }
    .pitch-video {
    }
    .pitch-poster {
    }
    .pitch-carousel-header {
    }
    .pitch-card {
      font-size: 24px;
    }
    .center {
    }
    .center .slick-center .pitch-card {
      color: #e67e22;
      transform: scale(1.05);
    }
    .center .pitch-card {
      transform: scale(0.96);
      transition: all 300ms ease;
    }
    .slick-arrow {
      width: 35px;
      height: 35px;
      top: 12vw;
      display: flex;
    }
    .slick-prev {
      left: 35px;
    }
    .slick-next {
      right: 35px;
    }
    .slick-arrow svg {
      width: 35px;
      height: 35px;
    }
  }
  @media (min-width: 1920px) {
    padding: 0px 0px 0px 0px;
    background-position: center center;
    .pitch-video-container {
    }
    .pitch-carousel-header {
      display: flex;
      flex-flow: column;
      max-width: 30vw;
      position: relative;
      margin: 20px 70px;
      h3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 800;
        margin-bottom: 0;
        font-size: 32px;
        letter-spacing: 0.075rem !important;
        font-family: "MuliExtraBold";
        a {
          font-size: 13px;
          display: none;
          align-items: center;
          margin-left: 20px;
          font-weight: normal;
          white-space: nowrap;
          text-transform: uppercase;
          color: #19acf7;
        }
      }
      p {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .pitch-card {
      font-size: 24px;
      .pitch-card-text {
      }
    }
    .center {
    }
    .center .slick-center .pitch-card {
      color: #e67e22;
      transform: scale(1.03);
    }
    .center .pitch-card {
      transform: scale(0.96);
      transition: all 300ms ease;
    }
    .slick-arrow {
      width: 50px;
      height: 50px;
      top: 12vw;
      display: flex;
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-arrow svg {
      width: 50px;
      height: 50px;
    }
  }
  &.centered-pitch {
    @media (min-width: 570px) {
      .pitch-card {
        justify-content: center;
        .pitch-card-video {
          width: 70%;
          margin-bottom: 10px;
        }
      }
    }
  }
}

#pitch-request-preview-modal {
  .pitch-card-video {
    width: 80%;
  }
}

.details-panel {
  .details-panel-video {
    &.PitchGrid {
      overflow: visible !important;
    }
  }
  .pitch-video-preview {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: -40px;
    .pitch-card {
      border-radius: 0px;
      .pitch-card-video {
        width: 100%;
        border-radius: 0px;
        overflow: visible !important;
      }
      .pitch-video-container {
        width: 100%;
        border-radius: 0px;
        overflow: visible !important;
        .pitch-video {
          width: 100%;
          border-radius: 0px;
          overflow: visible !important;
        }
      }
      .pitch-tool-bar {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}
.select-pitch-version {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
  flex: 1;
  a {
    color: #444;
    display: none;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    margin: 0px 0px 0px 0px;
    flex-grow: 0;
    text-decoration: none;
    &.selected {
      color: white;
      span {
        display: none;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  & > span {
    border-right: 1px solid #aaa;
    height: 25px;
    margin: 0px 15px;
    width: 1px;
  }
  &.has-quick {
    a.is-99sec {
      display: flex;
    }
  }
  &.has-full {
    a.is-5min {
      display: flex;
    }
  }
  &.has-full:not(.has-quick),
  &.has-quick:not(.has-full) {
    & > span {
      display: none;
    }
  }
  &.highlighted {
    a {
      color: $radivision-blue;
      opacity: 0.8;
      &.selected {
        opacity: 1;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        span.playing {
          display: block !important;
          color: #ccc;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}
.modal-body {
  .opportunities-container {
    padding: 0px 40px;
  }
}
