.grid-carousel {
  margin-top: 100px;

  .carousel-header {
    margin-bottom: 90px !important;
  }

  .rdv-show {
    padding: 0 70px;

    @include media-breakpoint-down(md) {
      padding: 0 35px;
    }
  }
}
.rdv-carousel-section {
  &.min-space {
    padding-bottom: 30px;

    @include media-breakpoint-down(xs) {
      padding-bottom: 15px;
    }
  }

  .masters-logo {
    display: none;
  }

  &.has-sep {
    padding-bottom: 55px;

    @media (min-width: 2500px) {
      margin-bottom: 100px;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 50px;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 35px;
    }

    hr {
      margin: 0 70px;
      margin-top: 40px;
      position: absolute;
      width: calc(100% - 140px);
      border-top: 4px solid rgba(0, 0, 0, 0.03);

      @include media-breakpoint-down(md) {
        width: 0;
        margin: 0 35px;
      }

      @media (max-width: 1169px) and (min-width: 768px) {
        width: calc(100% - 70px);
        margin: 0 35px;
        margin-top: 90px;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .carousel-header {
    margin: 0 70px;
    margin-bottom: 7px;
    display: flex;
    align-items: flex-end;

    .title-placeholder {
      display: block;
      height: 38px;
      width: 40%;
      max-width: 320px;
      background-color: $dark-empty-placeholder;

      &:empty {
        background-color: transparent;
      }
    }

    .view-all {
      margin-left: 12px;
      color: $radivision-blue;
      cursor: pointer;
      font-family: "Muli";
      font-weight: 500;
      font-size: 18px;
      display: flex;
      align-items: center;
      line-height: 30px;

      svg {
        height: 20px;
        margin-left: 4px;
        fill: $radivision-blue;
      }

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 24px;

        svg {
          height: 18px;
        }
      }

      @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 22px;

        svg {
          height: 14px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      margin: 0 35px;
    }
    @include media-breakpoint-down(sm) {
      margin: 0 24px;
    }

    .panel-title {
      color: #fff;
      &.clickable {
        cursor: pointer;
      }
    }
  }

  .rdv-show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 41px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: unset;
      margin-bottom: 24px;
    }

    a {
      cursor: pointer;

      img.award {
        @media (min-width: 2500px) {
          height: 150px;
        }
      }
    }

    .rdv-show-content {
      flex: 1;
      max-width: 100%;
      // overflow: hidden;

      @media (min-width: 2000px) {
        overflow: unset;
      }

      @media (min-width: 2500px) {
        padding-bottom: 130px;
      }
    }
  }

  .show-details {
    position: relative;
    flex: 0 0 30%;
    padding-left: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-self: stretch;
    z-index: 2;
    // pointer-events: none;

    & + .rdv-show-content {
      max-width: 100vw;

      @include media-breakpoint-down(sm) {
        max-width: 100vw;
      }
    }

    span.title-placeholder {
      width: 60%;
      height: 36px;
      background-color: $dark-empty-placeholder;
      display: block;
    }

    span.description-placeholder {
      display: block;
      width: 100%;
      margin-top: 18px;
      margin-bottom: 10px;

      span {
        display: block;
        height: 18px;
        background-color: $dark-empty-placeholder;
        margin-bottom: 8px;

        &:first-child {
          width: 100%;
        }

        &:last-child {
          width: 40%;
        }
      }
    }

    div.badges-placeholder {
      margin-bottom: 32px;

      span {
        display: inline-block;
        height: 64px;
        width: 64px;
        margin-right: 12px;
        background-color: $dark-empty-placeholder;
      }
    }

    // @media (max-width: 1280px) and (min-width: 1170px) {
    //   margin: 0 35px !important;
    // }

    @include media-breakpoint-down(md) {
      padding-left: 35px;
    }

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
      background-color: transparent;
      position: unset;
      padding-left: 24px;
      z-index: unset;
    }

    // @media (max-width: 1280px) {
    //   margin: 0 35px;
    // }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-left: 35px;
    }

    .logo-img {
      margin-bottom: 30px;
      border: none;
      max-width: 180px;
      max-height: 120px;
      width: auto;
      object-fit: contain;

      &.she {
        cursor: pointer;
      }

      @include media-breakpoint-up(xl) {
        max-width: 180px;
        margin-right: 30px;
        margin-bottom: 15px;
      }

      @include media-breakpoint-down(lg) {
        margin-bottom: 8px;
      }

      @include media-breakpoint-down(md) {
      }

      @include media-breakpoint-down(sm) {
        max-width: 40%;
        max-height: 80px;
        width: auto;
        object-fit: contain;
      }
    }

    .subtitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: normal;
      color: #aaa;
      padding-right: 50px;

      @media (min-width: 2000px) {
        font-size: 18px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 724px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 14px;
        line-height: 16px;
      }

      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 14px;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;

        & .REMOVED::after {
          display: block;
          content: "";
          position: absolute;
          background-image: linear-gradient(90deg, rgba(22, 22, 22, 0), $dark-mode-bg);
          height: 14px;
          bottom: 0;
          right: 0;
          width: 70%;
        }
      }

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .awards {
      @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
      }
      .award {
        height: 72px;

        // @media (max-width: 1536px) {
        //   height: 100px;
        // }

        // @media (max-width: 900px) {
        //   height: 75px;
        // }

        @include media-breakpoint-down(md) {
          height: 56px;
        }
      }
    }

    &.align-right {
      align-items: flex-end;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      .subtitle {
        text-align: right;
      }
    }
  }

  .rdv-carousel-mask {
    width: 100%;

    @media (min-width: 2000px) {
      overflow: unset;
    }
  }

  .rdv-carousel {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: content-box;
    scrollbar-width: auto;
    scrollbar-width: thin;
    scrollbar-width: none;
    padding: 21px 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-moz-scrollbars {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    @media (max-width: 1367px) {
      margin: 0;
    }

    .rdv-btn {
      width: 56px;
      background: rgba(22, 22, 22, 0.75);
      position: absolute;
      z-index: 1;
      cursor: pointer;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      color: #fff;
      padding: 0;
      border: none;
      border-radius: 0;

      @include media-breakpoint-down(md) {
        width: 24px;
        height: 100%;
      }

      &.rdv-carousel-prev {
        left: 0px;
        opacity: 1;
        transition: all 300ms;
        border-radius: 0 4px 4px 0;

        &.hidden {
          opacity: 0;
          cursor: default;
        }
      }

      &.rdv-carousel-next {
        right: 0px;
        border-radius: 4px 0 0 4px;
      }

      svg {
        opacity: 1;
        fill: #fff;
        width: 48px;
        max-width: 70px;
        max-height: 90px;
        transition: all 0.3s;
        transition-timing-function: cubic-bezier(0.5, 0.1, 0, 1);
      }
    }

    &:hover {
      .rdv-btn {
        background: rgba(50, 50, 50, 0.5);

        svg {
          visibility: visible;
          opacity: 1;
        }

        &:hover {
          svg {
            transform: scale(1.3);
            fill: #4cb4e7;
          }
        }
      }
    }

    &.no-arrows {
      .rdv-btn {
        display: none;
      }
    }

    .rdv-carousel-container {
      width: 100vw;
      display: flex;
      padding: 0 60px;

      @include media-breakpoint-down(md) {
        padding: 0 27px;
      }

      @mixin animate($direction, $basePercentFactor, $carouselPadding, $addFirstSlideSpace: 0) {
        $frame: calc(100% - (#{$carouselPadding * 2}));
        &.items-2 {
          margin-left: calc(
            (#{$direction} * ((#{$basePercentFactor} * (#{$frame})) + (#{$frame}) / 2)) -
              (#{$addFirstSlideSpace} * ((#{$frame}) / 2))
          );
        }

        &.items-3 {
          margin-left: calc(
            (#{$direction} * ((#{$basePercentFactor} * (#{$frame})) + (#{$frame}) / 3)) -
              (#{$addFirstSlideSpace} * ((#{$frame}) / 3))
          );
        }

        &.items-4 {
          margin-left: calc(
            (#{$direction} * ((#{$basePercentFactor} * (#{$frame})) + (#{$frame}) / 4)) -
              (#{$addFirstSlideSpace} * ((#{$frame}) / 4))
          );
        }

        &.items-5 {
          margin-left: calc(
            (#{$direction} * ((#{$basePercentFactor} * (#{$frame})) + (#{$frame}) / 5)) -
              (#{$addFirstSlideSpace} * ((#{$frame}) / 5))
          );
        }

        &.items-6 {
          margin-left: calc(
            (#{$direction} * ((#{$basePercentFactor} * (#{$frame})) + (#{$frame}) / 6)) -
              (#{$addFirstSlideSpace} * ((#{$frame}) / 6))
          );
        }

        &.items-7 {
          margin-left: calc(
            (#{$direction} * ((#{$basePercentFactor} * (#{$frame})) + (#{$frame}) / 7)) -
              (#{$addFirstSlideSpace} * ((#{$frame}) / 7))
          );
        }
        &.items-8 {
          margin-left: calc(
            (#{$direction} * ((#{$basePercentFactor} * (#{$frame})) + (#{$frame}) / 8)) -
              (#{$addFirstSlideSpace} * ((#{$frame}) / 8))
          );
        }
      }

      &.animating {
        transition: margin-left 0.7s cubic-bezier(0.5, 0.1, 0, 1);

        &.animating-left {
          margin-left: calc(-1 * (100% - (2 * 60px)));
        }

        @include media-breakpoint-down(md) {
          &.animating-left {
            margin-left: calc(-1 * (100% - (2 * 27px)));
          }
        }
      }

      &.silent {
        @include animate(-1, 1, 60px);

        &.animating {
          &.animating-left {
            @include animate(-1, 2, 60px);
          }

          &.animating-right {
            @include animate(0, 2, 60px, 1);
          }
        }

        @include media-breakpoint-down(md) {
          @include animate(-1, 1, 27px);

          &.animating {
            &.animating-left {
              @include animate(-1, 2, 27px);
            }

            &.animating-right {
              @include animate(0, 2, 27px, 1);
            }
          }
        }
      }

      .rdv-slide {
        position: relative;
        transition: width 0.2s cubic-bezier(0.5, 0.1, 0, 1), height 0.2s cubic-bezier(0.5, 0.1, 0, 1),
          top 0.2s cubic-bezier(0.5, 0.1, 0, 1);
        cursor: pointer;

        &.items-2 {
          flex: 0 0 calc(100% / 2);
        }

        &.items-3 {
          flex: 0 0 32%;
          margin: 8px 0px !important;
          box-sizing: border-box;
          max-width: 32%;
        }

        &.items-4 {
          flex: 0 0 calc(100% / 4);
        }

        &.items-5 {
          flex: 0 0 calc(100% / 5);
        }

        &.items-6 {
          flex: 0 0 calc(100% / 6);
        }

        &.items-7 {
          flex: 0 0 calc(100% / 7);
        }
        &.items-8 {
          flex: 0 0 calc(100% / 8);
        }

        .rdv-slide-content {
          .rdv-slide-img {
            position: relative;
            width: 100%;

            &::before {
              display: block;
              content: "";
              padding-top: calc(100% / (16 / 9));
            }
          }
        }

        &.SQUARE,
        &.CIRCLE {
          .rdv-slide-content {
            .rdv-slide-img {
              position: relative;
              width: 100%;

              &::before {
                display: block;
                content: "";
                padding-top: 100%;
              }
            }
          }
        }

        &.PORTRAIT {
          .rdv-slide-content {
            .rdv-slide-img {
              position: relative;
              width: 100%;

              &::before {
                display: block;
                content: "";
                padding-top: calc(100% / (320 / 457));
              }

              &.vertical-thumbnail {
                &::before {
                  padding-top: 177.78%;
                }
              }
            }
          }
        }

        .rdv-slide-content {
          margin: 0 4px;

          @include media-breakpoint-down(md) {
            margin: 0 3px;
          }

          .rdv-slide-img {
            border-radius: 5px;
            box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.34);
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              z-index: -1;
            }

            .new-episode {
              position: absolute;
              right: 2%;
              top: 0;
              padding: 4px 6px;
              background-color: $radivision-blue;
              color: white;
              text-transform: uppercase;
              letter-spacing: 0.075rem;
              font-size: 10px;
              font-weight: 800;
              // letter-spacing: 1px;
              opacity: 0.9;
            }

            .slide-img-rollover {
              position: absolute;
              width: 100%;
              height: 100%;
              overflow: hidden;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;

              .rollover-drawer {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
              }

              .play-btn {
                width: 64px;
                height: 64px;
                border: 2px solid white;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.2);
                fill: $radivision-blue;
                transition: all 0.3s;
                position: relative;
                z-index: 2;

                @include media-breakpoint-down(md) {
                  width: 32px;
                  height: 32px;
                }

                &:hover {
                  border-color: $radivision-blue;
                }
              }

              .ext-link {
                width: 200px;
                height: auto;
                fill: #fff;
                transform: scale(0.8, 0.97) rotate(-8deg);

                .link-arrow {
                  fill: $radivision-blue;
                }
              }

              .details-drawer-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: block;
                z-index: 1;
              }

              .details-drawer {
                display: none;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 35%;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
                align-items: flex-end;
                justify-content: center;
                z-index: 3;
              }
            }

            &.img-placeholder {
              background-color: $dark-empty-placeholder;
              img {
                opacity: 0;
                visibility: hidden;
              }
            }
          }

          img.rdv-slide-img[src=""] {
            background: #f8f8f8;
          }

          .rdv-slide-details {
            width: 100%;
            color: #fff;
            font-size: 12px;

            @media (min-width: 2000) {
              font-size: 16px;
            }

            @media (max-width: 1920px) {
              font-size: 13px;
            }

            &.hide-details {
              display: none;
            }

            h6 {
              font-size: 14px;
              text-transform: capitalize !important;
              // letter-spacing: 1px;
              font-family: "Muli";
              line-height: normal;
              margin-top: 15px;
              color: #888;

              @media (min-width: 2000) {
                margin-top: 20px;
              }

              @media (min-width: 1536px) and (max-width: 1600) {
                font-size: 14px;
              }

              &.extra-space {
                margin-top: 9px;
              }

              @media (min-width: 2000px) {
                font-size: 15px;
                line-height: normal;
                height: auto;
              }

              @media (min-width: 2500px) {
                font-size: 16px;
                line-height: 24px;
                height: 28px;
                margin-bottom: 8px;
              }

              span.publisher-name {
                flex: 1;

                &:empty {
                  height: auto;
                  display: none;
                }
              }

              span.duration {
                font-size: 12px;
                font-weight: 100;
                font-style: italic;
                margin-right: 1px;

                @media (min-width: 2000px) {
                  font-size: 15px;
                  margin-right: 3px;
                }
              }

              @media (max-width: 1440px) {
                font-size: 14px;
              }

              &:empty {
                height: auto;
                display: none;
              }
            }

            .story-icon svg {
              position: absolute;
              width: auto;
              max-width: 14px;
              max-height: 18px;
              fill: rgba(255, 255, 255, 0.5);
              stroke: rgba(255, 255, 255, 0.5);
              stroke-width: 1px;
              vertical-align: text-bottom;

              @media (min-width: 1537px) {
                width: 18px;
                height: 18px;
                max-width: 18px;
                max-height: 18px;
              }

              @media (min-width: 2500px) {
                height: 28px;
                width: 28px;
                max-width: 28px;
                max-height: 28px;
              }
            }

            p {
              line-height: 14px;
              // max-height: 28px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              margin: 2px 0;
              font-weight: normal;
              // letter-spacing: 0.5px;
              margin-bottom: 5px;
              font-family: "Muli";

              @media (min-width: 1500px) and (max-width: 1680px) {
                // line-height: 13px;
                max-height: 26px;
              }

              // span {
              //   br {
              //     display: none;
              //   }
              // }

              @media (min-width: 2000px) {
                font-size: 18px;
                line-height: normal;
                overflow: unset;
              }

              @media (max-width: 1440px) {
                font-size: 11px;
                // letter-spacing: 0;
              }

              @media (min-width: 2500px) {
                font-size: 22px;
                line-height: 24px;
                max-height: 50px;
                margin-bottom: 10px;
              }
            }

            @include media-breakpoint-down(sm) {
              p:not(:empty) {
                line-height: 14px;
                max-height: 28px;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-top: 2px;
                margin-bottom: 2px;

                // white-space: nowrap;
                span {
                  br {
                    display: none;
                  }
                }
              }
            }

            .margin-left {
              margin-left: 23px;

              @media (min-width: 2500px) {
                margin-left: 40px;
              }
            }

            .rdv-slide-info {
              @media (width: 1366px) {
                margin-top: 8px;
              }

              span {
                margin-right: 8px;
                font-size: 12px;
                font-weight: 100;

                @media (min-width: 2000px) {
                  font-size: 18px;
                }

                &:first-child {
                  font-weight: bold;
                }

                &:last-child {
                  margin-right: 0;
                  font-style: italic;
                }

                &:empty {
                  margin-right: 0;
                }
              }
            }

            @include media-breakpoint-down(lg) {
              h6 {
                font-size: 12px;
                line-height: 14px;
                min-height: 29px;
                max-height: 29px;
                overflow: hidden;
                position: relative;
                margin-bottom: 0;

                &::after {
                  display: block;
                  content: "";
                  position: absolute;
                  background-image: linear-gradient(90deg, rgba(22, 22, 22, 0), $dark-mode-bg);
                  height: 14px;
                  bottom: 0;
                  right: 0;
                  width: 70%;
                }
              }

              .rdv-slide-details {
                font-size: 10px;
              }

              .rdv-slide-info span {
                font-size: 10px;
              }
            }

            .publisher ~ h6 {
              font-weight: normal;
              font-family: "Muli";
            }
          }
        }

        &.CIRCLE {
          .rdv-slide-content {
            .rdv-slide-img {
              border-radius: 50% !important;
            }
          }
        }

        &:hover {
          .slide-img-rollover {
            animation: slideRollover 1.4s;
            animation-delay: 0.7s;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;

            &.no-rollover {
              background: none;
              animation: none;
            }
          }
        }

        &.has-details {
          .rdv-slide-content {
            .rdv-slide-img {
              .slide-img-rollover {
                .details-drawer {
                  display: flex;

                  svg {
                    fill: white;
                    width: 45px;
                    height: auto;
                    margin-bottom: 3%;
                    transition: all 0.3s;

                    .st0 {
                      fill: none;
                      stroke: #4cb4e7;
                      stroke-width: 2;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    }
                  }

                  &:hover {
                    svg {
                      fill: $radivision-blue;
                      transform: scale(1.2);
                    }
                  }
                }
              }
            }
          }
        }

        &.dotted-slide {
          text-align: center;
          position: relative;

          .rdv-slide-content {
            background: url("../static/dotted-frame.png") no-repeat;
            background-size: 100% 100%;

            .rdv-slide-img {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .dotted-content {
                padding: 20px;
                width: 100%;

                svg {
                  height: 18px;
                  display: block;
                  margin: auto;

                  .cls-2 {
                    fill: $radivision-blue;
                  }
                }

                .dotted-title {
                  font-size: 18px;
                  font-weight: 700;
                  padding-top: 4px;

                  @media (min-width: 576px) and (max-width: 1290px) {
                    font-size: 14px;
                    max-width: 100%;
                  }

                  @media (max-width: 1024px) {
                    font-size: 12px;
                  }

                  @media (min-width: 2000px) {
                    padding-top: 10px;
                  }
                }

                .btn-radivision {
                  margin-top: 5px;
                  margin-bottom: 0;

                  @media (min-width: 576px) and (max-width: 1290px) {
                    margin-top: 5px;
                    font-size: 13px;
                    padding: 8px 30px;
                  }

                  @media (max-width: 1024px) {
                    margin: 0;
                    padding: 8px 30px;
                    font-size: 10px;
                  }
                }
              }

              .slide-img-rollover {
                background: none;

                .details-drawer {
                  display: flex;
                  background: none;

                  svg {
                    fill: $radivision-blue;
                  }
                }
              }
            }
          }
        }

        &.panel-open {
          &:hover {
            .rdv-slide-content {
              .rdv-slide-img {
                .slide-img-rollover {
                  animation-delay: 0s;
                  background: none;

                  .details-drawer {
                    display: none;
                  }
                }
              }
            }
          }

          .rdv-slide-content {
            &:after {
              content: "";
              opacity: 0;
              width: 0;
              height: 0;
              position: absolute;
              z-index: 10;
              top: 100%;
              left: 50%;
              margin-left: -55px;
              border-style: solid;
              border-width: 24px 55px 0 55px;
              margin-top: 7px;
              border-color: #fff transparent transparent transparent;
              animation: slideRollover 0.4s;
              animation-fill-mode: forwards;
              animation-iteration-count: 1;

              @media (max-width: 560px) {
                margin-left: -35px;
                border-style: solid;
                border-width: 16px 35px 0 35px;
              }
            }
          }
        }

        .rdv-slide-hover {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-radius: 6px;
          background-color: #212121;
          box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.3);
          transition: all 0.2s;
          transition-delay: 0;
          z-index: 12;
          &.slide-visible {
            visibility: visible;
            opacity: 1;
          }

          .rdv-slide-img {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            box-shadow: none;

            .youtube-on-hover {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;

              & > div,
              iframe {
                width: 100%;
                height: 100%;
              }
            }
          }

          .slide-item-actions {
            padding: 0 19px;
            margin-top: 13px;
            display: flex;
            align-items: center;
            width: 100%;

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #525252;
              cursor: pointer;
              border-radius: 17px;
              flex: 1;
              margin: 0px 4px;
              padding: 7px;
              max-width: 60px;

              svg {
                height: calc(20px * 0.75);
                width: calc(20px * 0.75);
                fill: #fff;
              }

              &.play-btn {
                background-color: $radivision-blue;
              }

              &.saved {
                background-color: #fff;

                svg {
                  fill: $radivision-blue;
                }
              }

              @include media-breakpoint-down(md) {
                padding: 7px;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                max-width: 32px;
                box-sizing: border-box;
              }
              @include media-breakpoint-down(sm) {
                border-radius: 50%;
                width: 36px;
                height: 36px;
                max-width: 36px;
                box-sizing: border-box;
              }
            }

            @include media-breakpoint-down(md) {
              padding: 0px 5px;
              margin-top: 7px;
              box-sizing: border-box;
            }
          }

          .rdv-slide-details {
            padding: 15px 19px;
            overflow: hidden;

            h6 {
              margin: 0 !important;
              font-size: 14px;
              color: #fff;

              &::after {
                content: none;
              }
            }

            span {
              font-size: 14px;
              font-family: "Muli";
              color: rgba(255, 255, 255, 0.5);
              margin-top: 7px;
              text-align: center;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: block;
              overflow: hidden;
            }

            @include media-breakpoint-down(sm) {
              padding: 8px 11px;

              h6 {
                font-size: 11px;
              }

              span {
                margin-top: 4px;
              }
            }
          }
        }

        &.ecosys {
          .rdv-slide-hover {
            .slide-item-actions {
              justify-content: center;
            }

            .rdv-slide-details {
              h6 {
                font-size: 16px;
                font-family: "MuliBold";
              }

              @include media-breakpoint-down(sm) {
                h6 {
                  font-size: 14px;
                }
              }
            }
          }
        }

        &:hover {
          .rdv-slide-hover {
            visibility: visible;
            opacity: 1;
            transform: scale(1.25);
          }
        }

        &:not(.first):not(.last) {
          .rdv-slide-hover {
            transform-origin: center;
          }
        }

        &.first {
          .rdv-slide-hover {
            transform-origin: center left;
          }
        }

        &.last {
          .rdv-slide-hover {
            transform-origin: center right;
          }
        }
      }

      &.carouesl-grid {
        flex-wrap: wrap;
        padding: 0;

        .rdv-slide {
          margin-bottom: 70px;

          @include media-breakpoint-down(md) {
            margin-bottom: 35px;
          }
        }
      }
    }
  }

  &.rdv-circle-carousel .rdv-carousel .rdv-carousel-container .rdv-slide .rdv-slide-content {
    border-radius: 50%;
  }

  @include media-breakpoint-down(md) {
    .subtitle {
      width: 100% !important;
    }
  }

  @media (width: 768px) {
    .subtitle {
      max-width: 100%;
    }
  }
  &.masters {
    h3.panel-title {
      display: flex;
      align-items: center;
      .masters-logo {
        display: flex;
        width: 60px;
        min-width: 60px;
        height: 60px;
        border-radius: 30px;
        background-color: #aaaaaa;
        background-size: cover;
        background-position: center center;
        margin-right: 10px;
      }
      img {
        display: none;
      }
      img[src] {
        display: block;
      }
      .masters-title {
        text-transform: none;
        display: flex;
        flex-flow: column;
        font-size: 17px;
        .spotlight {
          font-size: 19px;
        }
      }
    }
    h6.subtitle {
      margin-top: 20px;
      margin-left: 30px;
      font-size: 16px;
      line-height: initial;
      font-weight: normal;
      padding-right: 30px;
      max-height: unset;
      ::after {
        display: none;
      }
    }
  }
}

div {
  .vid-bg {
    background: #32323b;
    // width: 50vw;
    // height: 50vh;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 100;
    transform: scale(1.1);
    opacity: 0.8;
    animation: crossfadeScale 0.6s ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: forwards;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @media (width: 1024px) {
      width: 65%;
    }

    iframe {
      width: 50vw;
      height: 50vh;
      border: 0;

      @media (width: 1024px) {
        width: 65%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .volume-control {
      cursor: pointer;
      max-width: 60px;
      position: absolute;
      right: 0;
      top: auto;
      bottom: 0;
      width: auto;
      height: auto;
      border-radius: 50%;
      position: absolute;
      margin: 0 auto;
      padding: 0;
      transform: translate(-50%, -70%);
      border: 0px solid #fff;
      background: none;

      @include media-breakpoint-down(sm) {
        max-width: 50px;
      }
    }

    // div:not(.exit-fs) {
    //   width: 100%;
    //   height: 100%;
    // }

    .exit-fs {
      position: absolute;
      z-index: 5;
      top: 85px;
      right: 25px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;

      @media (min-width: 1537px) {
        font-size: 16px;
      }

      .close-btn {
        width: 16px;
        height: 16px;
        fill: white;
        cursor: pointer;
        transition: all 0.3s;
        margin-left: 10px;
        position: unset;
      }

      &:hover {
        .close-btn {
          transform: scale(1.2);
        }
      }
    }
  }
}

.vid-bg {
  background: #32323b;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99999;
  transform: scale(1.1);
  opacity: 0.8;
  animation: crossfadeScale 0.6s ease-in-out;
  animation-direction: reverse;
  animation-fill-mode: forwards;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @media (width: 1024px) {
    width: 65%;
  }

  div {
    width: 100%;
    height: 100%;

    iframe,
    .iframe,
    .video,
    video {
      width: 100%;
      height: 100%;
      border: 0;

      @media (width: 1024px) {
        width: 65%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .volume-control {
    cursor: pointer;
    max-width: 60px;
    position: absolute;
    right: 0;
    top: auto;
    bottom: 0;
    width: auto;
    height: auto;
    border-radius: 50%;
    position: absolute;
    margin: 0 auto;
    padding: 0;
    transform: translate(-50%, -70%);
    border: 0px solid #fff;
    background: none;

    @include media-breakpoint-down(sm) {
      max-width: 50px;
    }
  }

  // div:not(.exit-fs) {
  //   width: 100%;
  //   height: 100%;
  // }

  .exit-fs {
    position: absolute;
    z-index: 5;
    top: 85px;
    right: 25px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    @media (min-width: 1537px) {
      font-size: 16px;
    }

    .close-btn {
      width: 16px;
      height: 16px;
      fill: white;
      cursor: pointer;
      transition: all 0.3s;
      margin-left: 10px;
      position: unset;
    }

    &:hover {
      .close-btn {
        transform: scale(1.2);
      }
    }
  }
}

div {
  .cover-img,
  .youtube-video {
    .about-video {
      display: block;
    }
  }
}

body.crossfade .main-navbar,
body.crossfade div.heroCarousel,
body.crossfade div.on-control,
body.crossfade div.rdv-carousel,
body.crossfade div.carousel-header,
body.crossfade .main-divider,
body.crossfade #onPanel,
body.crossfade #Footer {
  animation: crossfadeScale 0.6s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes crossfadeScale {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

@keyframes slideRollover {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .panel-subtitle {
    max-width: 50%;
  }
}

#show-upcoming,
#show-visionaries,
#show-driven {
  p:not(.text-content) {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;

    @media (min-width: 1360px) and (max-width: 1400px) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @media (max-width: 1024px) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @media (max-width: 1024px) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @include media-breakpoint-down(xs) {
      white-space: normal;
    }
  }
}

#organizations .story-icon svg,
#people .story-icon svg,
#startups .story-icon svg {
  display: none;
}

// #show-driven,
// #show-visionaries {
//   .carousel-header {
//     @media (max-width: 1280px) and (min-width: 1170px) {
//       margin: 0 35px !important;
//     }
//   }
// }

#show-sheconomics-visionaries-list .logo-img {
  @media (min-width: 1537px) {
    height: auto;
  }

  @media (min-width: 1440px) and (max-width: 1536px) {
    height: 70px;
  }

  @include media-breakpoint-down(md) {
    height: 50px;
    width: auto;
  }

  @include media-breakpoint-down(sm) {
    height: auto;
    width: auto;
  }
}

.rdv-panel {
  display: flex;
  margin-top: 30px;

  @media (min-width: 2000px) {
    margin-bottom: 30px;
  }

  .list-preview img {
    max-width: none;
  }

  .list-preview {
    position: relative;
    z-index: -1;

    img,
    .lazyload-placeholder {
      position: relative;
      height: 410px;
      width: calc((287 / 435) * 410px);
      margin-left: 70px;
      margin-bottom: 30px;

      @media (min-width: 3000px) {
        height: 665px;
        width: calc((287 / 435) * 665px);
      }

      @media (min-width: 2500px) and (max-width: 2999px) {
        height: 565px;
        width: calc((287 / 435) * 565px);
      }

      @media (min-width: 2100px) and (max-width: 2499px) {
        height: 495px;
        width: calc((287 / 435) * 495px);
      }

      @media (min-width: 2000px) and (max-width: 2099px) {
        height: 470px;
        width: calc((287 / 435) * 470px);
      }

      @media (max-width: 1536px) {
        height: 325px;
        width: calc((287 / 435) * 325px);
      }

      @media (max-width: 1440px) {
        height: 305px;
        width: calc((287 / 435) * 305px);
      }

      @media (max-width: 1400px) {
        height: 298px;
        width: calc((287 / 435) * 298px);
      }

      @media (max-width: 1367px) {
        height: 304px;
        width: calc((287 / 435) * 304px);
      }

      @media (max-width: 1280px) {
        height: 294px;
        width: calc((287 / 435) * 294px);
      }

      @media (max-width: 1247px) {
        height: 290px;
        width: calc((287 / 435) * 290px);
      }

      @media (max-width: 1200px) {
        height: 300px;
        width: calc((287 / 435) * 300px);
      }

      @media (max-width: 1171px) {
        height: 290px;
        width: calc((287 / 435) * 290px);
      }

      @media (max-width: 1024px) {
        height: 253px;
        width: calc((287 / 435) * 253px);
      }

      @media (max-width: 996px) {
        height: 250px;
        width: calc((287 / 435) * 250px);
      }

      @media (max-width: 916px) {
        height: 248px;
        width: calc((287 / 435) * 248px);
      }

      @media (max-width: 800px) {
        height: 270px;
        width: calc((287 / 435) * 270px);
      }

      @media (max-width: 768px) {
        height: 262px;
        width: calc((287 / 435) * 262px);
      }

      @media (max-width: 560px) {
        display: none;
      }

      @media (max-width: 1024px) and (min-width: 769px) {
        margin-left: 35px;
      }

      @include media-breakpoint-down(md) {
        margin-left: 35px;
      }
    }
  }
}

.rdv-panel-header {
  position: relative;

  .logo-preview {
    .on-curated-header .on-curated img {
      height: 39px;
      margin-right: 3px;
      width: auto;
    }

    span {
      line-height: normal !important;
    }
  }

  .logo-img {
    margin-bottom: 2px;
    border: none;
    height: 58px;
    cursor: pointer;
    width: auto;

    @media (max-width: 1536px) {
      height: 47px;
    }

    @media (max-width: 1440px) {
      height: 44px;
      width: auto;
    }

    @media (min-width: 1200px) and (max-width: 1280px) {
      height: 46px;
    }

    @media (width: 768px) {
      height: 45px !important;
      width: auto !important;
    }

    @media (max-width: 800px) {
      height: auto;
      width: 30%;
    }

    @include media-breakpoint-down(md) {
      height: 44px;
    }

    @include media-breakpoint-down(sm) {
      width: 40%;
      height: auto;
    }

    @include media-breakpoint-down(xs) {
      width: 100px;
    }
  }

  .subtitle {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px;
    margin-left: 0;

    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }

    @media (min-width: 1500px) and (max-width: 1536px) {
      max-width: 55%;
      font-size: 16px;
    }

    @media (width: 1366px) {
      max-width: 56% !important;
    }

    @media (min-width: 1025px) and (max-width: 1440px) {
      font-size: 16px;
      max-width: 52%;
    }

    @media (width: 1024px) {
      max-width: 47%;
    }

    @media (width: 768px) {
      max-width: 54%;
    }

    @include media-breakpoint-down(md) {
      margin-top: 5px;
      font-size: 14px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 13px;
      width: 100%;
      margin-top: 0px;
      margin-left: 0px;
    }

    @media (min-width: 2000px) {
      font-size: 30px;
      max-width: 60%;
    }
  }

  .awards {
    float: right;

    .award {
      @media (max-width: 1536px) {
        height: 100px;
      }

      @media (max-width: 900px) {
        height: 75px;
      }

      @media (max-width: 500px) {
        height: 70px;
      }
    }
  }
}

.hyberLink a {
  color: #4cb4e7;
  cursor: pointer;
  text-decoration: none;
}

.carousel-panel {
  z-index: 89;
  @include media-breakpoint-up(xl) {
    min-height: 285px;
    margin-top: -60px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 245px;
    margin-top: -50px;
  }

  @include media-breakpoint-down(md) {
    min-height: 213px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 250px;
  }
}
