/* Tooltip container */
.has-tooltip {
  position: relative;
  .tooltip {
    visibility: hidden;
    opacity: 0;
    white-space: nowrap;
    background-color: #f5f5f5;
    color: #212121;
    text-align: center;
    padding: 3px 6px;
    border-radius: 6px;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-size: 13px;
    transition: opacity 0.3s linear;
    &::after {
      content: " ";
      position: absolute;
      top: 98%;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: #f5f5f5 transparent transparent transparent;
    }
  }
  &.tooltip-bottom {
    .tooltip {
      bottom: unset;
      top: 120%;
      &::after {
        bottom: 98%;
        top: unset;
        border-color: transparent transparent #f5f5f5 transparent;
      }
    }
  }
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}
.slide-item-actions {
  .tooltip {
    visibility: hidden!important;
  }
}
.label-tooltip {
  margin-left: 10px;
  position: relative;
  .tooltip-icon {
    svg {
      color: white;
      fill: white;
      width: 14px;
      height: 14px;
    }
  }
  .tooltip {
    &::after {
      content: " ";
      position: absolute;
      top: 98%;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: #f5f5f5 transparent transparent transparent;
    }
  }
}
