span.save-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #525252;
  cursor: pointer;
  border-radius: 17px;
  flex: 1;
  margin: 0px 4px;
  padding: 7px;
  max-width: 60px;

  svg {
    height: calc(20px * 0.75);
    width: calc(20px * 0.75);
    fill: #fff;
  }

  &.play-btn {
    background-color: $radivision-blue;
  }

  &.saved {
    background-color: #fff;

    svg {
      fill: $radivision-blue;
    }
  }

  @include media-breakpoint-down(md) {
    &:not(.with-label) {
      padding: 7px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      max-width: 32px;
      box-sizing: border-box;
    }
  }
  @include media-breakpoint-down(sm) {
    &:not(.with-label) {
      border-radius: 50%;
      width: 36px;
      height: 36px;
      max-width: 36px;
      box-sizing: border-box;
    }
  }
  &.with-label {
    width: 120px;
    max-width: 120px;
    background-color: white;
    color: black;
    border-radius: 25px;
  }
}
