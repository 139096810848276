/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2019-05-11
// Original author: Muhammad Omran
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.hero-container.xxtra {
  #heroCarousel.xxtra {
    .carousel-item {
      .image-slide {
        .overlay {
          display: none;
        }
      }

      .text-block {
        height: 100%;
        padding-top: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @include media-breakpoint-between(xs, sm) {
          padding-top: 70px;
          width: 100%;
        }

        .list-logo {
          width: 55%;

          @media (max-width: 1280px) and (min-width: 768px) {
            width: 45% !important;
          }

          @include media-breakpoint-down(md) {
            width: 50% !important;
          }

          @include media-breakpoint-down(xs) {
            width: 35%;
          }
        }

        .subtitle {
          font-family: "ITC", "Muli", sans-serif;
          font-size: 110px;
          color: #fff;
          text-transform: capitalize !important;
          margin: 0;
          overflow: visible;
          margin-bottom: 0;
          white-space: normal;
          text-align: center;
          -webkit-line-clamp: unset;
          -webkit-box-orient: unset;

          @media (max-width: 1536px) {
            font-size: 85px;
          }

          @media (max-width: 1440px) {
            font-size: 80px;
          }

          @media (max-width: 1280px) and (min-width: 768px) {
            font-size: 2.5rem !important;
          }

          @include media-breakpoint-down(md) {
            font-size: 2.5rem !important;
            margin-bottom: 5px;
          }

          @include media-breakpoint-down(xs) {
            font-size: 1.25rem;
            margin-bottom: 5px;
          }
        }

        .description {
          font-family: "BarlowCondensed", "Muli", sans-serif;
          font-style: italic;
          color: $dark-gray;
          font-size: 50px;
          font-weight: 600;
          text-transform: capitalize;

          @media (max-width: 1536px) {
            font-size: 40px;
          }

          @media (max-width: 1440px) {
            font-size: 35px;
          }

          @media (max-width: 1280px) and (min-width: 768px) {
            font-size: 24px !important;
          }

          @include media-breakpoint-down(md) {
            font-size: 22px !important;
          }

          @include media-breakpoint-down(xs) {
            display: block;
          }
        }
      }
    }
  }

  // @media (min-width: 1024px) {
  //   max-height: 91vh !important;
  // }

  @media (max-width: 1023px) {
    max-height: 100% !important;
  }
}

.on-control.xxtra {
  background: none;
  color: #32323b;

  .flex-center {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 10px 70px;

    @media (width: 768px) {
      align-items: unset;
    }

    @include media-breakpoint-down(md) {
      align-items: unset;
    }

    .campain-Intro {
      width: 72%;
      margin: 15px auto;

      @media (width: 1440px) {
        width: 68%;
      }

      @media (max-width: 1280px) and (min-width: 769px) {
        width: 73%;
      }

      @include media-breakpoint-down(md) {
        width: 82%;
        justify-content: left;
      }

      p {
        margin: 0;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        font-family: "MuliSemiBold";

        @media (min-width: 1921px) {
          font-size: 2.1rem;
          line-height: 38px !important;
        }

        @media (width: 1536px) {
          font-size: 19px;
        }

        @media (width: 1440px) {
          font-size: 17px;
        }

        @media (max-width: 1280px) and (min-width: 768px) {
          font-size: 17px;
          width: 100%;
          text-align: left;
        }

        @include media-breakpoint-down(md) {
          font-size: 14px;
          width: 100%;
          text-align: left;
        }

        @include media-breakpoint-down(xs) {
          font-size: 13px;
          width: 100%;
          line-height: 1.7;
        }
      }

      ul {
        margin: 10px 20px;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        font-family: "MuliSemiBold";

        @media (width: 1536px) {
          font-size: 19px;
        }

        @media (width: 1440px) {
          font-size: 17px;
        }

        @media (max-width: 1280px) and (min-width: 768px) {
          font-size: 17px;
          margin: 10px;
        }

        @include media-breakpoint-down(md) {
          font-size: 14px;
          margin: 10px 0;
          padding: 0;
        }

        @include media-breakpoint-down(sm) {
          font-size: 13px;
        }

        li {
          margin-left: 60px;

          @include media-breakpoint-down(md) {
            margin-left: 30px;
          }
        }
      }

      .statistics {
        margin: 30px 0;

        @media (max-width: 1280px) and (min-width: 769px) {
          margin: 30px 5px;
        }
      }
    }
  }

  .flex-left {
    width: 100%;
    display: inline-block;
    padding: 40px 70px;
    padding-bottom: 25px;

    @include media-breakpoint-down(md) {
      margin: 35px;
    }

    @media (width: 1024px) {
      margin: 35px;
    }

    @include media-breakpoint-down(sm) {
      margin: 35px;
    }

    .campain-Intro {
      width: 60%;

      @media (width: 1440px) {
        width: 68%;
      }

      @media (max-width: 1280px) and (min-width: 769px) {
        width: 73%;
      }

      @include media-breakpoint-down(md) {
        width: 75%;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      p {
        margin: 0;
        text-align: left;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        font-family: "MuliSemiBold";

        @media (min-width: 1921px) {
          font-size: 2.1rem;
          line-height: 38px !important;
        }

        @media (width: 1536px) {
          font-size: 19px;
        }

        @media (width: 1440px) {
          font-size: 17px;
        }

        @media (max-width: 1280px) and (min-width: 768px) {
          font-size: 17px;
          width: 100%;
          text-align: left;
        }

        @include media-breakpoint-down(md) {
          font-size: 14px;
          width: 100%;
          text-align: left;
        }

        @include media-breakpoint-down(xs) {
          font-size: 13px;
          width: 100%;
          line-height: 1.7;
        }
      }
    }

    .monaIntro {
      width: 25%;
      display: inline-block;
      position: relative;
      right: -15%;
    }
  }

  &>div {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .compaign-logo {
      width: 100%;
      max-width: 90%;
      margin-bottom: 0px;

      @media (width: 1440px) {
        max-width: 85%;
      }

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
  }
}

.awards.xxtra {
  display: inline-block;

  @include media-breakpoint-down(xs) {
    position: relative;
  }

  .award {
    width: 150px;

    @media (min-width: 2000px) {
      width: 175px;
    }

    @media (width: 1440px) {
      width: 140px;
    }

    @media (width: 1024px) {
      width: 125px;
      margin-left: 3px;
    }

    @include media-breakpoint-down(md) {
      width: 100px;
    }

    @include media-breakpoint-down(sm) {
      width: 80px;
      margin-left: 3px;
    }
  }
}

.details-panel .video-tab .logo-img.xxtra {
  width: auto;
  max-width: 450px;

  @media (width: 1440px) {
    width: 33%;
  }

  @media (width: 1024px) {
    width: 35%;
  }
}

.details-panel .video-tab .text-content.xxtra {
  font-weight: 100;
  font-size: 20px !important;
  // letter-spacing: 0.6px;
  line-height: 21px;
  color: #f2f4f4;
  width: 60%;

  @media (min-width: 2000px) {
    font-size: 22px !important;
    width: 55%;
    margin-top: 10px;
    line-height: 24px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    font-size: 14px !important;
    margin: 15px 0;
  }
}

.details-panel .video-tab .text-block.xxtra {
  max-width: 100%;
}

.gradient.xxtra {
  width: 75% !important;

  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
  }

  .modal-open {
    overflow: hidden;
    overflow-y: auto;

    .modal {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  &::after {
    width: 30% !important;
  }
}

.compain-logo {
  width: 40px;
  margin-right: 10px;
  vertical-align: baseline;

  @media (min-width: 1366px) and (max-width: 1536px) {
    width: 35px;
  }

  @media (width: 1024px) {
    width: 30px;
  }

  @include media-breakpoint-down(md) {
    width: 22px;
  }
}

.blackBGWhiteText {
  background-color: #46464e;
  color: white;
  padding: 0 !important;

  .campain-Intro {
    width: 60% !important;
    margin: 0 auto !important;
    padding: 40px 70px 25px !important;

    @media (min-width: 1440px) and (max-width: 1440px) {
      width: 65% !important;
    }

    @media (max-width: 1024px) and (min-width: 769px) {
      width: 66% !important;
      padding: 40px 35px 25px !important;
    }

    @media (max-width: 1170px) and (min-width: 1024px) {
      width: 75% !important;
      padding: 30px 70px !important;
      padding-bottom: 10px !important;
    }

    @include media-breakpoint-down(md) {
      width: 80% !important;
    }

    @include media-breakpoint-down(sm) {
      padding: 35px !important;
      padding-bottom: 10px !important;
    }

    @include media-breakpoint-down(xs) {
      width: 100% !important;
    }

    p {
      margin: 0;
      text-align: center;
      font-size: 22px;
      font-weight: unset !important;
      line-height: 26px !important;
      font-family: "Muli" !important;
      // letter-spacing: 0.58px;
      color: white;

      @media (min-width: 1921px) {
        font-size: 2.1rem;
        line-height: 38px !important;
      }

      @media (width: 1536px) {
        font-size: 19px !important;
      }

      @media (max-width: 1440px) and (min-width: 1360px) {
        font-size: 17px !important;
      }

      @media (max-width: 1280px) and (min-width: 768px) {
        font-size: 17px !important;
        width: 100%;
        text-align: center !important;
      }

      @media (max-width: 1170px) and (min-width: 768px) {
        font-size: 15px !important;
        width: 100%;
        text-align: center !important;
      }

      @include media-breakpoint-down(md) {
        font-size: 14px !important;
        width: 100%;
        text-align: center !important;
      }

      @include media-breakpoint-down(xs) {
        font-size: 13px !important;
        width: 100%;
        line-height: 19px !important;
        text-align: center !important;
      }
    }
  }

  .campain-Intro:empty {
    padding: 0 !important;
  }
}

.craySection {
  padding: 0;
  margin-top: 0;
}