.checkbox-control {
  display: flex;
  flex-flow: row nowrap;
  .checkbox-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 5px;
    background: #2e2e2e;
    svg {
      width: 18px;
      height: 18px;
      fill: $radivision-blue;
    }
  }
  label {
    font-size: 15px;
    text-align: left;
    a {
      text-decoration: underline;
      color: $radivision-blue;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
    p {
      text-align: left!important;
    }
  }
}
