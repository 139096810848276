@keyframes cf3FadeInOut {
  0% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  55% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.hero-container {
  object-fit: cover;
  width: 100vw;
  height: calc((100vw / (16 / 9)) * 0.9);
  max-height: 100vh;
  position: relative;

  &.featured {
    @media (min-width: 1200px) {
      height: calc((100vw / (16 / 9)) * 0.87);
    }
    @media (min-width: 1600px) {
      height: calc((100vw / (16 / 9)) * 0.87);
    }
  }

  .volume-control {
    cursor: pointer;
    max-width: 57px;
    position: absolute;
    right: 85px;
    top: auto;
    z-index: 10;
    pointer-events: all;
    bottom: 100px;

    @media (min-width: 1200px) {
      width: 60px;
      max-width: 60px;
      bottom: 100px;
    }
    @media (min-width: 2500px) {
      max-width: 120px;
      right: 72px;
      bottom: 40px;
      width: 70px;
    }
    @media (min-width: 1600px) {
      width: 80px;
      max-width: 80px;
      bottom: 130px;
    }
    @include media-breakpoint-down(xl) {
      max-width: 42px;
      right: 85px;
      bottom: 70px;
    }
    @include media-breakpoint-down(lg) {
      max-width: 42px;
      right: 85px;
      bottom: 17%;
    }

    @include media-breakpoint-down(md) {
      max-width: 42px;
      right: 62px;
      bottom: 56px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 36px;
      right: 50px;
      bottom: 20px;
    }
  }

  .hero {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    // @media only screen and (min-width: 1024px) {
    //   max-height: 91vh;
    // }

    .carousel-inner {
      height: 100%;

      // @media only screen and (min-width: 1024px) {
      //   max-height: 91vh;
      // }
    }

    .shadow {
      box-shadow: 0px 0px 0px transparent !important;
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      background-position: 0 top;
      background-repeat: repeat-x;
      background-color: transparent;
      width: 100%;
      height: 22vw;
      top: auto;
      bottom: -1px;
      opacity: 1;
      position: absolute;
      left: 0;
      pointer-events: none;

      @include media-breakpoint-down(sm) {
        height: 32vw;
      }
    }
    .overlay {
      background: linear-gradient(to top, rgba(22, 22, 22, 1) 0%, rgba(22, 22, 22, 0) 20%) !important;
      margin-bottom: -1px;
      z-index: 1;
    }

    .featured-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 70px;
      position: relative;

      .video-js .vjs-tech {
        object-fit: cover;
      }
      @include media-breakpoint-up(xl) {
        align-items: flex-end;
      }

      @media (min-width: 1600px) {
        align-items: flex-end;
      }
      @include media-breakpoint-down(md) {
        padding-left: 35px;
      }

      .featured-item-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &:after {
          display: block;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(to top, rgba(22, 22, 22, 1) 0%, rgba(22, 22, 22, 0) 10%) !important;
        }

        .image-backdrop {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .image-backdrop,
        .video-backdrop {
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: 0px;
          left: 0px;
        }
      }

      .featured-item-details {
        max-width: 45%;
        position: relative;
        z-index: 1;
        @include media-breakpoint-up(xl) {
          bottom: 30%;
        }

        @media (min-width: 1600px) {
          bottom: 30%;
        }

        @include media-breakpoint-down(sm) {
          max-width: 55%;
        }
      }

      .featured-item-logo {
        margin-bottom: 28px;
        height: 190px;
        width: auto;
        max-width: 339px;

        &.carousel,
        &.headshot,
        &.square {
          img {
            box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.34);
            border-radius: 16px;
          }
        }

        &.headshot {
          img {
            border-radius: 50%;
          }
        }

        img {
          width: auto;
          height: 100%;
        }

        @include media-breakpoint-down(md) {
          height: 110px;
        }

        @include media-breakpoint-down(sm) {
          margin-bottom: 14px;
        }

        &.img-placeholder {
          background-color: $dark-empty-placeholder;
          img {
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      .fetaured-item-title {
        font-size: 42px;
        font-family: "MuliBold";
        letter-spacing: 1.1px;
        line-height: 44px;
        color: #fff;
        margin-bottom: 12px;

        &.masters {
          span:nth-child(1) {
            font-size: 28px;
            display: block;
          }
        }

        span.title-placeholder {
          width: 60%;
          height: 36px;
          background-color: $dark-empty-placeholder;
          display: block;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }

        @include media-breakpoint-down(sm) {
          width: 30%;
          &.masters {
            span:nth-child(1) {
              font-size: 16px;
              line-height: 18px;
              margin-bottom: 10px;
              display: block;
            }
            span:nth-child(2) {
              font-size: 18px;
              line-height: 22px;
              display: block;
            }
          }
        }
      }

      .fetaured-item-description {
        font-size: 22px;
        color: #fff;
        font-family: "Muli";
        margin-bottom: 28px;
        line-height: normal;
        text-overflow: ellipsis;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
        text-align: left;

        @include media-breakpoint-up(xl) {
          font-size: 1.1em;
        }

        @media (min-width: 1600px) {
          font-size: 1.3em;
        }

        @include media-breakpoint-down(lg) {
          font-size: 1.8vw;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }

        span.description-placeholder {
          span {
            display: block;
            height: 18px;
            background-color: $dark-empty-placeholder;
            margin-bottom: 8px;

            &:first-child {
              width: 100%;
            }

            &:last-child {
              width: 40%;
            }
          }
        }
      }

      .featured-item-details.use-dark-color {
        .fetaured-item-title,
        .fetaured-item-description {
          color: #000;
        }
      }

      .featured-item-button {
        font-family: "MuliSemiBold";
        color: #fff;
        background-color: $radivision-blue;
        padding: 18px 33px;
        border-radius: 30px;
        letter-spacing: 0.5px;
        line-height: 18px;
        font-size: 22px;
        width: auto !important;
        border: none;
        cursor: pointer;
        svg {
          fill: white;
          font-size: 18px;
          width: 22px;
        }
        @include media-breakpoint-up(xl) {
          padding: 15px 33px;
          height: 46px;
          font-size: 20px;
        }

        @media (min-width: 1600px) {
          padding: 15px 33px;
          height: 46px;
        }

        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 16px;
          padding: 0px 28px;
          svg {
            display: inline-block;
            fill: white;
            width: 16px;
            max-height: 100%;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &:not(.featured) + div > .rdv-carousel-section {
    margin-top: 1%;

    @include media-breakpoint-down(sm) {
      margin-top: 35px;
    }
  }

  &:not(.featured) + div > .rdv-carousel-section > div.rdv-show,
  &:not(.featured) + div > .rdv-carousel-section > div.carousel-header {
    position: relative;
    z-index: 3;
  }

  &.featured + div > .rdv-carousel-section {
    margin-top: -6%;

    @include media-breakpoint-down(lg) {
      margin-top: 70px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 70px;
    }
  }

  &.featured + div > .rdv-carousel-section > div.rdv-show,
  &.featured + div > .rdv-carousel-section > div.carousel-header,
  & + .featured-filter-tabs + div > .rdv-carousel-section > div.rdv-show,
  & + .featured-filter-tabs + div > .rdv-carousel-section > div.carousel-header,
  & + .featured-filter-tabs ~ div.first-found > .rdv-carousel-section > div.rdv-show,
  & + .featured-filter-tabs ~ div.first-found > .rdv-carousel-section > div.carousel-header {
    position: relative;
    z-index: 10;
  }

  .slide-actions {
    display: flex;
    margin-top: 45px;
    align-items: center;
    height: 45px;

    @include media-breakpoint-down(xl) {
      margin-top: 30px;
      height: 44px;
    }

    @media (min-width: 1600px) {
      height: 46px;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 18px;
      height: 44px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 18px;
      height: 44px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 6px;
      height: 30px;
      max-width: 75%;
    }

    button {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-family: "MuliSemiBold";
      padding: 5px 15px;
      height: 100%;
      min-width: auto;

      @include media-breakpoint-up(xl) {
        box-sizing: border-box;
        padding: 10px 25px;

        svg {
          width: 14px;
          height: 14px;
          margin-left: 0px;
          margin-right: 8px;
          margin-top: 2px;
          fill: #fff;
        }
      }

      @media (min-width: 1600px) {
        box-sizing: border-box;
        padding: 10px 25px;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          fill: #fff;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-right: 7px;
        padding: 2px 15px;

        svg {
          margin-right: 2px;
        }
      }
    }

    .more-info,
    .save-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(100, 100, 100, 0.7);
      border-radius: 32px;
      cursor: pointer;
      height: 100%;
      margin-right: 15px;
      transition: all 0.3s;
      padding: 10px 32px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }

      svg {
        width: 28px;
        height: 28px;
        margin-right: 16px;
        fill: #fff;
      }

      span {
        color: #fff;
        font-family: "MuliSemiBold";
        font-size: 20px;
        line-height: 28px;
        font-weight: 800;
        letter-spacing: 0.05em;
      }
      @media (min-width: 1281px) {
        box-sizing: border-box;
        width: auto;
        padding: 0px 30px;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          fill: #fff;
        }
      }

      @include media-breakpoint-down(xl) {
        box-sizing: border-box;
        padding: 10px 20px;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          fill: #fff;
        }
      }

      @include media-breakpoint-down(lg) {
        box-sizing: border-box;
        padding: 10px 20px;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          fill: #fff;
        }
      }

      @include media-breakpoint-down(md) {
        padding: 6px 19px;
        svg {
          width: 18px;
          height: 18px;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 30px;
        height: 30px;
        padding: 3px;
        margin-right: 7px;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 0;
        }

        span {
          display: none;
        }
      }
    }

    .save-item {
      border-radius: 50%;
      padding: 10px;
      width: 3em;
      height: 3em;
      box-sizing: border-box;
      flex: none;
      max-width: none;

      svg {
        width: 21px;
        height: 21px;
        margin-right: 0;
      }

      &.saved {
        background-color: #fff;

        svg {
          fill: $radivision-blue;
        }
      }

      @include media-breakpoint-down(md) {
        padding: 9.5px;
        width: 44px;
        height: 44px;
      }

      @include media-breakpoint-down(sm) {
        padding: 3px;
        border-width: 1px;
        width: 30px;
        height: 30px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
      @include media-breakpoint-up(xl) {
        width: 2.7em;
        height: 2.7em;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      @media (min-width: 1600px) {
        width: 3em;
        height: 3em;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &.featured {
    .volume-control {
      display: block;
    }
  }
  @include media-breakpoint-up(xl) {
    &.hero-carousel + .carousel-panel {
      margin-top: -80px;
    }
    &.hero-carousel + #filters-widget {
      margin-top: -100px;
      z-index: 2;
      & + .carousel-panel {
        margin-top: -20px;
      }
    }
    &.hero-carousel + #filters-widget ~ .the-shows {
      position: relative;
      z-index: 1;
    }
    &.hero-carousel + #filters-widget ~ #Footer {
      position: relative;
      z-index: 1;
    }
    &.live-video + .carousel-panel {
      margin-top: 30px;
    }
    .more-info {
      padding: 10px 34px !important;
      span {
        font-family: MuliExtraBold !important;
        font-size: 19px;
      }
    }
    .slide-actions {
      button {
        padding: 5px 33px 4px 33px;
        font-family: MuliExtraBold;
        font-size: 19px;
      }
    }
  }

  @media (min-width: 1600px) {
    &.hero-carousel + .carousel-panel {
      margin-top: -100px;
    }
    &.hero-carousel + #filters-widget {
      margin-top: -160px;
      z-index: 2;
      & + .carousel-panel {
        margin-top: -40px;
      }
    }
    &.live-video + .carousel-panel {
      margin-top: 30px;
    }
  }

  @include media-breakpoint-down(md) {
    height: calc((100vw / (16 / 9)));
    margin-bottom: 20px;
  }
}

.carousel {
  color: white;

  .pauseButton {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    fill: white;
    margin-right: 5px;

    @media (min-width: 2000px) {
      width: 28px;
      height: 28px;
    }
  }

  .outline {
    svg {
      width: 17px;
      height: 20px;
      vertical-align: sub;
      fill: white;
      margin-right: 10px;

      @media (min-width: 2000px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  .carousel-indicators {
    position: absolute;
    right: 170px;
    bottom: 15%;
    left: auto;
    z-index: 15;
    display: flex;
    justify-content: unset;
    padding-left: 0;

    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-down(xl) {
      bottom: 60px;
    }
    @media (min-width: 1600px) {
      bottom: 120px;
    }
    @include media-breakpoint-down(lg) {
      bottom: 15%;
    }
    @include media-breakpoint-down(md) {
      right: 120px;
      bottom: 48px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    li {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: rgba(255, 255, 255, 1);

      &.active {
        background-color: $radivision-blue;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
      bottom: 29px;
    }
  }
  .carousel-navigation {
    width: auto;
    z-index: 9;
    opacity: 0.75;

    span {
      cursor: pointer;

      &.carousel-control-prev-icon {
        background-image: none !important;
        margin-left: 32px;

        &.right {
          svg {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      &.carousel-control-next-icon {
        background-image: none !important;
        margin-right: 32px;
      }

      svg {
        fill: #fff;
        height: 50px;
        -webkit-filter: drop-shadow(0px 0px 3px rgba(20, 20, 20, 0.8));
        filter: drop-shadow(0px 0px 3px rgba(20, 20, 20, 0.8));
        @include media-breakpoint-down(sm) {
          height: 30px;
        }
      }
    }
  }

  .carousel-navigation {
    width: auto;
    z-index: 9;
    opacity: 0.75;
    bottom: 15%;

    @include media-breakpoint-down(sm) {
      bottom: 0;
    }

    span {
      width: auto;
      height: auto;
      &.carousel-control-prev-icon {
        background-image: none !important;
        margin-left: 10px;

        &.right {
          svg {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      &.carousel-control-next-icon {
        background-image: none !important;
        margin-right: 10px;
      }

      svg {
        fill: #fff;
        height: 45px;
        // -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .7));
        // filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .7));

        @include media-breakpoint-down(md) {
          height: 30px;
        }
      }
    }
  }

  .text-block {
    .info {
      left: 7%;
      position: absolute;
      bottom: 18%;
      justify-content: center;
      flex-direction: column;
      width: 44.5%;
      z-index: 10;
      display: flex;
      // height: 100%;

      &.is-playing {
      }

      @include media-breakpoint-up(xl) {
        left: 70px;
        bottom: 18%;
        width: 44%;
        justify-content: flex-end;
      }

      @media (min-width: 1600px) {
        left: 70px;
        bottom: 18%;
        width: 44%;
      }

      @include media-breakpoint-down(md) {
        left: 10%;
        width: 60%;
        height: auto;
        bottom: 30px;
      }

      @include media-breakpoint-down(sm) {
        left: 15%;
        width: 70%;
        margin-bottom: 0;
        bottom: 20px;
        padding-top: 5%;
        max-width: 60%;
      }
    }

    a {
      color: #4cb4e7;
      margin-left: 5px;
      cursor: pointer;

      &:hover {
        color: #4cb4e7;
      }
    }

    @media (max-width: 1024px) {
      padding-left: 35px;
    }

    @include media-breakpoint-down(lg) {
      width: 52%;
    }

    @include media-breakpoint-down(md) {
      width: 65%;
      padding: 0 35px;
      padding-right: 0;
    }

    @include media-breakpoint-down(xs) {
      font-size: 7px;

      p {
        margin-bottom: 0.5rem;
      }

      .hero-logo {
        display: block;
      }

      .btn-radivision {
        font-size: 12px;
      }
    }

    @media (min-width: 768px) and (max-width: 1150px) {
      line-height: 1.3;
      font-size: 11px;
      padding: 0 35px;
      padding-right: 0;

      .subtitle {
        margin-top: 15px !important;
        font-size: 1.3rem !important;
      }

      p {
        margin-bottom: 0.5rem !important;

        @include media-breakpoint-down(lg) {
          margin-bottom: 0;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      padding-left: 35px;
      padding-right: 0px;
      width: 85%;
      margin-bottom: 25px;
    }

    .description {
      @include media-breakpoint-down(sm) {
        font-size: 0.5rem;
        margin-bottom: 2px;
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .episode {
      margin-top: 20px;

      span {
        margin-right: 15px;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.9rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 0.5rem;
        margin-top: 0;
        display: none;
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }

    .list-logo {
      width: 40%;
      margin-bottom: 20px;
      transition: all 1.4s linear;
      filter: brightness(1) invert(0);

      img {
        filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.9));
        transition: filter 0s linear;
      }
      &.is-playing {
        img {
          transition: all 0s linear;
        }
      }
      &.is-normal {
        width: 40%;
        transition: all 1s linear;
        img {
          transition: all 1s linear;
          transform-origin: left bottom;
          transform: scale(1);
        }
        &.is-playing {
          width: 40%;
          transition: all 1s linear;
          img {
            filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
            transform: scale(0.8);
          }
        }
      }

      &.is-sheconomics {
        width: 100%;
        transition: all 1.4s ease-out;
        filter: brightness(1) invert(0);
        img {
          transition: all 1.4s ease-out;
          transform-origin: left bottom;
          transform: scale(2.1);
        }
        &.is-playing {
          transition: all 1s ease-out;
          filter: brightness(0) invert(1);
          img {
            filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
            transform: scale(0.5);
          }
        }
      }
      @include media-breakpoint-down(md) {
        width: 58%;
        margin-bottom: 10px;
        &.is-sheconomics {
          width: 60%;
          transition: all 1.4s ease-out;
          img {
            transition: all 1.4s ease-out;
            transform-origin: left bottom;
            transform: scale(1);
          }
          &.is-playing {
            transition: all 0s ease-out;
            filter: brightness(0) invert(1);
            img {
              filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
              transform: scale(1);
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 4px;
      }
    }

    .subtitle {
      margin: 1vw 0;
      font-size: 1.85vw;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);

      @media (min-width: 1600px) {
        font-size: 2.6vw;
        font-family: "MuliSemiBold";
        font-weight: 700;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2.4vw;
        font-family: "MuliSemiBold";
        font-weight: 700;
      }

      @media (width: 1366px) {
        font-size: 2.2vw;
      }

      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }

    .title {
      margin-bottom: 0;
      font-size: 50px;
      font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }

    .text-content {
      // margin-top: 40px;
      margin-bottom: 0;
      transition: all 2s cubic-bezier(0, 1, 0, 1);
      overflow: hidden;
      opacity: 1;
      max-height: 200px;

      &.is-playing {
        max-height: 0px;
        opacity: 0;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }

      span {
        line-height: normal;
        font-family: "Muli";
        font-size: 22px;
        color: rgba(255, 255, 255, 1);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

        br {
          display: none;
        }

        @include media-breakpoint-up(xl) {
          font-size: 1.1em;
        }

        @media (min-width: 1600px) {
          font-size: 1.3em;
        }

        @include media-breakpoint-down(sm) {
          font-size: 3.3vw;
        }
        @include media-breakpoint-down(lg) {
          font-size: 1.8vw;
        }
      }
    }
  }

  .carousel-inner.featured-hero {
    .text-block {
      .info {
        @include media-breakpoint-down(sm) {
          left: 35px;
        }
      }
    }
  }

  .btn-radivision {
    font-family: "MuliExtraBold";
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      padding: 10px 26px;
      font-size: 16px;

      svg {
        height: 16px;
        margin-right: 12px;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 8px 18px;
      font-size: 16px;

      svg {
        height: 10px;
        margin-right: 8px;
      }
    }
  }

  .carousel-item {
    margin-right: 0;
    height: 100%;
    animation-name: cf3FadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    .cover-img {
      // position: unset;

      .vid-bg {
        width: 100%;
        height: 100%;
        max-width: 100%;
        display: block;
        position: absolute;

        // @media (min-width: 1024px) {
        //   max-height: 91vh;
        // }

        .exit-fs {
          @media (max-width: 1024px) {
            position: absolute;
            z-index: 5;
            top: 45px;
            right: 20px;
          }
        }

        iframe {
          object-fit: cover;
          width: 100%;
          height: 100%;
          max-width: 100%;
          border: 0;
        }
      }
    }

    .hero-image {
      margin: 0 0 0 auto;
      width: 100%;
      opacity: 1;
      -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      filter: alpha(opacity=100);
      -webkit-transition: opacity 400ms cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      -o-transition: opacity 400ms cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      -moz-transition: opacity 400ms cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      transition: opacity 400ms cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
    }

    .item-gradient {
      background: linear-gradient(to right, rgba(50, 50, 59, 1) 96%, rgba(50, 50, 59, 0) 100%);
      position: absolute;
      top: 0;

      @include media-breakpoint-down(md) {
        background: linear-gradient(to bottom, rgba(50, 50, 59, 0.1) 0%, rgba(50, 50, 59, 1) 100%);
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        background: linear-gradient(to bottom, rgba(50, 50, 59, 0.1) 0%, rgba(50, 50, 59, 1) 75%);
      }

      @include media-breakpoint-down(xs) {
        background: linear-gradient(to bottom, rgba(50, 50, 59, 0.1) 0%, rgba(50, 50, 59, 1) 70%);
      }
    }

    &.active {
      opacity: 1;

      .show {
        background: (0, 0, 0 0.7);
      }

      .cover-img {
        video {
          display: block !important;
        }
      }

      @include media-breakpoint-down(md) {
        .video-slide {
          height: 100%;
        }
      }

      @media (max-width: 1024px) {
        .video-slide {
          height: 100%;
        }
      }
    }

    &.video-active .slide-detail {
      display: block;
    }

    &.video-active .hero-image {
      display: none;
      opacity: 0;
      -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      filter: alpha(opacity=0);
    }

    &.video-active {
      .video-js {
        width: 100%;
        height: 100%;
        opacity: 1;
        display: block;

        // @media (min-width:1024px) {
        //   height: 91vh;
        // }

        video {
          display: block;

          // @media (min-width:1024px) {
          //   height: 91vh;
          // }
        }
      }
    }

    .image-slide {
      img {
        display: block;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .image-slide {
    .video-logo {
      width: 100%;
      height: 100%;
    }
  }

  .video-slide {
    .about-video {
      border-radius: 50%;
      position: absolute;
      left: 50%;
      margin: 0 auto;
      padding: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 0px solid #fff;
      background: none;

      svg {
        width: 85px;
        height: 85px;
        vertical-align: middle;
        text-align: center;

        @include media-breakpoint-down(sm) {
          width: 65px;
          height: 65px;
        }
      }
    }

    // height: 100%;
    &.container {
      margin-left: auto;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .overlay {
      cursor: pointer;
    }

    video {
      // display: none;
      margin: 0 0 0 auto;
      position: relative;
      width: 100%;
    }

    .ui-video-player-component {
      .controllers-block {
        position: absolute;
        bottom: 15px;
      }
    }
  }

  .btn-radivision {
    &.multiple {
      min-width: 178px;

      @include media-breakpoint-down(md) {
        min-width: 120px;
      }

      @include media-breakpoint-down(sm) {
        min-width: auto;
      }

      @include media-breakpoint-down(sm) {
        min-width: 72px;
      }
    }
  }
}

@keyframes crossfade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cover-margin {
  padding-bottom: 100px;
}

p.text-justify {
  margin-bottom: 0;
}

.video-active video {
  display: block;
  position: unset !important;
}

.carousel-item {
  .zoomInOut {
    .list-logo {
      transform-origin: left bottom;
      transform: scale(1) translate3d(0px, 145px, 0px);
      transition-duration: 1300ms;
      transition-delay: 2000ms;

      @media (min-width: 3000px) {
        transform: scale(1) translate3d(0px, 245px, 0px);
      }

      @media (min-width: 2500px) and (max-width: 2999px) {
        transform: scale(1) translate3d(0px, 205px, 0px);
      }

      @media (min-width: 2000px) and (max-width: 2499px) {
        transform: scale(1) translate3d(0px, 175px, 0px);
      }

      @media (min-width: 1200px) and (max-width: 1680px) {
        transform: scale(1) translate3d(0px, 115px, 0px);
      }

      @include media-breakpoint-down(lg) {
        transform: scale(1) translate3d(0px, 105px, 0px);
        width: 62% !important;
      }

      @include media-breakpoint-down(md) {
        transform: scale(1) translate3d(0px, 90px, 0px);
      }

      @include media-breakpoint-down(sm) {
        width: 70% !important;
        transform: scale(1) translate3d(0px, 40px, 0px);
      }
    }

    .text-content,
    .subtitle {
      transform: scale(0) translate3d(0px, 165px, 0px);
      transition-delay: 2000ms;
      opacity: 0;
    }

    h6 {
      margin-bottom: 0 !important;
    }
  }

  .zoomOutIn {
    .list-logo {
      transform: scale(1) translate3d(0px, 0px, 0px);
    }

    .text-content,
    .subtitle {
      transform: scale(1) translate3d(0px, 0px, 0px);
      opacity: 1;
    }
  }
}

.addMask {
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}

.radivisionIntro {
  background: rgba(25, 25, 30, 0.8);
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .exit-fs {
    svg {
      width: 20px;
      position: absolute;
      top: 100px;
      right: 30px;
      fill: white;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    text-align: center;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    h1 {
      text-transform: uppercase;
      font-family: "MuliBold";
      letter-spacing: 0.075rem;

      @media (min-width: 2000px) {
        font-size: 3.75rem;
      }

      @media (min-width: 1024px) and (max-width: 1170px) {
        font-size: 2rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
      }

      @include media-breakpoint-down(xs) {
        font-size: 1rem;
      }
    }

    p {
      text-align: center;
      text-align: center;
      margin: 25px auto;
      font-size: 22px;
      font-family: "Muli";

      @media (min-width: 2000px) {
        font-size: 33px;
      }

      @media (min-width: 1024px) and (max-width: 1170px) {
        font-size: 18px;
      }

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 11px;
        margin: 10px auto;
      }
    }

    span {
      color: #4cb4e7;
      font-size: 24px;
      font-family: "MuliBold";
      text-decoration: none;
      cursor: pointer;

      @media (min-width: 2000px) {
        font-size: 36px;
      }

      @media (min-width: 1024px) and (max-width: 1170px) {
        font-size: 20px;
      }

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
  }
}

.video-panel.full-screen {
  div.youtube-video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    z-index: 99;
    left: 0;
    background: $dark-mode-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      width: 100%;
      position: relative;
      height: 100vh;

      & > div {
        width: 100%;
        height: 100vh;
      }

      @include media-breakpoint-down(md) {
        height: calc(100vw / (16 / 9));
        overflow: hidden;
      }
    }

    iframe {
      width: 100vw;
      height: 100%;
      border: 0;

      @include media-breakpoint-down(md) {
        position: relative;
        display: block;
        width: 100vw;
        height: calc(100vw / (16 / 9)) !important;
        overflow: hidden;
      }
    }
  }
  .carousel-item {
    &.active {
      .text-block {
        .info {
        }
      }
    }
  }
}
