/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-08-27
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.TechnicalIssues {
  // padding: 0% 18% 3% 18%;
  width: 70%;
  margin: 0 auto;

  @media (max-width:1280px) {
    width: 85%;
  }

  @media (max-width:1024px) {
    width: 100%;
    padding: 35px;

  }

  @media (min-width:2000px) {
    width: 62%
  }

  @include media-breakpoint-down(md) {
    padding: 35px;
    width: 100%;
  }

  .title {

    &::after {
      display: block;
      content: "";
      height: 0.2rem;
      background-color: #edeaea;
      margin: 3% 0%;
    }

    h3 {
      font-size: 2rem;
      padding-top: 5rem;

      @media (min-width:1024px) and (max-width:1280px) {
        font-size: 24px;
      }

      @include media-breakpoint-down(md) {
        font-size: 1.6rem;
        padding-top: 3rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
        padding-top: 1rem;
      }
    }

    h5 {
      @media (min-width:1024px) and (max-width:1280px) {
        font-size: 12pt;
      }

      @include media-breakpoint-down(md) {
        font-size: 12pt;
      }

      @include media-breakpoint-down(sm) {
        font-size: 10pt;
      }
    }
  }

  .question-and-answer {
    div {
      margin-top: 4%;

      h3 {
        font-size: 15pt;

        @include media-breakpoint-down(md) {
          font-size: 12pt;
        }

        @include media-breakpoint-down(sm) {
          font-size: 10pt;
        }
      }

      ol {
        li {
          margin-bottom: 0.7rem;
          font-weight: bold;

          @include media-breakpoint-down(md) {
            font-size: 11pt;
          }

          @include media-breakpoint-down(sm) {
            font-size: 9pt;
          }

          span {
            font-weight: normal;
          }
        }
      }
    }
  }

  h2 {
    font-size: 13pt;

    @include media-breakpoint-down(sm) {
      font-size: 10pt;
    }
  }

  p {
    margin-left: 42px;

    @include media-breakpoint-down(md) {
      margin-left: 0px;
      font-size: 10pt;
    }

    @include media-breakpoint-down(sm) {
      font-size: 8pt;
    }
  }
}