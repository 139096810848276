.panel-nav {
  .search-form {
    right: 0;
    width: 100%;
    top: 0;
    z-index: 1;

    .gray-div {
      height: 43px;
      background-color: transparent;
      transition: background-color linear 0.4s;

      @media (min-width: 2500px) {
        height: 74px;
      }
    }

    &.search-form-shown {
      .gray-div {
        background-color: $radivision-dk-gray;
      }
    }
  }
}

.search-form {
  position: absolute;
  z-index: -1;
  max-height: 0;
  width: calc(100% - 61px);
  right: 60px;
  top: 100%;
  overflow: hidden;
  background-color: $dark-mode-black;
  transition: max-height linear 0.6s;

  &.search-form-shown {
    max-height: 600px;

    //to hide the placeholder text on input focus
    input:focus::-webkit-input-placeholder {
      color: transparent;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      left: 0;
      overflow: auto;
      top: 59px;
    }
  }

  .search-input-container {
    background-color: #2e2e2e;
    width: 100%;
    height: 97px;
    padding-right: 100px;
    padding-left: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    input {
      border: 1px solid #2e2e2e;
      background-color: #2e2e2e;
      flex: 1;
      height: 100%;
      border: none;
      font-family: "MuliBold";
      font-size: 36px;
      color: #fff;
      padding: 22px 0;
      margin-right: 26px;
      text-align: center;

      &::placeholder {
        color: $dark-input-placeholder;
        text-transform: capitalize;
        text-align: center;
      }

      @media (min-width: 2500px) {
        font-size: 30px !important;
        height: 125px;
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 80%;
        width: 80%;
        font-size: 26px;
      }

      @media (max-width: 360px) {
        font-size: 20px;
      }
    }

    .dispose-search {
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        flex: 0 0 10%;
        margin-left: 0px;
      }

      svg {
        width: 34px;
        height: auto;
        fill: #fff;
      }
    }

    @include media-breakpoint-down (sm) {
      padding: 0 10px;

      input {
        margin: 0;
      }
    }
  }

  .results-container {
    // margin-top: 20px;
    width: 100%;

    p {
      margin-top: 20px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }

    .results-count {
      color: $gray;
    }

    @media (min-width: 2500px) {
      p {
        font-size: 1.8rem;
      }
    }
  }

  .suggestions {

    //targeted css for firefox to make column-count work
    @-moz-document url-prefix() {
      .list-group {
        display: block;
      }
    }

    .list-group {
      display: block !important;
    }

    .list-group-item {
      border: none;
      color: #fff;
      cursor: pointer;
      margin: 20px auto;
      padding: 0;
      background-color: transparent;
      text-align: center;

      & > span {
        display: block;
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      a {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: "MuliLight";
        font-size: 22px;
        color: #ececec;

        @media (min-width: 2500px) {
          font-size: 36px;
        }
      }

      &:hover {
        color: $radivision-blue;
      }

      .filter-source {
        font-weight: 500;
        display: inline-block;
        vertical-align: middle;
      }

      &.all-results {
        a {
          font-family: "MuliBold";
          color: $radivision-blue;
        }
      }
    }
  }

  hr {
    width: 100%;
    float: left;
  }

  .btn-radivision {
    font-size: 10pt;
    clear: both;
    display: block;

    @include media-breakpoint-down(sm) {
      padding: 14px 18px;
      height: auto;
    }
  }
}

.search-results-component {
  min-height: 400px;

  h6.title {
    font-size: 10pt;

    @media (min-width: 2500px) {
      font-size: 20pt;
    }
  }

  padding: 0 60px 0 70px;

  @include media-breakpoint-down(md) {
    padding: 0 35px;
  }

  @media (width: 1024px) {
    padding: 0 35px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 35px;
  }

  @media (min-width: 768px) and (max-width:998px) {
    padding: 0 35px;
  }

  .row:first-child {
    margin: 0;

    .filter-div {
      padding-left: 0;

      .list-group-item {
        padding-left: 0;

        @media (min-width: 2500px) {
          font-size: 18px;
        }
      }

      .filters-container {
        padding-left: 1.2rem;

        @media (width: 1367px) {
          padding-left: 0;
        }

        .list-group-item {
          padding-left: 1.2rem;
        }
      }
    }
  }

  .suggestions-section {
    padding: 20px 35px;
    color: $radivision-dk-gray;

    span {
      font-weight: 200;

      .suggestion {
        font-weight: 600;
        cursor: pointer;
        padding: 5px 10px;

        &.selected {
          color: $radivision-blue;
          text-decoration: underline;
        }

        &:hover {
          color: $radivision-blue;
        }
      }
    }
  }

  .search-phrase-section {
    padding: 20px 0;

    p {
      font-weight: 700;
      border-bottom: 1px solid $radivision-lt-gray;
      padding-bottom: 20px;
      margin: 20px 0;
      font-size: 18px;

      @media (min-width: 2500px) {
        font-size: 32px;
      }

      span {
        font-weight: 300;
      }
    }
  }

  .sort-by {
    padding: 0;
    margin-bottom: 40px;

    @media (min-width: 2500px) {
      font-size: 28px;
    }

    @include media-breakpoint-down(lg) {
      margin: 0 20px 20px 0px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0px;
      padding-right: 0;
    }

    .result {
      font-size: 18px;

      @media (min-width: 2500px) {
        font-size: 28px;
      }

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .sort-type {
      font-weight: 700;
      padding: 0 10px;
      cursor: pointer;
      font-size: 12px;

      @media (min-width: 2500px) {
        font-size: 28px;
      }

      &.active,
      &:hover {
        color: $radivision-blue;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }
    }
  }

  .search-results-grid {
    .linked-preview {
      height: 250px !important;

      @include media-breakpoint-down(xs) {
        height: 200px !important;
      }
    }

    .wide-carousel .slick-slider {
      padding-left: 20px;
      padding-right: 0px;

      @include media-breakpoint-down(md) {
        padding-left: 0px;
        padding-right: 0;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 0px;
        padding-right: 0;
      }

      .slick-slide {
        max-width: 25%;

        .episode-details {
          max-height: 170px;
          overflow: hidden;
          height: auto;

          .episode-title {
            font-size: 12px;
            text-transform: capitalize !important;
          }

          .episode-subtitle {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .load-more {
    text-align: center;
    margin-bottom: 30px;

    hr {
      width: 35%;
      display: inline-block;
      vertical-align: middle;

      @include media-breakpoint-down(md) {
        width: 30%;
      }

      @include media-breakpoint-down(sm) {
        width: 15%;
      }
    }

    button {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .no-results {
    text-align: center;
    font-family: "Muli";
    font-size: 46px;
    font-weight: bold;
    color: #464646;
  }

  .filters {
    .filters-container {
      @include media-breakpoint-only(lg) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
      }
    }

    .list-group-item {
      border: none;
      padding: 0.5rem 1.2rem;
      cursor: pointer;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.075rem;
      font-size: 14px;
      color: $dark-gray;
      color: $dark-gray;

      @include media-breakpoint-down(lg) {
        padding: 0.5rem 0.6rem;
      }

      &.active {
        color: $radivision-blue;
        background-color: transparent;
        position: relative;

        &:before {
          display: inline-block;
          content: "";
          background: url("../static/tick.png") no-repeat center center;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -10px;
        }
      }
    }
  }
}

.search-form {
  &.search-form-shown~div {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #000000a8;
    z-index: 90;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.btn {
  &.search-btn {
    &.btn-close {
      z-index: 1050;
    }
  }
}

.list-group {
  flex-direction: column;
  margin: 0;
  align-items: center;

  &.filters {
    border-left: 0px solid #f2f4f4;
    padding-left: 0px;

    .list-group-item {
      font-size: 12px;
      cursor: default;
      padding-right: 0px;
    }

    .filters-container {
      .list-group-item {
        font-size: 10px;
        cursor: pointer;

        .count {
          float: right;

          @media (max-width: 1280px) and (min-width: 1200px) {
            display: none;
          }
        }
      }
    }
  }
}

.no-hover {
  .wide-carousel {
    .slick-slider {
      .slick-slide {
        .carousel-slide {
          &:hover {
            transform: scale(1);
          }
        }

        &:hover {
          &~.slick-slide {
            transform: translate3d(0, 0, 0);
          }
        }
      }

      .slick-list {
        &.list-hover {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.other-icons {
  svg {
    width: 12px;
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    margin-right: 2px;
  }
}

.search-results-grid {
  position: relative;
  padding: 0;
  padding-left: 35px;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0px;
    padding-right: 0;
  }

  .slick-slider {
    padding-right: 0;
  }
}

.search-loader {
  position: absolute;
  top: 35%;
  left: 50%;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.searchResults {
  background: #161616;

  .filterUx-div {
    width: 100%;
    padding: 55px 110px;
    background-color: rgba(31, 31, 31, 1);

    @media (max-width: 1024px) {
      padding: 35px;
    }

    .nav.panel-nav {
      margin-top: 55px;

      @include media-breakpoint-down(sm) {
        margin-top: 36px;
      }
    }

    .intro {
      width: 100%;

      .title {
        font-family: 'Muli';
        font-size: 46px;
        font-weight: bold;
        color: rgba(70, 70, 70, 1);
        text-transform: capitalize !important;
        
        @include media-breakpoint-down (sm) {
          font-size: 26px;
        }
      }

      .subtitle {
        color: white;
      }
    }

    .filters {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        display: flex !important;
        align-items: center;
        flex-wrap: wrap;
        overflow: unset !important;
        white-space: unset !important;
      }

      .filters-container {
        width: 100%;
        display: inline-block;

        li {
          color: white;
          padding: 8.5px 30px;
          text-align: left;
          display: inline-block;
          position: relative;
          margin-left: 15px;
          cursor: pointer;
          border-radius: 19px;
          font-family: 'Muli';
          font-size: 14px;
          font-weight: 600;
          color: rgba(254, 254, 254, 1);
          letter-spacing: 0.03em;
          background-color: rgba(70, 70, 70, 1);
          text-transform: capitalize;


          &:first-child {
            margin-left: 0;
          }

          .count {
            height: 26px;
            width: 26px;
            background-color: #4cb4e7;
            border-radius: 26px;
            position: absolute;
            top: -10px;
            right: 5px;
            color: white;
            text-align: center;
            margin: 0 auto;
            font-family: "Muli";
            font-size: 12px;
            font-weight: bold;
            justify-content: center;
            align-items: center;
            display: flex;
            overflow: hidden;
          }

          &.active {
            background-color: rgba(255, 255, 255, 1);
            color: black
          }

          @media (max-width: 1024px) {
            padding: 1rem 0.05rem;
            font-size: 12px;
          }

          @include media-breakpoint-down(md) {
            padding: 1rem 0.05rem;
            font-size: 12px;
            min-width: 40px;
            padding: 15px 22px;
            text-align: center;
            width: auto;
            margin-bottom: 22px;
            margin-right: 15px;
            margin-left: 0;
            border-radius: 24px;
          }
        }

        .sort {
          float: right;

          @include media-breakpoint-down(sm) {
            display: none;
          }

          .dropdown {
            .btn {
              padding: 7px 30px;
              color: $snow-white;
              border-radius: 19px;
              background: #464646;
              margin: 0;
              text-transform: capitalize;
              border: 1px solid #464646;
              font-family: 'Muli';
              font-size: 13px;
              font-weight: bold;

              &::after {
                display: none;
              }

              // &:hover,
              // &:active,
              // &:focus {
              //   background: #32323b;
              //   color: white;
              // }

              svg {
                width: 15px;
                min-width: 15px;
                margin-top: 6px;
                vertical-align: middle;
                margin-left: 10px;
                transform: rotate(90deg);

                .st0 {
                  fill: white;

                }
              }
            }

            .dropdown-menu {
              min-width: 100%;
              padding: 0;
              margin: 0;
              color: white;
              background: #464646;
              margin-top: 3px;

              .dropdown-item {
                padding: 0.5rem 1rem;
                color: white;

                &:hover,
                &:active,
                &:focus {
                  color: white;
                  background: #4cb4e7;
                }
              }

            }
          }
        }
      }
    }
  }

  .search-results-componentUx {
    padding: 40px 110px;
    min-height: 550px;
    margin-bottom: 0;
    padding-right: 100px;

    @media (max-width: 1024px) {
      padding: 35px;
      margin-bottom: 0;
      padding-right: 25px;
    }

    p {
      span {
        font-family: "MuliBold";
        font-size: 20px;
      }
    }

    .grid-view {
      margin: 0;
      padding: 0;

      .searchUx {
        flex: 0 0 calc(100% / 5);
        max-width: calc(100% / 5);

        @media (max-width: 1024px) {
          flex: 0 0 calc(100% / 4);
          max-width: calc(100% / 4);
        }

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .grid-item {
          overflow: hidden;
          margin-bottom: 50px;

          img {
            background-color: rgba(70, 70, 70, 1);
            object-fit: cover;

          }

          .has-icon {
            flex-direction: column;

            svg {
              margin-top: 15px;
            }
          }

          .story-icon:not(:empty) {
            &~* {
              margin-bottom: 0;
            }
          }

          .youtubeOverlay {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1050;
            left: 0;
            top: 0;
          }

          .youtube-on-hover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    .no-results {
      font-family: "Muli";
      font-size: 46px;
      font-weight: bold;
      color: #464646;
      text-align: center;

      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }

      svg {
        width: 45px;
        margin-bottom: 10px;
      }
    }
  }
}
