.sponsor-panel {
  margin-top: 80px;
  margin-bottom: 120px;
  padding: 0 60px;

  @include media-breakpoint-down(md) {
    padding: 0 27px;
    margin-bottom: 80px;
  }

  .sponsor-panel-container {
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 40px;

      @include media-breakpoint-down(md) {
        padding: 0px;
      }

      a {
        display: flex;
        width: 100%;
        max-width: 120px;
        height: auto;

        @include media-breakpoint-down(md) {
          max-width: 100px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}