.grid-view {
  margin: 0;
  margin-right: -39px;
  color: #fff;

  @include media-breakpoint-down(sm) {
    margin-right: -18px;
  }

  & > div {
    padding: 0;
    padding-right: 39px;
    margin-bottom: 46px;

    @include media-breakpoint-down(sm) {
      padding-right: 18px;
    }
  }

  &.full-row {
    margin-right: 0;

    & > div {
      padding: 0;
    }
  }

  .optional-div {
    .plusIconDiv {
      position: relative;
      padding-top: 100%;
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      background-color: rgba(59, 59, 59, 1);
      border-radius: 10px;
      cursor: pointer;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        svg {
          fill: rgba(255, 255, 255, 0.6);
          width: 40px;
          height: 40px;
          padding: 6px;
          border: 3px solid rgba(255, 255, 255, 0.6);
          border-radius: 50%;

          @media (min-width: 2000px) {
            width: 56px;
            height: 56px;
          }
        }

        span {
          font-family: "MuliBold";
          font-size: 14px;
          margin-top: 18px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

  .view-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
      padding-top: 100%;
    }

    & > div.view-more {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding-top: 36px;

      i {
        width: 50px;
        height: 50px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        border-radius: 50%;

        svg {
          width: auto;
          fill: $dark-mode-bg !important;
          stroke: $dark-mode-bg;
          stroke-width: 7px;
          margin: unset !important;

          &:first-child {
            transform: translateX(43%);
          }

          &:last-child {
            transform: translateX(-27%);
          }
        }
      }

      span {
        font-size: 16px;
        text-transform: capitalize;
        font-family: "MuliBold";
        color: #fff;
        text-decoration: none;
        transition: all 0.3s;
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }

  .item-card {
    padding: 0;
    transition: 0.3s ease;
    transition-delay: 0s;
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
    position: relative;

    .overlay {
      display: none;
    }

    .card-image {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .img-placeholder {
        position: absolute;
        top: 0;
        display: block;
        background-color: $dark-empty-placeholder;
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
    }

    img {
      width: 100%;
      height: auto;
      border: none;
      position: absolute;
      top: 0;
      border-radius: 10px;
    }

    .card-image.card-image-circle {
      border-radius: 50%;

      img {
        border-radius: 50%;
        height: 100%;
        object-fit: fill;
      }
    }

    .grid-item-actions {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      padding-top: 20px;
      z-index: 2;

      button {
        background-color: #fff;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        transition: all 0.3s;
        border-radius: 50%;
        opacity: 0.8;

        svg {
          fill: $dark-mode-bg;
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: $radivision-blue;

          svg {
            fill: #fff;
          }
        }
      }

      & > div {
        display: block;
        opacity: 0;
        margin-left: 14px;
        margin-bottom: 12px;
        width: 36px;
        height: 36px;

        &.swap-item {
          span {
            margin-bottom: 4px;
          }
        }

        &.delete-item {
          span {
            margin-left: 2px;
            margin-bottom: 3px;
          }

          button:hover {
            svg {
              fill: $danger;
            }
          }
        }
      }

      &.d-block {
        & > div {
          opacity: 1;
          margin-left: 0 !important;
          box-shadow: 0 0 17px #000;
          border-radius: 50%;
        }
      }
    }

    &:hover {
      .grid-item-actions {
        display: block;

        & > div {
          animation-name: gridItemActionsSlideIn;
          animation-timing-function: ease-out;
          animation-duration: 0.2s;
          animation-fill-mode: forwards;

          &:first-child {
            animation-delay: 0s;
          }

          &:nth-child(2) {
            animation-delay: 0.1s;
          }

          &:nth-child(3) {
            animation-delay: 0.2s;
          }
        }
      }
    }

    @keyframes gridItemActionsSlideIn {
      from {
        opacity: 0;
        margin-left: 14px;
      }
      to {
        opacity: 1;
        margin-left: 0;
      }
    }

    .item-meta {
      display: flex;
      flex-direction: row;
      align-items: center;

      .publisher {
        font-family: "MuliBold";
        flex: 1;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        margin-left: 6px;
      }

      .publish-date {
        font-size: 11px;

        @media (min-width: 2000px) {
          font-size: 16px;
          line-height: 26px;
        }
      }

      .duration {
        font-family: "MuliBold";
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .show {
      display: block;
    }

    .hide {
      display: none;
    }

    .grid-details {
      .story-icon {
        margin: 0px 10px 0px 0px;
        display: inline-block;
        svg {
          width: auto;
          height: 21px;
          max-width: 18px;
          fill: rgba(255, 255, 255, 0.5);
          stroke: rgba(255, 255, 255, 0.5);
          stroke-width: 1px;
          vertical-align: text-bottom;
          &.articleVideo {
            width: 16px;
          }
        }
      }

      h6.card-title {
        font-family: "MuliBold";
        font-size: 14px;
        line-height: 1.4;
        color: #fff;
        margin-bottom: 5px;
        height: 70px;
        overflow: hidden;

        @media (min-width: 1200px) {
          font-size: 16px;
        }

        &.has-icon {
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          span {
            flex: 1;
            margin-right: 8px;
          }
        }

        .title-placeholder {
          display: block;
          background-color: $dark-empty-placeholder;
          width: 70%;
          height: 16px;
        }
        &::after {
          display: block;
          content: "";
          position: absolute;
          background-image: linear-gradient(180deg, rgba(22, 22, 22, 0), $dark-mode-bg);
          height: 22px;
          bottom: 0;
          width: 100%;
          right: 0;
        }
      }

      .card-description {
        font-family: "Muli";
        font-size: 16px;

        &.truncate {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .description {
        margin-bottom: -2px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .duration {
        font-family: "MuliBold";
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
      }

      .location {
        font-family: "MuliBold";
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .linked-preview {
      position: relative;

      img {
        &.square-img {
          width: 100%;
          height: auto;
          border: none;
        }
      }
    }

    .card-image {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 17px;

      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 100%;
      }

      &.card-carousel {
        margin-bottom: 13px;
        border-radius: 16px;

        &::before {
          padding-top: calc(100% / (16 / 9));
        }
      }
      &.card-portrait {
        margin-bottom: 13px;
        border-radius: 16px;

        &::before {
          padding-top: calc(100% / (320 / 457));
        }
      }
    }

    img {
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      margin: 0;

      @include media-breakpoint-up(sm) {
        width: auto;
        height: auto;
      }

      @include media-breakpoint-up(md) {
        width: 100%;
        height: auto;
      }
    }

    .four-by-one-grid-height {
      height: 268px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        height: 130px;
      }
    }

    .MySavedItems-grid-height {
      overflow: hidden;
    }

    .four-by-one-grid-details {
      .card-title {
        @include media-breakpoint-down(sm) {
          font-size: 8px;
        }
      }
    }

    &:hover {
      .delete-item,
      .edit-item,
      .swap-item {
        display: block;
      }

      .show {
        display: block;
      }

      .hide {
        display: none;
      }

      ///////////////////

      .margin-top {
        margin-top: 3px;
      }

      .mySavedItes-card-height {
        height: 65px;
      }

      .description {
        margin-bottom: -2px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .ready-for-swap {
        .swap-item {
          svg {
            fill: #4cb4e7 !important;
          }
        }

        .grid-item {
          border: 4px solid #4cb4e7;
        }

        .delete-item,
        .edit-item,
        .swap-item {
          display: block;
        }
      }

      .grid-description {
        font-weight: 600;
        margin-bottom: 40px;

        @media (min-width: 2000px) {
          font-size: 20px;
        }

        @include media-breakpoint-up(md) {
          max-width: 46%;
        }

        @include media-breakpoint-down(md) {
          max-width: 60%;
        }
      }
    }

    .fadeIn {
      animation-name: fadeIn;
      -webkit-animation-name: fadeIn;
      animation-duration: 0.8s;
      -webkit-animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
      -webkit-animation-timing-function: ease-in-out;
      visibility: visible !important;
    }

    @keyframes fadeIn {
      0% {
        transform: scale(0);
        opacity: 0;
      }

      80% {
        transform: scale(0.9);
        opacity: 1;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    @-webkit-keyframes fadeIn {
      0% {
        -webkit-transform: scale(0);
        opacity: 0;
      }

      60% {
        -webkit-transform: scale(1.1);
      }

      80% {
        -webkit-transform: scale(0.9);
        opacity: 1;
      }

      100% {
        -webkit-transform: scale(1);
        opacity: 1;
      }
    }
  }

  &.full-row {
    .grid-item {
      margin-bottom: 64px;
      border-radius: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 39px;

      @include media-breakpoint-down(sm) {
        padding: 0;
        margin-bottom: 36px;
      }

      .card-image {
        width: 130px;
        height: 130px;
        margin-right: 37px;
        flex: 0 0 130px;

        @include media-breakpoint-down(sm) {
          margin-right: 24px;
        }
      }

      .grid-details {
        flex: 1;

        .item-details {
          .card-title {
            font-family: "MuliBold";
            font-size: 18px;
            margin-bottom: 13px;
          }
          .card-description {
            font-family: "Muli";
            font-size: 16px;

            &.truncate {
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.companies-entities-section {
  .grid-view {
    .grid-item {
      cursor: pointer;
      border: none;
      margin-top: 30px;

      h6 {
        &.card-title {
          padding-left: 0px;
          transition: all 0.3s;
        }
      }

      &:hover {
        border: none;

        &:after {
          display: none;
        }

        // h6 {
        //   &.card-title {
        //     padding-left: 10px;
        //   }
        // }
      }
    }
  }
}

#dnaTab {
  .grid-view .grid-item .grid-details h6 {
    margin-top: 15px;
    margin-bottom: 0.5rem;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      margin-bottom: 1.2rem;
    }
  }

  .grid-item-actions {
    &.d-block {
      @include media-breakpoint-down(sm) {
        height: 100%;
        padding-top: 0;
        display: flex !important;
        align-items: center;
        padding-bottom: 17px;

        & > div {
          width: 28px;
          height: 28px;
          margin: 5px 0;

          button {
            width: 28px;
            height: 28px;
            padding: 6px;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

.tech-news {
  margin-bottom: 25px;

  .left-side {
    padding-right: 0;
  }

  .right-side {
    padding-left: 5px;
  }

  .news-heading {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.075rem;
    text-align: center;
    margin-bottom: 0;
    padding: 15px 0;
    font-family: "MuliBold";

    @media (min-width: 2000px) {
      font-size: 26px;
    }

    @media (width: 1536px) {
      font-size: 16px;
    }

    @media (width: 1440px) {
      font-size: 15px;
    }

    @media (max-width: 1280px) and (min-width: 768px) {
      font-size: 17px;
    }

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }
  }

  .news-content {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px 0;
    text-align: center;
    height: 240px;
    overflow-y: auto;
    color: #dee2e6;
    font-size: 16px;
    // letter-spacing: -.5px;
    font-family: "MuliSemiBold";

    @media (min-width: 2000px) {
      height: 250px;
      font-size: 20px;
    }

    @media (width: 1536px) {
      font-size: 14px;
    }

    @media (width: 1440px) {
      font-size: 13px;
    }

    @media (max-width: 1280px) and (min-width: 768px) {
      font-size: 12px;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 12px;
    }

    ul {
      margin-bottom: 0;
      text-align: left;

      li {
        a {
          color: #fff;
          text-decoration: none;
        }

        color: #fff;
        list-style-type: decimal;
        font-size: 16px;
        font-family: "MuliSemiBold";
        // letter-spacing: -.5px;

        @media (min-width: 2000px) {
          font-size: 20px;
        }

        @media (width: 1536px) {
          font-size: 14px;
        }

        @media (width: 1440px) {
          font-size: 13px;
        }

        @media (max-width: 1280px) and (min-width: 768px) {
          font-size: 12px;
        }

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 12px;
        }
      }
    }

    ul:empty {
      text-align: center;

      &::before {
        content: "No Venture News";
      }
    }
  }

  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 0.1rem;
    margin: 25px auto;
  }
}

.overlay-item-meta {
  position: absolute;
  bottom: 5px;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  z-index: 1;
  padding: 0px 10px;
  .story-icon,
  .duration {
    width: 22px;
    height: 22px;
    svg {
      vertical-align: unset;
      fill: #ffffff;
      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
    }
  }
  .story-icon {
  }
  .duration {
    height: 16px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0px 5px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 11px;
    text-align: center;
  }
  &.ArticleStory {
    .story-icon {
      svg {
        max-height: 22px;
      }
    }
  }
}
