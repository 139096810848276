.item-panel {
  .episode-guests {
    width: 100%;
    display: flex;
    align-items: center;

    .episode-guest-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      max-width: calc(50% - 35px);

      .guest-avatars {
        position: relative;
        width: 156px;
        height: 156px;
        margin-right: 24px;

        a {
          display: block;
          border-radius: 50%;
          overflow: hidden;
          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);

          &.cast-person {
            width: 156px;
            height: 156px;
          }

          &.cast-company {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 48px;
            height: 48px;
            border: 2px solid $dark-mode-bg;
            box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }

      .guest-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-right: 35px;

        h3 {
          font-size: 18px;
          font-family: "MuliBold";
          margin-bottom: 4px;
        }

        h4 {
          font-size: 16px;
          margin-bottom: 4px;
        }

        a {
          font-size: 12px;
          margin-bottom: 0.5rem;
  
          @media (min-width: 2500px) {
            font-size: 24px;
          }
        }

        span {
          margin: 0;
          color: $dark-empty-placeholder;
        }
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;

      .episode-guest-item {
        max-width: 100%;
        margin-bottom: 28px;

        .guest-details {
          margin-right: 0px;
        }
      }
    }
  }
}
