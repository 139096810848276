.search-filters {
  .tags {
    padding: 0;
    margin: 5px 0;
    background: #fff;

    .tag,
    .clear {
      margin: 10px;
      margin-left: 0;
    }
  }

  .title {
    margin-left: 0px;
    font-weight: 800;
  }

  .close-svg {
    fill: white;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -5px;
    cursor: pointer;
  }

  .dropdown {
    margin: 0 0px 10px;
    width: 22%;
    display: inline-block;
    margin-right: 10px;
    position: relative;

    @include media-breakpoint-down(lg) {
      width: calc(100% / 4 - 10px);
    }

    @include media-breakpoint-down(md) {
      width: calc(100% / 4 - 10px);
    }

    @media (max-width: 576px) {
      width: 91%;
      margin: 2px 15px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 2px 0px;
    }

    &.trending {
      width: 90%;

      label:hover {
        color: $radivision-blue;
      }

      .dropdown-menu {
        padding-bottom: 10px;
      }

      .checkbox {
        &+label:before {
          border: none;
        }

        &:hover+label:before {
          background-color: transparent;
        }

        &:focus+label:before {
          box-shadow: none;
        }
      }
    }

    .dropdown-button,
    .dropdown-menu {
      text-transform: uppercase;
      letter-spacing: 0.075rem;
      font-size: 12px;
      // letter-spacing: 0.3px;
      font-weight: 800;
    }

    .dropdown-button {
      width: 100%;
      background-color: $snow-white;
      color: $radivision-dk-gray;
      border-color: #dddfe2;
      padding: 0.6rem 0.7rem;
      font-size: 14px;
      text-align: left;
      font-family: "MuliExtraBold";
      letter-spacing: 0.075rem;

      @media (min-width: 800px) and (max-width: 1280px) {
        font-size: 12px;
      }

      &::after {
        background: url("../static/down-arrow.png") center center no-repeat;
        width: 25px;
        height: 25px;
        background-size: 100%;
        border: none;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        right: 10px;
      }
    }

    .dropdown-menu {
      background-color: $radivision-dk-gray;
      margin-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      transform: none !important;
      top: 96% !important;
      padding-top: 20px;
      padding-left: 0;
      padding-bottom: 58px;
      width: 100%;
      min-width: 285px;

      &.wide-dropdown-menu {
        @include media-breakpoint-up(md) {
          padding-top: 40px;
          padding-left: 20px;
          -webkit-column-count: 3;
          -moz-column-count: 3;
          column-count: 3;
          width: 750px;
        }

        @include media-breakpoint-only(md) {
          column-count: 2;
          width: 450px;
        }
      }

      .dropdown-item,
      .dropdown-item:focus,
      .dropdown-item:hover {
        color: white;
        background-color: transparent;
        padding-right: 12px;
      }

      .clear-filter {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        color: white;
        height: 44px;
        padding-left: 12px;
        border-top: 1px solid white;
        cursor: pointer;
        line-height: 34px;
        letter-spacing: 0.075rem;
        display: flex;
        align-items: center;

        svg {
          fill: $radivision-blue;
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }
      }
    }

    &.show,
    &:focus,
    &:hover {
      .dropdown-button {
        background-color: $radivision-dk-gray;
        color: white;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        opacity: 0.96;
      }

      .dropdown-menu {
        display: block;
        opacity: 0.96;
      }
    }

    .checkbox {
      // position: absolute; // take it out of document flow
      opacity: 0; // hide it

      &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        font-weight: 800;
        letter-spacing: 0.075rem;
        color: #fff;
        min-width: 100%;

        span {
          position: relative;
        }
      }

      // Box.
      &+label span:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: bottom;
        width: 20px;
        height: 20px;
        background: transparent;
        border: 1px solid white;
      }

      // Box hover
      &:hover+label span:before {
        background: $radivision-blue;
      }

      // Box focus
      &:focus+label span:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked+label span:before {
        background: transparent;
        border: none;
      }

      // Disabled state label.
      &:disabled+label {
        color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled+label span:before {
        box-shadow: none;
        background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked+label span:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 9px;
        background: $radivision-blue;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $radivision-blue, 4px 0 0 $radivision-blue,
          4px -2px 0 $radivision-blue, 4px -4px 0 $radivision-blue,
          4px -6px 0 $radivision-blue, 4px -8px 0 $radivision-blue,
          4px -10px 0 $radivision-blue, 4px -12px 0 $radivision-blue;
        transform: rotate(45deg);
      }
    }
  }

  .tags {
    // margin-bottom: 15px;

    .tag {
      // min-width: 160px;
      letter-spacing: 0.075rem;
      background-color: $radivision-blue;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      padding: 8px 12px 8px 12px;
      border: 1px solid $radivision-blue;
      border-radius: 4px;
      color: white;
      position: relative;
      text-transform: uppercase;

      font-weight: 800;
      font-size: 13px;
      // letter-spacing: 0.8px;
      margin-left: 10px;
      margin-bottom: 10px;

      @media (max-width:1280px) and (min-width:997px) {
        font-size: 11px;
        margin-bottom: 7px;

      }

      @include media-breakpoint-down(md) {
        font-size: 11px;

      }

      svg {
        width: 16px;
        height: 16px;
        margin-left: 12px;
        cursor: pointer;
        vertical-align: sub;
      }

      &.clear {
        cursor: pointer;
        background-color: white;
        color: $radivision-dk-gray;
        border-color: #dddfe2;
        font-weight: 800;

        svg {
          fill: $radivision-blue;
        }
      }
    }
  }

  .dropdown-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

}

.for_tags {
  padding-left: 0;
  padding-right: 10px
}