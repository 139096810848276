.panel-nav {
  margin-bottom: 10px;
  position: relative;
  border-bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  .nav-items {
    max-width: 80%;

    .nav-item {
      display: inline-block;

    }
  }

  .nav-item {
    display: inline-block;

    .nav-link {
      font-size: 14px;
      padding: .6rem 1rem;
      margin: 0 5px;
      text-align: center;
      border-radius: 19px;
      font-family: 'Muli';
      font-size: 13px;
      font-weight: bold;
      color: rgba(254, 254, 254, 1);
      letter-spacing: 0.03em;
      background-color: transparent;
      border: 0;

      @media (min-width: 2000px) {
        font-size: 16px;
      }

      &.active {
        background: white;
        color: rgba(0, 0, 0, 1);
        border-color: none;
      }

      &.dimmed {
        color: #adadad !important;
        cursor: not-allowed;
      }

      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }
  }

  .nav-item:first-child .nav-link {
    margin-left: 0;
  }

  .search-container {
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      float: right;
      display: inline-block;

      .panel-search-bar {
        display: flex;
      }
    }

    @include media-breakpoint-down(md) {
      display: inline-block;

      .panel-search-bar {
        display: flex;
      }
    }

    @include media-breakpoint-down(sm) {
      float: unset;
      display: inline-block;

      .panel-search-bar {
        display: flex;
      }
    }

    input {
      border: none;
      text-overflow: ellipsis;

      @media (min-width: 2000px) {
        font-size: 1.5rem;
        line-height: 2.5;
        height: calc(2.5em + 0.75rem + 2px);
      }
    }

    button {
      background-color: $radivision-blue;
      border: none;
      padding: 9.5px 14px;
      border-radius: 0;

      @media (min-width: 2000px) {
        padding: 18.5px 22px;
      }

      &.close-btn {
        position: absolute;
        top: 0px;
        right: 0;
        z-index: 100;
        bottom: 0;
        margin: 0 auto;
        height: 43px;

        @media (min-width: 2000px) {
          height: 74px;
        }
      }

      svg {
        fill: white;
        display: inline-block;
        vertical-align: middle;
        width: 16px;

        &.close {
          width: 16px;

          @media (min-width: 2000px) {
            width: 35px;
          }
        }

        @media (min-width: 2000px) {
          width: 35px;
        }
      }
    }
  }

  .resources {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -12px;
    font-size: 13px;
    font-weight: 600;

    @media (min-width: 2500px) {
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.dropdown-panel {
    border-bottom: 1px solid $radivision-lt-gray;

    .dropdown {
      padding-left: 0;
      width: 300px;

      a:hover {
        text-decoration: none;
      }

      .dropdown-toggle {
        border: 1px solid $radivision-lt-gray;
        max-width: 230px;
        color: $radivision-dk-gray;
        padding: 10px 30px 10px 15px;
        border-radius: 3px;
        font-weight: 800;
        font-size: 14px;
        // letter-spacing: 1px;
        text-transform: uppercase;
        letter-spacing: 0.075rem;
        background-color: white;
        position: relative;
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-bottom: 0;

        &::after {
          background: url("../static/down-arrow.png") center center no-repeat;
          width: 20px;
          height: 20px;
          border: none;
          position: absolute;
          top: 50%;
          margin-top: -10px;
          right: 8px;
          background-size: 100%;
        }
      }

      &.show,
      &:hover {
        .dropdown-toggle {
          background-color: $radivision-dk-gray;
          opacity: 0.9;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          color: white;
        }

        .dropdown-menu {
          display: block;
          background-color: $radivision-dk-gray;
          opacity: 0.9;
          margin-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          transform: none !important;
          top: 43px !important;
        }
      }

      .dropdown-menu {
        padding: 0;
        border: none;
        border-radius: 4px;
        width: 100%;

        &.show,
        &:hover {
          opacity: 0.9;
          margin-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .dropdown-item {
        padding: 10px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
          display: inline-block;
          font-weight: 700;
          font-size: 14px;
          color: white;
          width: 100%;
        }

        &:focus,
        &:hover {
          cursor: pointer;
          background-color: $radivision-dk-gray;

          a {
            color: $radivision-blue;
          }
        }
      }
    }

    .search-container {
      position: absolute;
      right: 0;
      top: 0;

      &.search-container-shown {
        width: 100%;
      }

      .close-btn {
        margin: 0;
        height: 43px;
      }
    }
  }
}