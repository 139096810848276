.pitch-grid,
.featured-offers {
  position: relative;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  &::after {
    content: "";
    flex-grow: 1;
  }

  .item-card-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .loader-container.spinner {
    background: none;
    background-color: none;
  }
  .item-card-actions {
    padding: 0 19px;
    margin: 13px 0px;
    display: none;
    align-items: center;
    width: 100%;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #525252;
      cursor: pointer;
      border-radius: 17px;
      flex: 1;
      margin: 0px 4px;
      padding: 7px;
      max-width: 60px;

      svg {
        height: 15px;
        width: 15px;
        fill: #fff;
      }

      &.play-btn {
        background-color: $radivision-blue;
      }

      &.saved {
        background-color: #fff;

        svg {
          fill: $radivision-blue;
        }
      }

      @include media-breakpoint-down(md) {
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0px 5px;
      margin-top: 7px;
      box-sizing: border-box;
    }
  }
  &:hover {
    .slick-arrow {
      background-color: rgba(0, 0, 0, 0.6);
      svg {
        opacity: 1;
        transform: scale(1.3);
      }
    }
  }
  .item-card {
    color: #fff;
    font-size: 36px;
    position: relative;
    text-align: center;
    width: 100%;
    &:hover {
      .popover {
        display: block;
      }
    }
    &.BookStory {
      .item-card-text {
        padding: 10px 0px;
        a {
          text-align: center;
        }
      }
    }
    .item-preview-container {
      position: relative;
      top: 0px;
      right: 0px;
      left: 0px;
      height: 0px;
      padding-top: 60%;
      cursor: pointer;
    }
    .popover {
      background-color: #212121;
      position: absolute;
      left: 0px;
      right: 0px;
      max-width: unset;
      border-radius: 20px;
      display: none;
      transform-origin: center center;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.5, 0.1, 0, 1);
      transform: scale(1) translateY(0);
      z-index: 99;
      &:hover {
        transform: scale(1.2) translateY(-20px);
      }
      .item-preview-container {
        position: relative;
        background-color: #272727;
        border-radius: 20px 20px 0px 0px;
        padding-top: 60%;
        height: 0px;
        .item-video {
          pointer-events: none;
          border-radius: 20px 20px 0px 0px;
          transition: opacity 0.3s ease-in;
          z-index: 1;
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
        }
        .item-preview {
          border-radius: 20px 20px 0px 0px;
          z-index: 0;
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
      .item-card-actions {
        display: flex;
        justify-content: space-between;
      }
      .item-card-text {
        padding: 10px 10px;
        position: relative;
        z-index: 2;
        & > a {
          margin: 0px 15px;
          max-height: unset;
          min-height: unset;
          text-decoration: none;
          &::after {
            display: none;
          }
        }
      }
      .referral {
        right: 10px !important;
        flex-flow: row nowrap;
        .profile-picture {
          margin-left: 10px !important;
          display: flex !important;
        }
      }
    }
    .item-card-text {
      position: relative;
      font-size: 14px;
      color: #fff;
      text-align: left;
      padding: 10px 0px 10px 10px;
      transition: transform 0.1s linear;
      a {
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        min-height: 58px;
        max-height: 58px;
        width: 58px;
        height: 58px;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;
        padding: 0px;
      }
      .referral {
        position: absolute;
        top: 5px;
        right: 0px;
        .profile-picture {
          min-height: unset;
          max-height: unset;
          height: 36px;
          width: 36px;
        }
      }
    }
    .item-card-title {
      margin: 20px 0px;
      font-size: 16px;
      height: 40px;
      display: flex;
      justify-content: space-between;
    }
  }
  .item-preview-container {
    border-radius: 10px;
    background: #272727;
    overflow: hidden;
    position: relative;
    top: 0px;
    right: 0px;
    left: 0px;
    .item-video {
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-color: #171717;
      flex: 1;
      border-radius: 20px;
      overflow: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }
    .item-preview {
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
      transform: scaleY(1.1);
    }
  }
  .item-card {
    outline: none;
    font-size: 24px;
    .item-card-title {
      flex-flow: column;
      margin: 20px 0px;
      height: auto;
    }
  }
  .referral {
    height: 42px;
    display: flex;
    visibility: visible;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px !important;
    color: white !important;
    .referral-name {
      flex-flow: column;
      justify-content: flex-start;
    }
    p.nominated-by {
      font-size: 12px !important;
      color: #999 !important;
      text-align: right;
      padding: 0px;
      margin: 0px;
    }
    a.nominated-by {
      font-size: 13px !important;
      color: #bbb !important;
      font-family: MuliBold;
      &:hover {
        color: $radivision-blue !important;
        text-decoration: none;
      }
    }
    .profile-picture-name {
      cursor: pointer;
      flex-flow: row-reverse nowrap;
      .profile-picture {
        width: 34px !important;
        height: 34px !important;
        margin-left: 10px;
        margin-right: 0px;
        border: 0px !important;
        background-size: 120%;
      }
      .profile-name {
        display: none;
      }
    }
  }
  ul.pitch-tags {
    margin: 0px;
    padding: 0px 15px;

    li {
      margin: 0px 10px 0px 0px;
      padding: 0px;
      font-size: 11px;
      list-style: none;
      color: #999999;
      text-transform: capitalize;
    }
  }
  @include media-breakpoint-up(xl) {
    min-height: 245px;
    margin: 35px 70px;
    .item-card-container {
      width: calc(15% - 20px);
      margin: 10px 10px 30px 10px;
    }
    .profile-picture-name {
      .profile-name {
        a {
          font-size: 14px;
        }
      }
    }
    ul.pitch-tags {
      padding: 0px 15px;
    }
  }
  @include media-breakpoint-up(lg) {
    min-height: 245px;
    margin: 35px 70px;
    .item-card-container {
      width: calc(20% - 20px);
    }
  }
  @media (min-width: 900px) and (max-width: 1280px) {
    min-height: 245px;
    margin: 35px 70px;
    .item-card-container {
      width: calc(25% - 20px);
    }
  }

  @include media-breakpoint-down(md) {
    min-height: 213px;
    margin: 35px;
    .item-card-container {
      width: calc(33% - 20px);
    }
    ul.pitch-tags {
      padding: 0px 10px;
    }
  }

  @include media-breakpoint-down(md) {
    &::after {
      display: none;
    }
  }
  @include media-breakpoint-up(sm) {
    .profile-picture {
      display: none;
    }
    .item-card > ul.pitch-tags {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    min-height: 250px;
    margin: 0px 24px;
    .item-card-container {
      width: 100%;
    }
    .item-preview-container {
      margin: 30px 0px 5px 0px;
    }
    .item-card {
      text-align: left !important;
      &:hover {
        .popover {
          display: none !important;
        }
      }
    }
    ul.pitch-tags {
      margin-top: 5px;
      padding: 0px 0px !important;
      li {
        font-family: MuliSemiBold;
      }
    }
    .item-card-text {
      padding: 5px 0px 0px 0px !important;
      span {
        color: #888;
      }
      .read-more {
        color: #ccc;
      }
      .profile-name {
        span {
          color: #ccc;
        }
      }
      a.profile-picture {
        width: 42px !important;
        height: 42px !important;
        max-width: 42px !important;
        max-height: 42px !important;
        min-height: auto;
      }
    }
    &::after {
      display: none;
    }
  }
}

.pitch-planet-filters {
  width: 100%;
  padding: 40px 70px 0px 0px;
  margin: 0px 0px 0px 0px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  .filter-tab {
    cursor: pointer;
    padding: 3px 15px 0px 20px;
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    background-color: #212121;
    color: #ddd;
    border-radius: 20px;
    position: relative;
    height: 30px;
    a.filter-name {
      color: #dddddd !important;
      white-space: nowrap;
      text-decoration: none;
      text-transform: capitalize;
      svg {
        fill: #aaa;
        width: 12px;
        margin-left: 5px;
        transform: rotate(90deg);
      }
    }
    ul {
      position: absolute;
      background-color: #212121;
      width: max-content;
      min-width: 120%;
      display: none;
      top: 34px;
      right: 0px;
      padding: 20px 20px;
      border-radius: 15px 0px 15px 15px;
      overflow-y: scroll;
      z-index: 200;
      max-height: 400px;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        .checkbox-control {
          flex-grow: 1;
          display: flex;
          align-items: center;
          .checkbox-sign {
            width: 14px;
            height: 14px;
            border-radius: 3px;
          }
          &:hover {
            label {
              color: $radivision-blue;
            }
          }
        }

        label {
          margin-bottom: 0px !important;
          cursor: pointer;
          flex-grow: 1;
          text-transform: capitalize;
          display: flex;
          &:hover {
            color: $radivision-blue;
          }
          & > div {
            flex: 1;
            display: flex;
            justify-content: space-between;
            span:last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }
    &:hover {
      border-radius: 15px 15px 0px 0px !important;
      height: 34px;
      ul {
        display: block;
      }
    }
  }
  @include media-breakpoint-down(md) {
    display: flex;
    padding: 0px 20px;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    margin-bottom: 0px;
    .filter-tab {
      margin-bottom: 10px;
      margin-left: 0px;
      margin-right: 5px;
      padding: 7px 7px 0px 12px;
      height: 34px;
      display: block;
      max-width: 150px;
      border-radius: 5px;
      flex-grow: 1;
      a.filter-name {
        font-size: 14px;
        max-height: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      ul {
        min-width: 110%;
        border-radius: 5px;
        top: 33px;
        li {
          margin-bottom: 5px;
          label {
            font-size: 14px;
          }
        }
      }
      &:last-child {
        margin-right: 0px;
        ul {
          border-radius: 5px 0px 5px 5px;
        }
      }
      &:nth-child(2) {
        ul {
          border-radius: 0px 5px 5px 5px;
          right: unset;
          left: 0px;
        }
      }
      &:first-child {
        ul {
          right: unset;
          left: 0px;
          border-radius: 0px 5px 5px 5px;
        }
      }
      &:hover {
        border-radius: 5px 5px 0px 0px !important;
        height: 38px;
        ul {
          top: 37px;
          display: block;
        }
      }
    }
  }
}

.pitch-grid-loading-container {
  height: 200px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
h4.pitch-no-results {
  text-align: center;
  flex-grow: 1;
}
.featured-offers {
  .item-card-actions {
    display: flex;
    padding: 0px 5px;
  }
  .item-preview-container {
    background-color: #313131;
    border-radius: 20px 20px 0px 0px;
  }
  .item-card-container {
    background-color: #272727;
    border-radius: 20px;
  }
  .item-card-text {
    a {
      &::after {
        display: block;
        content: "";
        position: absolute;
        background-image: linear-gradient(90deg, rgba(39, 39, 39, 0), #272727);
        height: 15px;
        bottom: 0;
        right: 0;
        width: 70%;
      }
    }
  }
}
#FeaturedOffers,
.pitch-grid-container {
  h4 {
    padding: 10px 70px 0px 70px;
    @include media-breakpoint-down(sm) {
      padding: 24px;
    }
  }
}
