/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-15
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.profile-body {
  display: flex;
  margin: 0px 30px;
  flex-flow: row nowrap;
  .profile-sidebar {
    overflow-x: hidden;
    display: none;
    flex-shrink: 0;
  }
  .intro {
    position: relative;
    color: white;
    text-align: center;
    font-size: 20px;
    width: 80%;
    margin: 2rem auto;
    color: $radivision-blue;
  }
  .intro-title {
    color: white;
    font-size: 24px;
    margin: 0px 0px 20px 0px;
    font-family: MuliSemiBold;
    text-align: left;
    span {
      color: $radivision-blue;
    }
  }
  .description {
    font-size: 18px;
    margin: 0px 20px 0px 0px;
    text-align: left;
    margin-bottom: 1rem;
  }
  @media (min-width: 1200px) {
    margin: 0px auto;
    width: 1100px;
    .tab-pane {
      &#Opportunities,
      &#Links,
      &#About,
      // &#Portfolio,
      // &#People,
      &#Posts {
        min-height: 500px;
      }
      &#Marketplace {
        //border-bottom: 1px solid #313131;
      }
    }
    .profile-sidebar {
      display: flex;
      flex-flow: column nowrap;
      width: 250px;
      margin-right: 50px;
      padding-top: 30px;
      .btn-radivision {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: 1px solid #4cb4e7;
        color: #4cb4e7 !important;
        margin: 7px auto 43px auto;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 30px;
        font-size: 16px;
      }
      h4 {
        font-size: 16px;
        font-family: MuliBold;
        margin: 0px 0px 0px 0px;
        padding: 0px;
        opacity: 0.6;
      }
      h1 {
        hyphens: auto;
        font-size: 28px;
        font-family: MuliBold;
        margin: 10px 0px 20px 0px;
        padding: 0px;
      }
      .company-list {
        margin-top: 30px;
      }
      .company-leadership {
        margin-top: 30px;
      }
      article {
        font-size: 16px;
        padding-right: 10%;
        margin-bottom: 20px;
      }
      .website {
        text-decoration: none;
        display: flex;
        margin: 20px 0px 0px 0px;
        font-size: 14px;
        color: $radivision-blue;
        box-sizing: border-box;
        flex-flow: row nowrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg {
          margin-right: 10px;
          width: 14px;
          fill: $radivision-blue;
        }
      }
      .profile-picture-name {
        margin: 20px 0px;
        cursor: pointer;
        a.profile-picture {
          width: 48px !important;
          height: 48px !important;
          border: 0;
        }
        a.profile-name {
          text-decoration: none;
          &:hover {
            color: $radivision-blue;
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    margin: 0px auto;
    width: 1500px;
    .profile-sidebar {
      display: flex;
      width: 400px;
      margin-right: 50px;
      h1 {
        font-size: 28px;
      }
      article {
        font-size: 18px;
      }
      .website {
        font-size: 16px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0px 15px;
  }
}
.profile-details {
  flex-grow: 1;
  @include media-breakpoint-down(lg) {
    width: 100%;
    margin-top: 0px;
  }
  .tab-content {
    min-height: 400px;
  }
}
#tabs {
  padding: 0;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .tab-scroll-btn {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 35px;
    z-index: 10;
    svg {
      fill: #aaaaaa;
      width: 30px;
      height: 30px;
    }
  }
  .tab-next {
    right: -15px;
    background: linear-gradient(90deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 1) 75%);
  }
  .tab-prev {
    left: -15px;
    background: linear-gradient(90deg, rgba(22, 22, 22, 1) 22%, rgba(22, 22, 22, 0) 100%);
  }
  ul {
    border-bottom: none;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: center;
    position: relative;
    margin-bottom: 36px;
    @include media-breakpoint-down(md) {
      justify-content: space-around;
      margin: 36px 0px;
      border-bottom: 1px solid #313131;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0.1;
      bottom: 0;
      z-index: -1;
    }

    .nav-my-media-tab {
      svg {
        width: 40px;
        height: 28px;
        margin-right: 14px;

        @media (width: 1366px) {
          width: 35px;
          height: 24px;
        }

        @media (min-width: 2500px) {
          width: auto;
          height: 40px;
        }
      }

      .my-media-l1 {
        fill: none;
        stroke: white;
        stroke-width: 5.0555;
        stroke-miterlimit: 10;
      }

      .my-media-l2 {
        fill: $radivision-blue;
        stroke: $radivision-blue;
        stroke-width: 5.0555;
        stroke-miterlimit: 10;
      }
    }

    .nav-overview-tab {
      .cls-2 {
        fill: white;
      }
    }

    .nav-northstar-tab {
      .star-1 {
        fill: darkgray;
      }
    }
    .nav-twitterFeeds-tab {
      svg {
        width: auto;
        height: 18px;
        fill: rgba(255, 255, 255, 0.5);
        margin-top: 2px;
        @media (min-width: 2000px) {
          height: 28px;
        }
      }
      a.active,
      &:hover {
        svg {
          fill: #fff;
        }
      }
    }

    .dna-tab {
      svg {
        position: relative;
        .dna-1 {
          fill: white;
        }
      }
    }

    li {
      flex: 1;
      list-style-type: none;
      text-align: center;
      flex-grow: 1;
      max-width: 25%;

      @include media-breakpoint-down(sm) {
        padding-right: 10px;
        padding-left: 10px;
        max-width: unset;
      }
      a {
        padding: 5px 10px;
        position: relative;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.5);
        margin: 0px 3px;
        border-top: none;
        border-radius: 0;
        font-size: 18px;
        text-transform: capitalize;
        white-space: nowrap;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        border-bottom: 2px solid transparent;
        line-height: normal !important;
        height: 100% !important;
        vertical-align: middle;
        padding-bottom: 10px;
        transition: all 0.3s;

        @media (min-width: 1200px) {
          font-size: 18px;
        }
        @media (min-width: 1600px) {
          font-size: 20px;
        }

        @include media-breakpoint-down(sm) {
          padding-right: 10px;
          padding-left: 10px;

          span {
            display: block;
            font-size: 16px;
          }
        }

        &:hover {
          color: #fff;
        }
        span.new-label {
          position: absolute;
          bottom: 70%;
          right: -25px;
          color: $radivision-blue;
          font-size: 10px;
          font-family: MuliBold;
          @include media-breakpoint-down(sm) {
            right: -20px;
          }
        }
        @include media-breakpoint-down(sm) {
          svg {
            display: none !important;
          }
        }
      }

      a.active {
        border-bottom-color: $radivision-blue;
        color: #fff;
        font-family: "Muli";

        border-radius: 0;

        @include media-breakpoint-down(sm) {
          svg {
            margin-left: 0;
            margin-right: 5px;
          }
          span {
            display: inline;
          }
        }
      }

      .active {
        border-bottom-color: $radivision-blue;
        color: #fff;
        font-family: "Muli";
      }

      h6 {
        color: $radivision-dk-gray;
        font-size: 9pt;

        @include media-breakpoint-down(sm) {
          font-size: 6pt;
        }
      }

      svg {
        width: 28px;
        height: 28px;
        min-width: 28px;
        margin: -5px 5px;
        margin-right: 10px;

        @media (min-width: 2500px) {
          width: auto;
          height: 38px;
          margin-right: 10px;
          vertical-align: text-top;
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .my-media-2,
    .my-media-l3 {
      fill: $radivision-blue;
    }

    .my-media {
      .my-media-l1 {
        stroke: white;
      }
    }

    .dna-2 {
      fill: $radivision-blue;
    }

    .dna {
      .dna-1 {
        fill: white;
        display: flex;
        flex-flow: row nowrap;
      }
    }

    .star {
      .star-0 {
        fill: white;
      }

      .star-1 {
        fill: darkgray;
      }

      .star-2 {
        fill: $radivision-blue;
      }
    }

    #overview-icon {
      .cls-1 {
        fill: $radivision-blue;
      }
    }

    .overview {
      .cls-2 {
        fill: white;
      }
    }

    .account-settings {
      .setting-icon {
        fill: white;
      }
    }

    .notification {
      svg {
        fill: $radivision-blue;
      }
    }
  }
}
.arrow-menu {
  svg {
    fill: white;
  }
}

.social-links-panel {
  display: flex !important;
  margin-top: 72px;
  min-height: 300px;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  h2.no-content {
    font-size: 18px;
    font-family: Muli;
    text-align: center;
    line-height: 24px;
    max-width: 70%;
    margin: 0px auto;
    a.clickable {
      color: $radivision-blue;
      cursor: pointer;
    }
  }
  .social-link {
    margin: 20px;
    &:hover {
      text-decoration: none;
    }
    figure {
      width: 100px;
      height: 100px;
      border-radius: 50px;

      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: #fff;
        width: 30%;
        max-width: 30%;
      }
      &.FACEBOOK {
        background-color: #1877f2;
        svg {
          transform: translateX(10px);
        }
      }
      &.TWITTER {
        background-color: #1da1f2;
        svg {
          transform: translateY(5px);
        }
      }
      &.LINKED_IN {
        background-color: #0072b1;
      }
      &.INSTAGRAM {
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
      }
      &.TUMBLR {
        background-color: #34526f;
      }
      &.PINTEREST {
        background-color: #c8232c;
      }
      &.WEIBO {
        background-color: #df2029;
        svg {
          transform: scale(1.2);
        }
      }
      &.MEDIUM {
        background-color: #e4e4e4;
        svg {
          fill: #000000;
          transform: scale(1.1);
        }
      }
      &.WHATS_APP {
        background-color: #075e54;
        svg {
          transform: scale(1.2);
        }
      }
      &.REDDIT {
        background-color: #ff4500;
        svg {
          transform: scale(1.4);
        }
      }
      &.YOU_TUBE {
        background-color: #ff0000;
        svg {
          transform: scale(1.4);
        }
      }
      &.SNAPCHAT {
        background-color: #fffc00;
        svg {
          transform: scale(1.2);
          filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.7));
        }
      }
      &.ANGEL_LIST {
        background-color: #ffffff;
        svg {
          fill: #000000;
        }
      }
    }
    h4 {
      text-decoration: none;
      text-align: center;
      font-size: 16px;
      color: #fff;
      text-decoration: none;
      @media (min-width: 600px) {
        font-size: 15px;
      }
      @media (min-width: 1200px) {
        font-size: 16px;
      }
      @media (min-width: 1900px) {
        font-size: 19px;
      }
      border: 0;
      &:hover {
        color: $radivision-blue;
      }
    }
  }
}
