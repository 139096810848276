.toast {
  position: fixed;
  top: 0;
  z-index: 9999;
  opacity: 0;
  transition: all 0.4s;
  left: 50%;
  transform: translateX(-50%);
  padding: 9px 48px;
  border-radius: 24px;
  background-color: rgba(22,22,22,0.95);
  max-width: none;
  width: auto!important;

  @include media-breakpoint-down(sm) {
    width: 90%!important;
    box-sizing: border-box;
    padding: 9px 10px;
  }

  @keyframes toasterSlideIn {
    from {
      top: 0px;
    }
    to {
      top: 120px;
    }
  }

  @include media-breakpoint-down(sm) {
    @keyframes toasterSlideIn {
      from {
        top: 0px;
      }
      to {
        top: 80px;
      }
    }
  }

  &.show {
    animation: toasterSlideIn 0.4s ease-out;
    animation-fill-mode: forwards;
  }

  &.danger {
    background: rgba(246, 74, 107, 0.95);
    .toast-body, .toast-header {
      color: black;
    }
  }
  
  &.alert {
    background: rgba(255, 230, 200, 0.95);
    .toast-body, .toast-header {
      color: black;
    }
  }

  .toast-header {
    strong {
      color: #fff;
      flex: 1;
    }

    button {
      color: #fff;
      opacity: 1;
    }
  }

  .toast-body, .toast-header {
    padding: 0;
    flex: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0px!important;
    margin-bottom:0px!important;

    .toaster-icon {
      width: 20px;
      height: 20px;
      padding: 2px;
      border-radius: 50%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;

      i {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
      }

      svg {
        width: 14px;
        height: 14px;
        fill: $dark-mode-bg;
      }

      i.success {
        svg {
          fill: #28a745;
        }
      }
      i.danger {
        svg {
          fill: #D00;
        }
      }
      i.alert {
        padding:0px!important;
        margin-bottom:0px!important;
        background: rgba(255, 230, 200, 1);
        svg {
          fill: black;
        }
      }
    }

    & > span.message, & > span.header-message {
      flex: 1;
      text-align: center;
      font-size: 16px;
    }

    button {
      margin-left: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      color: #fff;
      text-shadow: none;
      opacity: 1;
      font-size: 18px;
      line-height: 14px;
      padding-bottom: 2px;

      svg {
        width: 10px;
        height: 10px;
        fill: #fff;
      }
    }
  }
}
