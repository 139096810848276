.investment-notification-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0px 0px 20px 0px;
  border-bottom: 1px solid #313131;
  padding-bottom: 8px;
  h2.no-content {
    margin-top: 26px;
    font-size: 18px;
    font-family: Muli;
    text-align: center;
    line-height: 24px;
    max-width: 80%;
    a.clickable {
      color: $radivision-blue;
      cursor: pointer;
    }
  }
  form {
    display: flex;
    width: 70%;
    max-width: 400px;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    input {
      display: block;
      flex-grow: 1;
      width: 100%;
      border: 0px;
      background-color: #272727;
      border-radius: 25px;
      margin: 10px 0px;
      font-size: 17px;
      color: #ffffff;
      padding: 8px 20px;

      &::placeholder {
        color: #aaaaaa;
      }
    }
    .investor-type {
      margin: 20px 0px;
    }
  }
  button.submit {
    background-color: $radivision-blue;
    font-size: 18px;
    font-family: MuliSemiBold;
    padding: 5px 40px;
    margin: 20px 20%;
    border-radius: 25px;
    color: white;
    border: 0px;
  }
  @media (min-width: 600px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1900px) {
  }
}
