.pitch-carousel {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  padding: 0px 0px 20px 0px;
  margin: 0px 0px;

  .loader-container.spinner {
    background: none;
    background-color: none;
  }
  .panel-title {
  }
  .bottom-shadow {
    pointer-events: none;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(22, 22, 22, 1) 0%,
      rgba(22, 22, 22, 1) 2%,
      rgba(22, 22, 22, 0) 80%,
      rgba(22, 22, 22, 0) 100%
    );
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }
  .top-shadow {
    pointer-events: none;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(22, 22, 22, 1) 0%,
      rgba(22, 22, 22, 1) 2%,
      rgba(22, 22, 22, 0) 40%,
      rgba(22, 22, 22, 0) 100%
    );
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }
  .pitch-get-discovered {
    opacity: 0.7;
    display: flex;
    position: absolute;
    z-index: 2;
    font-size: 14px;
    color: #ffffff;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
      color: #ffffff;
      fill: #ffffff;
      margin-right: 5px;
    }
  }

  .pitch-carousel-header {
    display: flex;
    position: relative;
    .view-all {
      margin-top: 10px;
      display: none;
      visibility: hidden;
      a {
        text-decoration: none;
        color: $radivision-blue;
        cursor: pointer;
        font-family: "Muli";
        font-weight: 500;
        font-size: 22px;
        align-items: center;
        display: flex;
        svg {
          width: 20px;
          fill: $radivision-blue;
        }
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 0.075rem !important;
      font-family: "MuliExtraBold";
      img {
        width: 35vw;
      }
      a {
        display: none;
        font-size: 13px;
        display: none;
        margin-left: 20px;
        font-weight: normal;
        color: #19acf7;
      }
    }
    p {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 14px;
    }
    h3.pitch-panel-title {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: flex-start;
    }
    .audio-controls {
      opacity: 1;
      display: flex;
      color: #000000;
      background-color: #ffffff;
      border-radius: 16px;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 0px 20px !important;

      @include media-breakpoint-down(sm) {
        max-width: 32px;
      }

      svg {
        width: 18px;
        height: 18px;
        color: #000000;
        fill: #000000;
      }
    }
  }
  &.is-standalone {
    .pitch-carousel-header {
      .view-all {
        display: flex;
        visibility: visible !important;
      }
      p {
        font-size: 14px !important;
        line-height: 22px;
      }
    }
  }
  .slick-slider {
    position: relative;
    z-index: 1;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide:not(.slick-center) {
    .pitch-card-title {
      opacity: 0;
    }
    .pitch-card-text {
      opacity: 0;
    }
  }
  .pitch-card {
    color: #fff;
    width: 30wv;
    font-size: 36px;
    margin: 10px;
    position: relative;
    text-align: center;
    .company-referral {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 2px 8px;

      .company-name {
        width: 50%;
      }
    }
    .referral {
      width: 50%;
      height: 48px;
      display: flex;
      visibility: hidden;
      flex-flow: row;
      justify-content: flex-end;
      align-items: center;
      font-size: 13px !important;
      color: white !important;
      .referral-name {
        flex-flow: column;
        justify-content: flex-start;
      }
      p.nominated-by {
        font-size: 14px !important;
        color: white !important;
        text-align: right;
        padding: 0px;
        margin: 0px;
      }
      a.nominated-by {
        font-size: 13px !important;
        color: white !important;
        font-family: MuliBold;
        &:hover {
          color: $radivision-blue !important;
          text-decoration: none;
        }
      }
      .profile-picture-name {
        cursor: pointer;
        flex-flow: row-reverse nowrap;
        .profile-picture {
          width: 38px !important;
          height: 38px !important;
          margin-left: 10px;
          margin-right: 0px;
          border: 0px !important;
          background-size: 120%;
        }
        .profile-name {
          display: none;
        }
      }
    }
    .pitch-card-buttons {
      display: flex;
      width: 95%;
      margin-bottom: 10px;
      .btns {
        margin-left: auto;
        margin-right: 0;
      }
    }
    .pitch-card-text {
      transition: opacity 0.3s ease-in;
      font-size: 14px;
      color: #fff;
      text-align: left;
      padding-bottom: 20px;
      margin-bottom: 40px;
      max-height: 35vh;
      overflow: auto;
      padding-right: 10px;
    }
    .pitch-card-title {
      width: 50%;
      transition: opacity 0.3s ease-in;
      margin: 20px 0px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      .select-pitch-version {
        margin-top: 0px !important;
      }
      .pitch-card-actions {
        display: flex;
        flex-flow: row;
        .pitch-card-button {
          cursor: pointer;
          text-decoration: none;
          margin-left: 10px;
          background-color: #19acf7;
          font-size: 14px;
          color: white;
          height: 36px;
          border-radius: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 10px;
          &.is-grey {
            border: 2px solid grey;
            color: grey;
          }
          &.outline-button {
            background-color: transparent;
            border: 2px solid #ffffff;
          }
          &.disabled {
            background-color: grey;
            color: #bbbbbb;
            cursor: not-allowed;
          }
        }
        .btn-radivision {
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          font-size: 15px;
          padding: 5px 20px;
          margin: 10px 0px 0px 10px;
        }
        svg {
          margin: 0px 0px 0px 10px;
          width: 15px;
          height: 15px;
          color: white;
          fill: white;
        }
      }
      .profile-picture-name {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        .profile-picture {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          border: 2px solid white;
          margin-right: 10px;
          background-color: white;
          background-size: cover;
          background-position: center center;
          box-sizing: border-box;
          overflow: hidden;
        }
        .profile-name {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
  .pitch-video-container {
    border-radius: 20px;
    // overflow: hidden;
  }
  .pitch-youtube {
    object-fit: fill;
  }
  .slick-slide:not(.slick-current) {
    .pitch-poster {
      // filter: brightness(50%);
    }
  }
  .slick-current {
    // .pitch-video {
    //   box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.5);
    // }
    .referral {
      visibility: visible;
    }
  }
  .pitch-video-container {
    padding-bottom: 56.25%;
    height: 0px;
    position: relative;
    &.current {
      border: 3px solid rgb(75, 75, 75);
      border-radius: 22px;
    }
    .pitch-video {
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-color: #171717;
      flex: 1;
      border-radius: 20px;
      overflow: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      & > div {
        height: 100%;
      }
    }
    .pitch-poster {
      border-radius: 20px;
      pointer-events: none;
      overflow: hidden;
      transition: all 0.8s linear;
      background-size: cover;
      background-position: center center;
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
    }
  }
  .center {
  }
  .center .pitch-card {
    // transition: transform 300ms ease;
    // transform: scale(0.95);
  }
  .center .slick-center .pitch-card {
    color: #e67e22;
    // transform: scale(1.05);
  }
  .content {
    padding: 0px;
    margin: auto;
  }
  @media (max-width: 700px) {
    .content {
      width: 70%;
    }
  }
  .slick-slide .image {
    padding: 10px;
  }
  .slick-slide img.slick-loading {
    border: 0;
  }
  .slick-slider {
    position: relative;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    width: 100%;
    height: 100%;
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
  .slick-vertical .slick-slide {
    height: 280px;
  }
  .slick-arrow {
    position: absolute;
    display: flex;
    width: 50px;
    height: 50px;
    top: 50%;
  }
  .slick-arrow:hover {
  }
  .slick-arrow:focus {
  }

  .button {
    padding: 10px 20px;
    margin: 0px 20px;
    border: none;
    color: white;
    font-size: 20px;
    border-radius: 5px;
    min-height: 45px;
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: calc(50% - 5px);
    width: 50px;
    height: 50px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    z-index: 1;
  }
  .slick-arrow svg {
    color: #ffffff;
    width: 50px;
    height: 50px;
  }
  .slick-next {
    right: 20px;
  }
  .slick-prev {
    left: 20px;
  }
  .slick-arrow:before,
  [dir="rtl"] .slick-prev:before {
    content: "";
    display: none;
  }
  .slick-slide,
  .pitch-card {
    outline: none;
    border-radius: 20px;
  }

  .pitch-video-container {
    background: transparent;
    // background: #171717;
  }
  .pitch-get-discovered {
    top: unset;
    bottom: 40px;
    left: 40px;
    right: unset;
  }

  .pitch-carousel-header {
    display: flex;
    flex-flow: column;
    max-width: none;
    position: relative;
    margin: 20px 40px;
    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      a {
        display: none;
        font-size: 13px;
        margin-left: 20px;
        flex-wrap: nowrap;
        font-weight: normal;
        color: #19acf7;
        text-transform: uppercase;
        cursor: pointer;
        white-space: nowrap;
        svg {
          display: inline;
        }
      }
    }
    p {
      font-size: 14px;
    }
  }
  .pitch-card {
    font-size: 24px;
    .pitch-card-title {
      flex-flow: column;
      margin: 20px 0px;
      height: auto;
      .pitch-card-actions {
        flex: 1;
        justify-content: space-between;
        .pitch-card-button {
          margin: 10px 0px;
          flex-grow: 1;
          font-size: 12px;
          max-width: calc(50vw - 56px);
          height: 32px;
          border-radius: 16px;
          padding: 0px 20px;
          &.outline-button {
          }
        }
      }
    }
  }
  .slick-arrow {
    width: 20px;
    height: 20px;
    display: flex;
    top: 23vw;
  }
  .slick-prev {
    left: 0px;
    display: flex;
    justify-content: flex-start;
  }
  .slick-next {
    right: 0px;
    display: flex;
    justify-content: flex-end;
  }
  .slick-arrow svg {
    width: 20px;
    height: 20px;
  }
  @media (min-width: 576px) {
    background-position: center bottom;

    .pitch-video-container {
    }
    .pitch-card {
      font-size: 24px;
      height: auto;
      .pitch-card-text {
        max-height: 25vh;
      }
    }
    .slick-arrow {
      width: 20px;
      height: 20px;
      display: flex;
    }
    .slick-prev {
      left: 0px;
      display: flex;
      justify-content: flex-start;
    }
    .slick-next {
      right: 0px;
      display: flex;
      justify-content: flex-end;
    }
    .slick-arrow svg {
      width: 20px;
      height: 20px;
    }
    .pitch-carousel-header {
      max-width: unset;
      h3 {
        img {
          width: 30vw;
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding: 30px 0px 50px 0px;
    background-position: center bottom;
    .pitch-video-container {
    }
    .pitch-card {
      font-size: 24px;
      height: auto;
      .pitch-card-title {
        flex-flow: column;
        margin: 10px 0px;
        height: auto;
        .pitch-card-actions {
          flex: 1;
          justify-content: space-between;
          .pitch-card-button {
            margin: 10px 0px;
            white-spacing: nowrap;
            flex-grow: 1;
            font-size: 12px;
            // max-width: 49%;
            height: 32px;
            border-radius: 16px;
            padding: 18px 20px;
            &.outline-button {
            }
          }
        }
        .profile-picture-name {
          .profile-picture {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid white;
            margin-right: 10px;
            background-color: white;
            overflow: hidden;
            display: flex;
          }
          .profile-name {
            color: white;
            font-size: 15px;
          }
        }
      }
    }
    .center {
    }
    .center .slick-center .pitch-card {
      color: #e67e22;
      // transform: scale(1.05);
    }
    .center .pitch-card {
      // transform: scale(1);
      // transition: all 300ms ease;
    }
    .slick-arrow {
      width: 30px;
      height: 30px;
      top: 16vw;
      display: flex;
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-arrow svg {
      width: 30px;
      height: 30px;
    }
    .pitch-carousel-header {
      h3 {
        img {
          width: 18vw;
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding: 20px 0px 30px 0px;
    background-position: center bottom;
    .pitch-panel-header {
      max-width: 60vw;
    }
    .pitch-carousel-header {
      h3 {
        img {
          width: 22vw;
        }
      }
    }
  }
  @media (min-width: 950px) {
    padding: 20px 0px 30px 0px;
    background-position: center bottom;
    .pitch-carousel-header {
      max-width: 60vw;
      h3 {
        img {
          width: 22vw;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    padding: 0px 0px 20px 0px;
    background-position: center bottom;
    &.is-standalone {
      min-height: 680px;
    }
    &.is-home-carousel {
      min-height: 0px;
      padding: 0px;
    }
    .bottom-shadow {
      background: linear-gradient(
        0deg,
        rgba(22, 22, 22, 1) 0%,
        rgba(22, 22, 22, 1) 2%,
        rgba(22, 22, 22, 0) 50%,
        rgba(22, 22, 22, 0) 100%
      );
    }
    .top-shadow {
      background: linear-gradient(
        180deg,
        rgba(22, 22, 22, 1) 0%,
        rgba(22, 22, 22, 1) 2%,
        rgba(22, 22, 22, 0) 40%,
        rgba(22, 22, 22, 0) 100%
      );
    }
    .pitch-video {
      & > div {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
      }
    }
    .pitch-video-container {
    }
    .pitch-get-discovered {
      top: 20px;
      right: 20px;
      left: unset;
      bottom: unset;
    }

    .pitch-carousel-header {
      display: flex;
      flex-flow: column;
      max-width: 50vw;
      position: relative;
      margin: 20px 0px 20px 70px;
      h3 {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        font-weight: 800;
        margin-bottom: 0;
        font-size: 30px;
        letter-spacing: 0.075rem !important;
        font-family: "MuliExtraBold";
        img {
          width: 15vw;
        }
        a {
          font-size: 13px;
          display: none;
          margin-left: 20px;
          font-weight: normal;
          white-space: nowrap;
          text-transform: uppercase;
          color: #19acf7;
          svg {
            margin-left: 10px;
            display: flex;
          }
        }
      }
      p {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .slick-slide {
    }
    .slick-current {
      opacity: 1;
      filter: unset;
    }
    .pitch-card {
      margin: 10px;
      height: auto;
      .pitch-card-text {
        font-size: 14px;
        max-height: 16vw;
      }
      .pitch-card-title {
        margin: 10px 0px;
        height: auto;
        flex-flow: row;
        .pitch-card-actions {
          justify-content: flex-end;
          .pitch-card-button {
            font-size: 15px;
            margin-left: 10px;
            flex-grow: 0;
            white-space: nowrap;
            height: 32px;
            border-radius: 16px;
            padding: 0px 25px;
            &.outline-button {
            }
          }
        }
        .profile-picture-name {
          .profile-picture {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid white;
            margin-right: 10px;
            background-color: white;
            overflow: hidden;
            display: flex;
          }
          .profile-name {
            color: white;
            font-size: 15px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
    .pitch-video {
    }
    .pitch-poster {
    }
    .pitch-carousel-header {
    }
    .pitch-card {
      font-size: 24px;
    }
    .center {
    }
    .center .slick-center .pitch-card {
      color: #e67e22;
      // transform: scale(1.05);
    }
    .center .pitch-card {
      // transform: scale(0.96);
      // transition: all 300ms ease;
    }
    .slick-arrow {
      width: 35px;
      height: 35px;
      top: 15vw;
      display: flex;
    }
    .slick-prev {
      left: 35px;
    }
    .slick-next {
      right: 35px;
    }
    .slick-arrow svg {
      width: 35px;
      height: 35px;
    }
  }
  @media (min-width: 1920px) {
    padding: 0px 0px 20px 0px;
    background-position: center center;
    .pitch-video-container {
    }
    .pitch-carousel-header {
      display: flex;
      flex-flow: column;
      max-width: 30vw;
      position: relative;
      margin: 20px 70px;
      h3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 800;
        margin-bottom: 0;
        font-size: 32px;
        letter-spacing: 0.075rem !important;
        font-family: "MuliExtraBold";
        img {
          width: 180px;
        }
        a {
          font-size: 13px;
          display: none;
          align-items: center;
          margin-left: 20px;
          font-weight: normal;
          white-space: nowrap;
          text-transform: uppercase;
          color: #19acf7;
        }
      }
      p {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .pitch-card {
      font-size: 24px;
    }
    .center {
    }
    .center .slick-center .pitch-card {
      color: #e67e22;
      // transform: scale(1.03);
    }
    .center .pitch-card {
      // transform: scale(0.96);
      // transition: all 300ms ease;
    }
    .slick-arrow {
      width: 50px;
      height: 50px;
      top: 14vw;
      display: flex;
    }
    .slick-prev {
      left: 30px;
    }
    .slick-next {
      right: 30px;
    }
    .slick-arrow svg {
      width: 50px;
      height: 50px;
    }
  }
}
.pitch-planet-banner {
  display: flex;
  padding: 40px 0px 20px 0px;
  width: 100%;
  justify-content: center;
  position: relative;
  z-index: 50;
  img {
    width: 20vw;
    min-width: 150px;
    max-height: 200px;
    object-fit: contain;
    height: min-intrinsic;
  }
}

.pitch-carousel-skeleton {
  width: 100vw;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row nowrap;
  padding-top: 0px;
  z-index: 1;
  min-height: 500px;
  .referral {
    display: none;
  }
  .select-pitch-version {
    display: none;
  }
  & > div {
    position: relative;
    flex-basis: 40vw;
    flex-shrink: 0;
    height: calc(40vw * 0.6);
    background-color: rgba(28, 28, 28, 0.5);
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
  & > div:nth-child(1) {
    border-radius: 0px 20px 20px 0px;
    // transform: scale(0.9);
    .pitch-card-title,
    .pitch-card-text {
      display: none;
    }
  }
  & > div:nth-child(2) {
    border-radius: 20px 20px 20px 20px;
  }
  & > div:nth-child(3) {
    border-radius: 20px 0px 0px 20px;
    // transform: scale(0.9);
    .pitch-card-title,
    .pitch-card-text {
      display: none;
    }
  }
  @include media-breakpoint-up(xl) {
    min-height: 700px;
  }
  @include media-breakpoint-down(sm) {
    margin: -20px 0px 0px 0px;
    & > div {
      flex-basis: calc(100vw - 40px);
      height: calc((100vw - 40px) * 0.56);
    }
    & > div:nth-child(1) {
      margin-right: 5px;
      // transform: scale(1);
    }
    & > div:nth-child(2) {
    }
    & > div:nth-child(3) {
      margin-left: 5px;
      // transform: scale(1);
    }
  }
}
