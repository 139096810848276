.share {
	position: relative;

	@include media-breakpoint-down(xs) {
		// display: none !important
		margin-top: 20px;
	}

	&.multiple {
		margin-right: 10px;

	}

	.share-list {
		display: none;
		position: absolute;
		top: -180px;
		/* -300 with sms, mail, and whats */
		left: 50%;
		transform: translateX(-50%);
		width: 200px;
		background: $radivision-dk-gray;
		border-radius: 6px;
		border: 1px solid $radivision-blue;
		padding: 24px;
		padding-top: 32px;
		color: #fff;
		text-transform: uppercase;
		font-size: 12px;
		// letter-spacing: 1px;
		font-weight: bold;
		z-index: 1;
		letter-spacing: 0.075rem;

		@media (min-width: 2500px) {
			width: 230px;
		}

		&.visible {
			display: block;
			top: -300px;
			opacity: 0;
			animation: share-list-in 0.3s ease-out;
			animation-fill-mode: forwards;
			animation-direction: normal;
		}

		&:after {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			background: $radivision-dk-gray;
			border: 1px solid $radivision-blue;
			border-width: 0 1px 1px 0;
			bottom: -11px;
			left: calc(50% - 10px);
			position: absolute;
			z-index: 2;
			transform: rotate(45deg);
		}

		.close-btn {
			position: absolute;
			z-index: 2;
			cursor: pointer;
			width: 10px;
			height: 10px;
			top: 20px;
			right: 15px;

			@media (min-width: 2500px) {
				width: 15px;
				height: 15px;
			}

			path {
				fill: $gray;
				transition: all 0.3s;
			}

			&:hover {
				path {
					fill: $light-gray;
				}
			}
		}

		.rad-s-item {
			cursor: pointer;
			text-align: left;
			margin: 24px 0;
			transition: all 0.3s;
			color: #fff;
			outline: 0;

			&:last-child {
				margin-bottom: 0;
			}

			&:active,
			&:visited {
				outline: 0;
				border: 0
			}

			div {
				font-size: 12px;
				color: #fff;
				transition: all 0.3s;
				text-decoration: none;

				@media (min-width: 2500px) {
					font-size: 18px;
				}

				&:hover {
					text-decoration: none;
					color: $radivision-blue;
					border: 0;
					outline: none;
				}
			}

			svg {

				vertical-align: sub;
				margin-right: 15px;
				height: 18px;
				width: 18px;

				@media (min-width: 2500px) {
					width: 24px;
					height: 24px;
				}

				path {
					// fill: #fff;
					transition: all 0.3s;
				}
			}

			&:hover {
				color: #5AB5E8;

				path {
					fill: #5AB5E8;
				}
			}
		}

		.close-btn+.rad-s-item {
			margin-top: 0;
		}

		.share-count {
			color: $gray;
			margin-top: 16px;

			.rad-s-count {
				font-size: 36px;
				font-weight: normal;
				margin-bottom: 4px;
				display: block;
				line-height: 24px;
			}
		}
	}
}

@keyframes share-list-in {
	0% {
		opacity: 0;
		top: -180px;
		/* -300 with sms, mail, and whats */
	}

	100% {
		opacity: 1;
		top: -210px;
		/* -320 with sms, mail, and whats */
	}
}

.display_InlineBlock {
	display: inline-block;

	.share.multiple {
		button {
			margin-bottom: 0;
		}
	}

	.landingPages {
		display: flex
	}
}