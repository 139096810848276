/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2020-11-12
// Original author: Muhammad Omran
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////

.featured-filter-tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  min-height: 60px;

  @include media-breakpoint-down(lg) {
    margin-top: 0px;
  }

  @include media-breakpoint-down(md) {
    margin-top: -6%;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0px;
  }

  &.stickey {
    position: fixed;
    bottom: auto;
    margin-top: 0 !important;
    z-index: 98;
    background-color: rgba(31, 31, 31, 0.9);
    padding: 16px 0;

    @include media-breakpoint-down(sm) {
      background-color: rgba(31, 31, 31, 1);
      overflow: visible;

      &::after {
        display: block;
        content: "";
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgb(31, 31, 31);
        height: 21px;
        bottom: -5px;
      }
    }
  }

  & > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-down(lg) {
    bottom: 0;

    &.stickey {
      bottom: auto;
    }
  }

  @include media-breakpoint-down(md) {
    bottom: 0;
    margin-bottom: 30px;
  }

  @include media-breakpoint-down(sm) {
    bottom: 0;
    position: relative;
    overflow-y: hidden;

    & > div {
      overflow-x: scroll;
      margin-bottom: -17px;
      padding: 12px 15%;
      justify-content: flex-start;
      display: flex;
      
      &::-webkit-scrollbar {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 15%;
        height: 100%;
        z-index: 3;
      }

      &::before {
        left: 0;
        background-image: linear-gradient(90deg, $dark-mode-bg, transparent);
      }

      &::after {
        right: 0;
        background-image: linear-gradient(-90deg, $dark-mode-bg, transparent);
      }

      &.justify-content-start {
        padding-left: 0;
        
        &::before {
          content: none;
        }
      }
    }
  }

  .filter-item {
    padding: 14px 20px;
    font-size: 13px;
    font-family: "MuliBold";
    color: #fff;
    letter-spacing: 0.03em;
    line-height: 13px;
    background-color: rgba(255, 255, 255, 0.33);
    border-radius: 22px;
    margin: 0 5.5px;
    white-space: nowrap;
    cursor: pointer;

    &.with-icon {
      padding-top: 9px;
      padding-bottom: 9px;
      display: inline-block;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        padding-top: 10.5px;
        padding-bottom: 10.5px;
      }

      svg {
        height: 23px;
        width: auto;
        margin-right: 8px;
      }
    }

    &.active {
      background-color: #fff;
      color: #000;
      cursor: default;
    }
  }
}

body.mobile-browser {
  .featured-filter-tabs {
    @include media-breakpoint-down(sm) {
      &.stickey {
        margin-top: -1px !important;
      }

      & > div {
        margin-bottom: 0px;
      }
    }
  }
}
