#empowered-humanity {
  padding: 0px 0px 30px 0px;
  margin: 0px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-size: 100% auto;
  background-position: center center;
  position: relative;
  z-index: 1;
  background-image: url("../static/earth.png");
  height: 60vh;
  background-repeat: no-repeat;

  .shade {
    // background: linear-gradient(
    //   0deg,
    //   rgba(22, 22, 22, 1) 0%,
    //   rgba(0, 0, 0, 0.3) 30%,
    //   rgba(0, 0, 0, 0.3) 60%,
    //   rgba(22, 22, 22, 1) 100%
    // );
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }

  .north-star-sign {
    display: flex;
    position: relative;
    max-width: 90px;
    margin: 0px 0px 0px 0px;
    z-index: 1;
    transform: translateY(-50px);
  }

  .empowered-humanity-title {
    color: $radivision-blue;
    font-family: MuliSemiBold;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
    position: relative;
    z-index: 1;
    transform: translateY(-50px);
  }

  .empowered-humanity-description {
    position: relative;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
    margin: 0px 24px;
    text-align: center;
    font-size: 20px;
    z-index: 1;
    transform: translateY(-50px);
  }




  @media (min-width: 600px) {
    padding: 0px 0px 0px 0px;
    height: 35vh;

    .north-star-sign {}

    .empowered-humanity-title {
      color: $radivision-blue;
    }

    .empowered-humanity-description {}
  }

  @media (min-width: 1200px) {
    padding: 0px 0px 0px 0px;
    height: 50vh;

    .north-star-sign {
      img {
        max-width: 120px;
        margin: 0px 0px 0px 0px;
      }
    }

    .empowered-humanity-title {
      width: 80%;
      text-align: center;
      font-size: 32px;
      margin: 10px 0px 20px 0px;
      color: $radivision-blue;
    }

    .empowered-humanity-description {
      max-width: 1000px;
      font-size: 24px;
      text-align: center;
      font-family: Muli;
    }
  }

  @media (min-width: 1600px) {
    padding: 50px 0px 20px 0px;

    .north-star-sign {
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-120px);

      img {
        max-width: 160px;
        margin: 0px 0px 0px 0px;
      }
    }

    .empowered-humanity-title {
      max-width: 800px;
      font-size: 44px;
      margin: 10px 0px 30px 0px;
      transform: translateY(-95px);
      color: $radivision-blue;
    }

    .empowered-humanity-description {
      max-width: 900px;
      font-size: 32px;
      text-align: center;
      transform: translateY(-95px);
    }
  }
}

#our-vision {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% auto;
  background-position: center center;
  position: relative;

  .shade {
    // background: linear-gradient(
    //   0deg,
    //   rgba(22, 22, 22, 1) 0%,
    //   rgba(0, 0, 0, 0.3) 30%,
    //   rgba(0, 0, 0, 0.3) 60%,
    //   rgba(22, 22, 22, 1) 100%
    // );
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0px;
    position: relative;
    flex-flow: column;
    z-index: 1;
  }

  .thumbnail {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 90%;
      margin-bottom: 20px;
    }
  }

  .text {
    width: 100%;
    padding: 0px 24px 0px 24px;

    .section {
      h3 {
        color: $radivision-blue;
        font-size: 28px;
        font-family: MuliSemiBold;
      }

      p {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 600px) {
    .inner {
      flex-flow: row;
    }

    .thumbnail {
      width: 100%;
    }

    .text {
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .inner {
      margin: 0px 35px;
    }

    .thumbnail {
      width: 50%;

      img {
        width: 90%;
      }
    }

    .text {
      width: 50%;
      padding: 20px 30px;

      .section {
        margin-bottom: 40px;

        h3 {
          font-size: 26px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }

  @media (min-width: 1600px) {
    .inner {
      margin: 0px 35px;
    }

    .thumbnail {
      img {
        width: 85%;
      }
    }

    .text {
      padding: 20px 30px;

      .section {
        margin: 30px 0px 40px 0px;

        h3 {
          font-size: 38px;
        }

        p {
          font-size: 22px;
          line-height: 36px;
        }
      }
    }
  }
}

.members-banner {
  padding: 10px 0px 20px 0px;
  position: relative;
  // padding-bottom: 20%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  @media (min-width: 1200px) {
    background-size: 100% auto;
  }

  .members-banner-shade {
    display: block;
    opacity: 1;
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 80%);
  }
}

.member {
  text-align: center;
  font-family: "MuliBold";
  font-size: 29px;
  font-weight: 800;
  color: white;
  margin: 4rem 0px 6rem 0px;
  z-index: 1;
}

.members {
  display: flex;
  flex-basis: 50%;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: auto;

  .circle {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    font-size: 50px;
    color: black;
    text-align: center;
    background: white;
    margin-bottom: 20px;

    &:first-child {
      background: $radivision-blue;
      color: white;
    }

    p {
      height: 100%;
      width: 100%;
      margin: 0px;
      font-family: "MuliSemiBold";
      font-size: 15px;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      display: flex;

      img {
        width: 45%;
      }
    }
  }

  .experts {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 10%;
    }
  }
}

@media (min-width: 1200px) {
  .members {
    .circle {
      width: 150px;
      height: 150px;

      p {
        width: auto;
        font-size: 18px;
        padding: 10px;
        margin: 0px;
        line-height: 24px;
        white-space: normal;

        img {
          width: 65%;
        }
      }
    }

    .experts {
      img {
        width: 90%;
      }
    }
  }
}

@media (min-width: 1600px) {
  .members {
    .circle {
      width: 190px;
      height: 190px;

      p {
        font-size: 20px;
        line-height: 30px;

        img {
          width: 65%;
        }
      }
    }
  }
}

.bullet-points {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: auto;
  flex-flow: row wrap;

  .points {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    width: 40%;
    min-width: 300px;
    flex: 1;
    text-align: center;

    .title {
      background-color: $radivision-blue;
      border-radius: 40px;
      padding: 1rem;
      width: 100%;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: white;
      font-size: 16px;
      font-family: MuliSemiBold;
      align-items: stretch;

      span {
        text-align: left;
      }

      span.expand-icon {
        width: 20px;
        margin-left: 10px;
        display: flex;
        align-items: center;

        @media (max-width: 1200px) {
          margin-right: 3px;
        }
      }

      svg {
        fill: white;
        stroke: white;
        width: 20px;
        height: 20px;
        stroke: white;
        stroke-width: 3px;
        // transform: translate(-5px, 0) rotate(90deg);
      }
    }

    ul {
      margin-left: 4px;
      color: white;
      text-align: left;
      flex: 1;

      li {
        color: $radivision-blue;

        p {
          color: white;
        }
      }
    }

    &.expanded {
      .title {
        // svg {
        //   display: none;
        // }
      }
    }
  }
}

@media (min-width: 1200px) {
  .bullet-points {
    .points {
      flex-basis: 40%;
      flex-grow: 0;

      .title {
        font-size: 18px;
        min-width: 300px;

        svg {
          fill: white;
          stroke: white;
          stroke-width: 3px;
          width: 20px;
          height: 20px;
        }

        span.expand-icon {
          width: 40px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      ul {
        padding: 20px 20px 20px 60px;

        li {
          font-size: 18px;
          color: $radivision-blue;

          p {
            color: white;
          }
        }
      }
    }
  }
}

#values {
  .inner {
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: row wrap;
      margin: 0px 24px;

      .logo {
        width: 100%;
        max-width: auto;
        margin-bottom: 10px;

        img {
          max-width: 180px;
        }
      }

      .description {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }




    .values-row {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .flip-card {
        cursor: pointer;
        perspective: 1000px;
        min-height: 230px;
        width: calc(100% - 48px);
        margin: 5px 24px;
        border-radius: 6px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        position: relative;

        .flip-card-front {
          flex-flow: column;
          background-size: 100% 100%;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          padding: 20px 0px 0px 0px;

          .logo {
            margin: 60px 0px 20px 0px;
            max-width: 95%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              width: 100%;
            }
          }

          .description {
            font-family: Muli;
            flex-grow: 1;
            font-size: 15px;
            padding: 0px 10px 0px 10px;
            margin-top: 10px;
            text-align: center;
          }
        }

        .flip-card-inner {
          position: relative;
          flex: 1;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 1.2s ease-in;
          transform-style: preserve-3d;
        }

        &.is-hover .flip-card-inner {
          transition: transform 1.2s ease-out;
          transition-delay: 0.8s;
          transform: rotateY(180deg);
        }

        .flip-card-front,
        .flip-card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-backface-visibility: hidden;
          /* Safari */
          backface-visibility: hidden;
          position: absolute;
          top: 0px;
          right: 0px;
          left: 0px;
          bottom: 0px;
        }

        .flip-card-back {
          display: none;
          background-color: #161616;
          transform: rotateY(180deg);
          border: 3px solid $radivision-blue;
          border-radius: 5px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          padding: 10px;

          p {
            margin: 5px 0px 5px 0px;
            padding: 0px;
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (min-width: 500px) {
    .inner {
      padding: 20px 24px;

      .header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 15px 0px;
        flex-flow: row nowrap;

        .logo {
          width: 160px;
          max-width: 160px;
          margin-right: 35px;
        }

        .description {
          font-size: 15px;
          line-height: 12px;
        }
      }

      .values-row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-flow: row wrap;
        margin: -10px;

        .flip-card {
          width: calc(50% - 12px);
          flex-shrink: 0;
          min-height: 260px;
          flex-grow: 1;
          margin: 6px;
          flex-flow: column;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-size: 100% 100%;
          border-radius: 6px;

          .flip-card-front {
            padding: 10px 0px 0px 0px;

            .logo {
              max-width: 50%;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              img {
                width: 100%;
              }
            }

            .description {
              flex-grow: 1;
              font-size: 14px;
              padding: 0px 20px 0px 20px;
              margin-top: 15px;
              text-align: center;
            }
          }

          .flip-card-back {
            display: flex;
            padding: 10px;

            p {
              font-size: 13px;
            }
          }
        }
      }
    }


  }

  @media (min-width: 1200px) {
    .inner {
      padding: 20px 70px;

      .header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 15px 0px;
        flex-flow: row nowrap;

        .logo {
          width: 160px;
          max-width: 160px;
          margin-right: 35px;
        }

        .description {
          font-size: 15px;
          line-height: 12px;
        }
      }

      .values-row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-flow: row;
        margin: 0px;

        .flip-card {
          width: calc(25% - 12px);
          min-height: 240px;
          flex: 1;
          margin: 6px;
          flex-flow: column;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-size: 100% 100%;
          border-radius: 6px;

          .flip-card-front {
            padding: 10px 0px 0px 0px;

            .logo {
              max-width: 85%;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              img {
                width: 100%;
              }
            }

            .description {
              flex-grow: 1;
              font-size: 14px;
              padding: 0px 20px 0px 20px;
              margin-top: 15px;
              text-align: center;
            }
          }

          .flip-card-back {
            display: flex;
            padding: 10px;

            p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1600px) {
    .inner {
      margin: 0px 35px;

      .header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-flow: row nowrap;

        .logo {

          margin: 60px 0px 20px 0px;
          max-width: 95%;
          width: 180px;
          max-width: 180px;
          margin-right: 30px;
        }

        .description {
          font-size: 16px;
          line-height: 12px;
        }
      }

      .values-row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-flow: row;

        .flip-card {
          width: calc(25% - 40px);
          min-height: 290px;
          flex: 1;
          flex-flow: column;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-size: 100% 100%;
          border-radius: 6px;

          .flip-card-front {
            padding: 10px 0px 0px 0px;

            .logo {
              max-width: 85%;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              img {
                width: 100%;
              }
            }

            .description {
              flex-grow: 1;
              font-size: 17px;
              padding: 0px 35px 0px 35px;
              margin-top: 15px;
              text-align: center;
            }
          }

          .flip-card-back {
            padding: 10px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {



  #our-vision {
    .inner {
      padding-top: 0px;
      padding-bottom: px;

      .section {


        p {
          padding: 10px;
          font-size: 12px;
        }
      }
    }
  }

  #empowered-humanity {
    height: 100%;

    .north-star-sign {
      display: flex;
      position: relative;
      max-width: 50px;
      margin: 0px 0px 0px 0px;
      z-index: 1;
      transform: translateY(-20px);

      img {
        max-width: 60px;
        margin: 0px 0px 0px 0px;
      }
    }
  }

  #values {
    .inner {
      .values-row {
        .flip-card {
          .flip-card-front {
            .logo {
              // Override the larger-margin default
              margin: 20px 0px 20px 0px;

              // Or, if you want all sides to be 20px:
              // margin: 20px;

              // Set the width to 40%
              width: 40%;
            }
          }
        }
      }
    }
  }
}

#our-founder~.the-shows {
  display: none;
}

#our-founder {
  margin-top: 30px;

  h3 {
    width: 100%;
    font-size: 32px;
    color: $radivision-blue;
    text-align: center;
    margin: 40px 0px 10px 0px;
  }

  .inner {
    padding: 10px 24px 20px 24px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;

    .text {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      width: 100%;

      p {
        font-size: 20px;
      }
    }

    .photo {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  @media (min-width: 600px) {}

  @media (min-width: 1200px) {
    h3 {
      width: 100%;
      font-size: 32px;
      color: $radivision-blue;
      text-align: center;
      margin: 40px 0px 10px 0px;
    }

    .inner {
      padding: 25px 75px 50px 75px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;

      .text {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0px;
        width: 60%;

        p {
          font-size: 16px;
        }
      }

      .photo {
        width: 35%;

        img {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 1600px) {
    h3 {
      width: 100%;
      font-size: 36px;
      color: $radivision-blue;
      text-align: center;
      margin: 40px 0px 10px 0px;
    }

    .inner {
      padding: 25px 75px 50px 75px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .text {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        width: 60%;

        p {
          font-size: 21px;
          line-height: 40px;
        }
      }

      .photo {
        width: 35%;

        img {
          width: 100%;
        }
      }
    }
  }
}

#join-banner {
  height: 300px;
  display: flex;
  flex-flow: column;
  position: relative;
  justify-content: center;
  align-items: center;

  .gradient1 {
    background: linear-gradient(90deg,
        rgba(0, 229, 244, 1) 0%,
        rgba(15, 168, 216, 1) 35%,
        rgba(41, 61, 168, 1) 69%,
        rgba(56, 0, 140, 1) 100%);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  h2 {
    font-size: 22px;
    color: white;
    position: relative;
    z-index: 5;
    font-family: MuliSemiBold;
    margin: 0px 0px 40px 0px;
    text-align: center;
  }

  button {
    display: flex;
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    background-color: $radivision-blue;
    font-family: MuliSemiBold;
    width: 140px;
    height: 40px;
    color: white;
    border: 0;
    border-radius: 20px;
  }

  @media (min-width: 600px) {
    height: 400px;

    h2 {
      font-size: 32px;
    }
  }

  @media (min-width: 800px) {
    height: 300px;

    h2 {
      font-size: 38px;
    }

    button {
      width: 240px;
      font-size: 20px;
      font-family: MuliExtraBold;
      width: 140px;
      height: 40px;
      color: white;
      border: 0;
      border-radius: 20px;
    }
  }

  @media (min-width: 1600px) {
    height: 340px;

    h2 {
      font-size: 50px;
    }

    button {
      font-size: 25px;
      width: 180px;
      height: 50px;
      color: white;
      border: 0;
      border-radius: 25px;
    }
  }
}