.roundedCorner {
  background-color: rgba(22, 22, 22, 1);
  // background-color: #8c8c8c;
  width: 100%;

  .mini-hero-container {
    display: flex;
    align-items: center;
    position: relative;
    object-fit: cover;
    background-position: 100% 100%;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 40px 0 120px 0px;
    .profile-picture-name {
      .profile-picture {
         height: 48px;
         width: 48px;
       }
      .profile-name {
        font-size: 22px;
        font-family: MuliExtraBold;
        cursor: pointer;
      }
    }
  }

  .divShadow {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: 0 top;
    background-repeat: repeat-x;
    background-color: transparent;

    &.topShadow {
      bottom: -1px;
      background-image: linear-gradient(0deg, rgba(20, 20, 20, 1) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(20, 20, 20, 0.1) 29%, rgba(20, 20, 20, 0) 44%, rgba(20, 20, 20, 0.1) 70%, rgba(20, 20, 20, 0.9) 99%, rgba(20, 20, 20, 1) 100%);
    }
  }

  .miniContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: calc(100% - 175px);
    z-index: 9;

    .title-logo {
      margin-bottom: 27px;
      width: auto;
      max-width: 200px;
      height: auto;
    }

    & > h1 {
      font-size: 42px;
      font-family: "MuliExtraBold";
      letter-spacing: 0.075rem !important;
      margin-bottom: 24px;
      color: #fff;

      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }

    .hero-item {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    .videoShadow {
      position: relative;
      margin-right: 125px;
      border-radius: 16px;
      overflow: hidden;
      // background-color: rgba(123, 123, 123, 1);
      box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.34);
      width: 61.5%;
      img.hero {
        position: absolute;
        top:0px;
        right: 0px;
        left:0px;
        bottom: 0px;
      }

      .back {
        display: none;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-right: 35px;
        height: 100%;
        margin-bottom: 35px;
        background-color: transparent;
        flex-direction: column;
      }

      @include media-breakpoint-down(sm) {
        margin-right: auto;
      }

      @media (min-width: 800px) and (max-width: 1536px) {
        width: 63%;
        margin-right: auto;
        // height: 450px;
      }

      & > div {
        position: relative;
        width: 100%;
        overflow: hidden;

        /* 16:9 */
        &:before {
          display: block;
          content: "";
          padding-top: calc(100% / (16 / 9));
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          border-radius: 16px;
        }

        & .vid-bg {
          iframe {
            border-radius: 0;
          }

          .back {
            display: unset;
          }
        }

        iframe {
          border-radius: 16px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .info {
      width: 27.5%;


      img {
        width: 100%;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .profile-picture-name {
        margin-bottom: 20px;
      }

      p {
        font-family: "Muli";
        font-size: 18px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 0;

        @media (min-width: 2000px) {
          font-size: 20px;
        }

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }
      }

      button {
        margin-top: 30px;
        padding: 10px 30px;

        svg {
          width: 17px;
          height: 20px;
          vertical-align: sub;
          fill: white;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      @media (min-width: 800px) and (max-width: 1536px) {
        width: 32%;
        margin-left: 15px;
      }
    }

    @include media-breakpoint-down(md) {
      width: calc(100% - 70px);
      flex-direction: column;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      width: calc(100% - 70px);
      flex-direction: column;
    }

    @media (min-width: 1537px) {
      width: calc(100% - 330px);
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% - 70px);
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 32px;
  }

  // @include media-breakpoint-down(xs) {
  //   height: 700px;
  // }

  // @media (min-width: 800px) and (max-width: 1536px) {
  //   height: 675px;
  // }
  // @media (min-width:2000px) {
  //       height: 900px;
  // }
}
