.page-loading-progress {
  width: 100vw;
  position: relative;
  margin: 0px;
  box-sizing: border-box;
  height: 0px;
  z-index:10000;
  .loadingBar {
    position: absolute;
    top:0px;
    display: none;
    width: 100vw;
    height: 2px;
    background: #333333;
    overflow: hidden;
    z-index:10000;
    &.show {
      display: inline-block;
    }
  }
  .loadingBar::after {
    content: "";
    position: fixed;
    left: 0;
    width: 0;
    height: 4px;
    background: $radivision-blue;
    animation: loading infinite 5s;
    animation-iteration-count: 1;
  }
  @keyframes loading {
    0% {
      width: 0%;
    }
    20% {
      width: 20%;
    }
    40% {
      width: 40%;
    }
    60% {
      width: 60%;
    }
    80% {
      width: 80%;
    }
    90% {
      width: 90%;
    }
    100% {
      width: 100%;
      background: $radivision-blue;
    }
  }
}
