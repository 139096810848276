/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-19
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
// .my-media-tab {
//   @include media-breakpoint-down(lg) {
//     padding: 4% 2%;
//   }

//   @include media-breakpoint-down(sm) {
//     padding: 4% 6%;
//   }

//   .title {
//     font-weight: bold;
//     font-size: 11px;
//     max-width: 95%;

//     @media (min-width: 2000px) {
//       font-size: 18px;
//     }
//   }
//   .media-content {
//     & > h2.no-content {
//       margin-top: 72px;
//       margin-bottom: 32px;
//       font-size: 18px;
//       font-family: Muli;
//       text-align: center;
//       line-height: 24px;
//     }
//     & > h2 a.clickable {
//       color: $radivision-blue;
//       cursor: pointer;
//     }
//   }

//   h3.section-title {
//     font-family: MuliSemiBold;
//     font-size: 24px;
//   }

//   .padding-left-right {
//     padding-right: 15px;
//     padding-left: 0;

//     .card {
//       border: 1px solid #e2e2e2;
//       margin-bottom: 15px;

//       .card-body {
//         padding: 15px;
//       }

//       @include media-breakpoint-down(md) {
//         margin: 55px 30px;
//       }

//       @include media-breakpoint-down(sm) {
//         margin: 55px 0;
//       }
//     }
//   }

//   .add-buttons {
//     display: flex;
//     flex-flow: row wrap;
//     justify-content: space-evenly;
//   }

// }

// .bg-gray {
//   padding: 25px 70px 25px 25px;

//   .nav-tabs .nav-item .nav-link.active {
//     padding: 0.65rem 0;
//     max-height: 44.5px;
//   }

//   .nav-tabs .nav-item .nav-link {
//     @media (min-width: 2500px) {
//       padding: 1.2rem 0 !important;
//       max-height: 75.5px !important;
//     }
//   }

//   @media (min-width: 1282px) and (max-width: 1366px) {
//     padding: 25px 70px 25px 15px;
//   }

//   @media (min-width: 997px) and (max-width: 1280px) {
//     padding: 25px 35px 25px 15px;
//   }

//   .social-content {
//     margin-top: 0px;

//     .tab-pane {
//       h4 {
//         @media (min-width: 2500px) {
//           font-size: 1.25rem;
//         }
//       }
//     }
//   }
// }

// .padding-left-right {
//   padding-right: 15px;
//   padding-left: 0;
// }

// .auto-fill {
//   &:disabled {
//     background-color: #f0f0f0;
//     border-color: #d1d1db;
//     color: #32323b !important;
//     cursor: not-allowed;
//     opacity: 1;
//   }
// }

// @media (min-width: 768px) and (max-width: 800px) {
//   .mediaContainer {
//     flex: 0 0 62%;
//     max-width: 62%;
//   }

//   .bg-gray {
//     flex: 0 0 38%;
//     max-width: 38%;
//   }
// }

// @media (min-width: 996px) and (max-width: 1170px) {
//   .mediaContainer {
//     flex: 0 0 70%;
//     max-width: 70%;
//   }

//   .bg-gray {
//     flex: 0 0 30%;
//     max-width: 30%;
//   }
// }

.opportunities-container {
  width: 100%;
  .pitch-card-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-flow: column;
    & > div {
      margin: 0px;
      max-size: min-content;
    }
    .notify-me {
      button.btn-radivision {
        justify-content: center;
      }
    }
  }
  .owner.more-info,
  .notify-me {
    button.btn-radivision {
      background-image: none;
      background-color: #313131;
    }
  }
  .btn-radivision {
    margin: 5px 0px;
    justify-content: center;
  }
  span.is-promoted {
    width: 140px;
    margin-bottom: 5px !important;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 15px !important;
    svg {
      margin-right: 10px !important;
      fill: $radivision-blue !important;
    }
  }
  .dropdown-menu {
    background-color: #212121;
    a.dropdown-item {
      width: auto;
      display: flex;
      margin: 5px 10px;
      box-sizing: border-box;
      text-align: center;
      color: #ffffff;
      background-color: transparent;
      &:hover {
        color: $radivision-blue;
      }
      &.delete-item {
        color: #d00;
        &:hover {
          background-color: #d00;
          color: #ffffff;
        }
      }
    }
  }
  #pitch-more-menu-dropdown {
    cursor: pointer;
    &::after {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .pitch-card {
    align-items: center;
    .pitch-card-video {
      width: 70% !important;
    }
  }
  .opportunity {
    display: flex;
    //border-top: 1px solid #313131;
    padding: 30px 0px;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0px;
    @include media-breakpoint-down(sm) {
      flex-flow: column wrap;
      padding: 10px 0px;
      margin: 0px 0px;
      .opportunity-link {
        margin-top: 20px !important;
        button {
          margin-left: 0px !important;
        }
      }
    }

    .opportunity-body {
      flex: 1;
      h2 {
        font-size: 24px;
        margin: 0px 0px 20px 0px;
        font-family: MuliSemiBold;
        text-align: left;
        color: $radivision-blue;
      }
      p {
        max-width: 800px;
        font-size: 16px;
        margin: 0px 20px 0px 0px;
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        h2 {
          font-size: 18px;
          margin: 5px 0px;
        }
        p {
          font-size: 14px;
          color: #aaa;
          margin: 0px;
        }
      }
    }
    .opportunity-link {
      button {
        font-size: 14px;
        white-space: nowrap;
        padding: 8px 25px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        min-width: 170px;
      }
      svg {
        margin: 0px 0px 0px 10px;
        width: 15px;
        height: 15px;
        color: white;
        fill: white;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .opportunity-link {
      .pitch-card-actions {
        align-items: flex-start;
      }
    }
  }
}

.no-content-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  button.btn-radivision {
    padding: 7px 20px;
    font-size: 15px;
    flex-grow: 0;
  }
  & > h2.no-content {
    margin-top: 72px;
    margin-bottom: 32px;
    font-size: 18px;
    font-family: Muli;
    text-align: center;
    line-height: 24px;
  }
  & > h2 a.clickable {
    color: $radivision-blue;
    cursor: pointer;
  }
}
