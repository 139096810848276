/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2019-04-11
// author: Muhammad Omran
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.btn-radivision {
  padding: 14px 30px;
  color: $snow-white;
  border-radius: 29px;
  background-image: -webkit-linear-gradient(90deg,
      rgba(25, 172, 247, 1) 0%,
      rgba(76, 180, 231, 1) 100%);
  background-image: -moz-linear-gradient(90deg,
      rgba(25, 172, 247, 1) 0%,
      rgba(76, 180, 231, 1) 100%);
  background-image: -o-linear-gradient(90deg,
      rgba(25, 172, 247, 1) 0%,
      rgba(76, 180, 231, 1) 100%);
  background-image: linear-gradient(90deg,
      rgba(25, 172, 247, 1) 0%,
      rgba(76, 180, 231, 1) 100%);
  margin-bottom: 10px;
  margin-top: 20px;
  font-family: "Muli";
  font-size: 19px;
  font-weight: 800;
  letter-spacing: 0.03em;
  text-transform: none;
  position: relative;
  border: none;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    cursor: pointer;
    color: $snow-white;

    // background-color: rgba($radivision-blue, 0.8);
    // border-color: rgba($radivision-blue, 0.8);
    .hoverOverlay {
      opacity: 1;
    }

  }

  &:active:not(:disabled) {
    cursor: pointer;
    color: $snow-white;
    background-color: rgba($radivision-blue, 0.9);
    background-image: none;
    // border-color: rgba($radivision-blue, 0.9);
  }

  @media (min-width: 1300px) and (max-width: 1536px) {
    padding: 12px 15px;
  }

  // @media (max-width: 1366px) {
  //   font-size: 12px;
  // }

  // @media (min-width: 576px) and (max-width: 1290px) {
  //   // font-size: 11px;
  //   padding: 14px 30px;
  //   position: relative;
  // }

  // @media (min-width: 2000px) {
  //   font-size: 20px !important;
  //   padding: 18px 35px;
  // }

  @include media-breakpoint-down(xs) {
    padding: 10px 20px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  &.outline {
    background-color: transparent;
    color: white !important;

    // &.outline-light {
    //   color: $dark-gray;
    //   border-width: 2px;
    // }

    &:active:not(:disabled),
    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      color: $snow-white;

      // background-color: $radivision-blue;
      // border-color: $radivision-blue;
      .hoverOverlay {
        opacity: 1;
      }
    }

    &:disabled {
      border-color: $light-gray;
    }
  }

  // &.multiple {
  //   margin-right: 10px;

  //   @include media-breakpoint-down(sm) {
  //     // adjusting the margin for the stacking of multiple buttons
  //     margin-top: 5px;
  //     margin-bottom: 5px;
  //   }
  // }
}

.hoverOverlay {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  opacity: 0;
  top:0;
  left: 0;
  border-radius: 29px;
}
