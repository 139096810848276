/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-09-18
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
#my-saved-items {
  width: 70%;
  margin: 0 auto;

  @media (min-width:1025px) and (max-width:1280px) {
    width: 85%;
    padding: 35px;

  }

  @media (max-width:1024px) {
    width: 100%;
    padding: 35px;

  }

  @media (min-width:2000px) {
    width: 62%
  }

  @include media-breakpoint-down(md) {
    padding: 35px;
    width: 100%;
  }
}

.my-saved-items {
  padding: 0 70px;

  @include media-breakpoint-down(md) {
    padding: 0 35px;
  }


  // padding: 0% 3% 6% 24%;
  .row.grid-view {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    .story-icon:not(:empty)~* {
      margin-bottom: 3px !important;
    }

    .searchUx {
      flex: 0 0 calc(100% / 4);
      max-width: calc(100% / 4);
      margin-bottom: 20px;

      @media (max-width: 1024px) {
        flex: 0 0 calc(100% / 4);
        max-width: calc(100% / 4);
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .card-img-top {
        border: 1px solid #f0f0f0;
      }

      img {
        border: 0;
      }

      // width: calc(100% / 4) !important;

      @include media-breakpoint-down(sm) {
        flex: 100%;
        max-width: 100%;
      }

      .overlay {
        display: none;
      }
    }
  }

  .title {
    h3 {
      font-family: "MuliExtraBold";
      color: #fff;
      font-size: 46px;
      padding-top: 100px;
      font-weight: 800;
      margin-bottom: 38px;

      @include media-breakpoint-down(md) {
        padding-top: 2.5rem;
      }

      @media (min-width:2000px) {
        padding-top: 7rem;
      }
    }
  }

  .section-header {
    padding-top: 80px;
    .no-content-to-show {
      text-align: center;
      font-size: 21px;
      color: lightgrey;
      font-weight: 700;
    }

    @include media-breakpoint-down(md) {
      padding-top: 35px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }
}

#my-saved-items {
  @media (min-width:1025px) and (max-width:1280px) {
    padding: 0 20px !important;
  }
}