.profile-picture-name {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  .profile-picture {
    width: 36px;
    height: 36px;
    flex-shrink:0;
    border-radius: 50%;
    border: 2px solid white;
    margin-right: 10px;
    background-color: white;
    background-size: cover;
    background-position: center center;
    box-sizing: border-box;
    overflow: hidden;
  }
  .profile-name {
    color: white;
    hyphens: auto;
    font-size: 16px;
    span {
      text-align:left;
      display: block;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    em {
      display: block;
      font-style: normal;
      color: #cccccc;
      font-size:14px;
    }
  }
}
