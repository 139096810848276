.MuiContainer-root {
  @media (min-width: 0px) {
    max-width: 444px;
  }

  @media (min-width: 960px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 600px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
.invitation-conatiner {
  margin-bottom: 95px;
}
.makeStyles-paper-1 {
  display: flex;
  margin-top: 64px;
  align-items: center;
  flex-direction: column;
}
.makeStyles-avatar-2 {
  width: 180px;
  margin: 8px;
  background-color: #fff;
}
.MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: #bdbdbd;
}
.MuiAvatar-root {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.makeStyles-form-3 {
  width: 100%;
  margin-top: 24px;
}

.makeStyles-stepper-5 {
  padding: 24px 0px 40px;
}

.MuiStepper-horizontal {
  align-items: center;
  flex-direction: row;
}
.MuiStepper-root {
  display: flex;
  padding: 24px;
}
.MuiPaper-elevation0 {
  box-shadow: none;
}
.MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.btn-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 24px 0px 16px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  background: #3f51b5;
  width: 100%;
  height: 36px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

  .hoverOverlay {
    display: none;
  }
}
.stepper {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  li.step {
    padding: 0 8px;

    span.step {
      font-size: 12px;
      background: rgba(0, 0, 0, 0.54);
      padding: 4px;
      margin-right: 15px;
      border-radius: 50%;
      color: $snow-white;
      width: 24px;
      height: 24px;
      display: inline-block;
      text-align: center;
      line-height: 16px;

      svg {
        fill: $snow-white;
        width: 16px;
        height: 16px;
        vertical-align: bottom;
      }
    }

    span.step-label {
      color: #73859f;
    }

    &.active {
      span.step {
        background: $radivision-blue;
      }

      span.step-label {
        color: #ffffff;
      }
    }
  }
}
.invitation-section {
  width: 100%;
  max-width: 900px;
  color: #fff;

  input::placeholder {
    font-size: 14px;
  }

  input:-ms-input-placeholder {
    font-size: 14px;
  }

  input::-ms-input-placeholder {
    font-size: 14px;
  }

  input[type="checkbox"] {
    display: inline;
    margin-right: 8px;
    height: auto;

    & + label {
      margin-bottom: 0;
    }

    & + span {
      font-size: 14px;
      font-weight: bold;
    }
  }

  p.password-instructions {
    img {
      margin-top: -3px;
      filter: invert(1);
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      line-height: 15px;
    }
  }
  
  span {
    color: #fff;
  }
  span.required {
    color: #dc3545;
  }
  label {
    color: #fff;
  }

  .has-float-label label,
  .has-float-label > span {
    color: rgba(0, 0, 0, 0.87);
  }

  .has-float-label .form-control:placeholder-shown:focus + * {
    background-color: #c0c0c0;
    display: block;
    z-index: 1;
  }
}
