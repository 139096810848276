/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-08-28
// Original author: W. Alex Livesley
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.scroll-to-top-button {
  border: none;
  bottom: 1.5rem;
  cursor: pointer;
  outline: none;
  padding: 0;
  position: fixed;
  right: -1%;
  transform: scale(1, -1);
  z-index: 99;
  transition: all .3s;
  visibility: hidden;
  opacity: 0;
  background-color: white;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 10px 1px #00000033;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 2500px) {
    width: 75px;
    height: 75px;
  }

  &.visible {
    opacity: 1;
    right: 1rem;

    @media (min-width: 2500px) {
      right: 2rem;
    }
  }

  &:hover {
    background-color: #4cb4e7;

    svg {
      fill: #fff;
    }
  }

  svg {
    fill: $radivision-blue;
    width: 1.5rem;
    margin-top: 4px;

    @media (min-width: 2500px) {
      width: 2rem;
    }

  }



  @include media-breakpoint-up(md) {
    bottom: 2rem;

  }

  @include media-breakpoint-up(lg) {
    bottom: 2rem;
  }

  @include media-breakpoint-up(xl) {}
}