.on-control {
  background-color: #46464e;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;

  @include media-breakpoint-down(sm) {
    // padding-left: 8px;
    // padding-right: 30px;
    padding: 30px 0;
    padding-bottom: 20px;
  }

  .marketing-item {
    &:hover {
      svg {
        fill: #fff;
      }

      h6 {
        color: #fff;
      }
    }
  }

  .action {
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      background-color: #3c3c45;
      border-radius: 8px;
      // margin-bottom: -52px;
      padding: 15px 3px;
      // margin-right: -22px;
    }
  }

  .on-star {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    img {
      padding-top: 0.5rem;
      width: 150px;
      padding-bottom: 1rem;
      cursor: pointer;
    }
  }

  .row {
    @include media-breakpoint-down(sm) {
      // margin-top: -65px;
      // margin-bottom: 33px;
      margin: 0;
    }

    // .col-6 {
    //   div {}
    // }
  }

  .cls-1 {
    fill: #a6a8ab;
  }

  .cls-2 {
    fill: #4fb4e6;
  }

  .cls-3 {
    fill: #fff;
  }

  .padding-top {
    padding-top: 60px;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      /* height: 65px; */
      padding-bottom: 10px;
    }
  }

  .intro-text {
    // letter-spacing: 0.8px;
    max-width: 700px;
    margin: auto;

    @media (min-width: 2000px) {
      font-size: 20px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 0.9rem;
    // letter-spacing: 1.5px;
    margin-top: 0;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      // letter-spacing: 1px;
    }
  }

  .sub-title {
    font-weight: 700;
    color: $radivision-blue;
    font-size: 0.8rem;

    @include media-breakpoint-down(sm) {
      font-size: 0.6rem;
      margin-bottom: 0;
    }
  }

  svg {
    fill: $radivision-blue;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 85px;
    transform: scale(1) rotate(0deg);
    transition: all .3s;
  }

  .sub-svg {
    top: 12px;
    height: 43px;

    @include media-breakpoint-down(sm) {
      width: 30px;
      position: inherit;
      float: left;
      margin-left: 16px;
      // height: auto;
    }
  }
}

.navigationBar {
  background-color: #46464e;
  color: white;
  padding: 15px 70px;
  margin-bottom: 35px;
  padding-bottom: 11px;

  @include media-breakpoint-down(md) {
    padding: 0px 35px;
    margin-bottom: 30px;

  }

  @include media-breakpoint-down(sm) {
    padding: 0 35px;
    margin-bottom: 30px;
  }

  .navigationIcons {
    // width: 75%;
    display: flex;
    flex-direction: row;
    // align-items: center;
    text-align: center;
    margin: 0 auto;

    .navigationText {
      // width: calc(100% / 6);
      vertical-align: top;

      @include media-breakpoint-down(md) {
        width: 25%;
        margin: 8px 10px;
        display: inline-block;
        text-align: center;
        vertical-align: top;
      }

      @include media-breakpoint-down(sm) {
        width: 70%;
        margin: 8px 0;
        display: inline-block;
        text-align: center;
        vertical-align: top;
      }

      .action {
        width: 100%;
        margin: 0;


        img {
          // height: 55px;
          vertical-align: top;
          margin-bottom: 10px;

          @media (min-width:1921px) {
            height: 55px;
          }

          @media (min-width:1537px) and (max-width:1920px) {
            height: 37px;
          }

          @media (min-width:1366px) and (max-width:1536px) {
            height: 32px;
          }

          @media (min-width:768px) and (max-width:1365px) {
            height: 28px;
          }

          @include media-breakpoint-down(md) {
            height: 28px;
          }

        }

        .title {
          font-size: 15px;
          font-weight: unset;
          // line-height: 10px;
          margin-bottom: 0;
          margin-top: 0px;

          @media (min-width:2500px) {
            font-size: 1.6rem;
          }

          @media (min-width:1366px) and (max-width:1540px) {
            font-size: 13px;
          }

          @media (min-width:768px) and (max-width:1365px) {
            font-size: 11px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 12px;
          }
        }

        .subTitle {
          font-size: 15px;
          font-weight: unset;
          line-height: normal;
          margin-bottom: 0;

          @media (min-width:2500px) {
            font-size: 1.6rem;
            // letter-spacing: -1px;
          }

          @media (min-width:1366px) and (max-width:1540px) {
            font-size: 13px;
          }

          @media (min-width:768px) and (max-width:1365px) {
            font-size: 11px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 12px;
          }
        }
      }
    }

    & :hover {
      cursor: pointer;
    }

    @media (min-width:2500px) {
      width: 90%;
    }

    @media (max-width: 1280px) and (min-width: 769px) {
      width: 66% !important;
    }

    @media (min-width:768px) and (max-width:800px) {
      width: 100% !important;
    }

    @media (max-width: 1170px) and (min-width: 1024px) {
      width: 75% !important;
    }

    @media (min-width:1024px) and (max-width:1365px) {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      display: block;
      margin: 0 auto;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}

.navigationIcons::-webkit-scrollbar {
  display: none;
}

.navigationIcons::-webkit-scrollbar-thumb {
  display: none;

}

.sixContainer {
  width: 75%;

  @media (min-width:1366px) and (max-width:1441px) {
    width: 82%;
  }

  .sixColums {
    width: calc(100% / 6);
  }
}

.threeContainer {
  width: 60%;


  @media (min-width: 1440px) and (max-width: 1440px) {
    width: 65%;
  }

  @media (max-width: 1280px) and (min-width: 769px) {
    width: 70%;
  }

  @include media-breakpoint-down(md) {
    width: 80%;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  .threeColums {
    width: calc(100% / 3);
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 25px;

    .action {
      width: 100%;
      margin: 0;


      img {
        // height: 55px;
        vertical-align: top;
        margin-bottom: 15px !important;

        @media (min-width:1921px) {
          height: 80px !important;
        }

        @media (min-width:1537px) and (max-width:1920px) {
          height: 55px !important;
        }


        @media (min-width:1366px) and (max-width:1536px) {
          height: 40px !important;
        }

        @media (min-width:768px) and (max-width:1365px) {
          height: 33px !important;
        }

        @include media-breakpoint-down(md) {
          height: 30px !important;
        }
      }

      .title {
        font-size: 22px !important;
        white-space: pre;
        line-height: normal !important;

        @media (min-width:1921px) {
          font-size: 2.1rem !important;

        }

        @media (width: 1536px) {
          font-size: 19px !important;
        }

        @media (max-width: 1440px) and (min-width:1360px) {
          font-size: 17px !important;
        }



        @media (max-width: 1280px) and (min-width: 768px) {
          font-size: 17px !important;
          // width: 100%;
          text-align: center !important;
        }

        @media (max-width: 1170px) and (min-width: 768px) {
          font-size: 15px !important;
        }

        @include media-breakpoint-down(md) {
          font-size: 14px !important;
          width: 100%;
          text-align: center !important;
        }

        @include media-breakpoint-down(xs) {
          font-size: 13px !important;
          width: 100%;
          line-height: 1.7;
        }
      }
    }
  }
}