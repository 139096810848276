/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2020-12-01
// Original author: Muhammad Omran
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.marketing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  background-color: $dark-mode-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  touch-action: none;

  .marketing-panel-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;

    img {
      width: 100vw;
      height: 100vh;
      height: -webkit-fill-available;
      object-fit: cover;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100vh;
      height: -webkit-fill-available;
      background-color: rgba(7, 7, 7, 0.88);
    }
  }

  .start-adventure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .radivision {
      max-width: 662px;
      width: 80%;
      height: auto;
      margin-bottom: 70px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 37px;
      }
    }

    h3 {
      font-family: "MuliBold";
      font-size: 32px;
      color: #fff;
      text-align: center;
      line-height: 28px;
      white-space: nowrap;
      margin-bottom: 36px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
        margin-bottom: 22px;
      }
    }

    p {
      max-width: 870px;
      width: 95%;
      font-family: "MuliSemiBold";
      font-size: 22px;
      color: #fff;
      text-align: center;
      line-height: 28px;
      margin-bottom: 36px;

      &+p {
        margin-bottom: 16px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 22px;
  
        &+p {
          margin-bottom: 16px;
        }
      }
    }

    .btn-radivision {
      padding: 18px 79px;
      margin: 0;
      border-radius: 45px;
      text-transform: none;
      font-family: "MuliExtraBold";
      font-size: 24px;
      line-height: 24px;

      @include media-breakpoint-down(sm) {
        padding: 9px 64px;
        font-size: 16px;
        line-height: 16x;
        border-radius: 22px;
      }
    }
  }
}