/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-09
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
#report-bug {
  // padding: 0% 18% 3% 18%;
  position: relative;
  width: 70%;
  margin: 0 auto;

  @media (max-width:1280px) {
    width: 85%;
  }

  @media (max-width:1024px) {
    width: 100%;
    padding: 35px;

  }

  @media (min-width:2000px) {
    width: 62%
  }

  @include media-breakpoint-down(md) {
    padding: 35px;
    width: 100%;
  }

  h1 {
    font-size: 2rem;
    padding-top: 5rem;

    @media (min-width:1024px) and (max-width:1280px) {
      font-size: 24px;
    }

    @include media-breakpoint-down(md) {
      font-size: 26px;
      padding-top: 2rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      padding-top: 2rem;
    }
  }

  h4 {
    font-size: 16pt;

    @media (min-width:1024px) and (max-width:1280px) {
      font-size: 12pt;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.1rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.9rem;
    }

    &::after {
      display: block;
      content: "";
      height: 0.2rem;
      background-color: #edeaea;
      margin: 3% 0%;
    }
  }

  form {
    width: 100%;
    text-align: left;

    .title {
      margin-bottom: 30px;

      @media (min-width:1024px) and (max-width:1280px) {
        font-size: 24px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 22px;
        font-size: 26px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 12pt;
        margin-bottom: 20px;
      }
    }

    .required-text {
      margin-bottom: 15px;
    }

    .required-star {
      color: red;
      position: relative;
      top: 1px;
      left: 1px;
    }


    textarea {
      // border-left: none;
      min-height: 6rem;

      &:focus {
        box-shadow: none;
      }
    }

    button {
      width: 100%;
    }

    .issue-types {
      margin-bottom: 2%;
      font-weight: 700;
      font-size: 10pt;

      input {
        margin-right: 10px;
        margin-bottom: 0.6rem;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }

    #device-type-label {
      position: relative;
      top: 20px;
      left: 13px;
      background-color: white;
      padding-left: 3px;
      padding-right: 10px;
      font-weight: 200;
    }

    .show {
      position: relative;
      top: -30px;
      display: block;
      color: red;
      font-weight: bold;
      font-style: italic;
      font-size: 11pt;
      margin-bottom: 20px;
    }

    .hide {
      display: none;
    }

    .row {
      position: relative;
      top: -20px;
    }

    #report-bug-email-label,
    #report-bug-name {
      position: relative;
      bottom: 62px;
      left: 13px;
      background-color: white;
      padding-left: 3px;
      padding-right: 10px;
      font-weight: 200;
    }

    .email-input {
      @include media-breakpoint-down(sm) {
        position: relative;
        top: -25px;
      }

      #report-bug-email-label {
        padding-right: 20px;
      }

      span {
        color: red;
        font-size: 21px;
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 5px;
      }
    }

    button {
      width: 100%;
    }
  }

  .right-side {
    .right-side-content {
      border-left: 6px solid #edeaea;
      border-left-width: 3px;
      margin-left: 175px;
      padding-left: 36px;

      @include media-breakpoint-down(lg) {
        margin-left: 100px;
      }

      @include media-breakpoint-down(md) {
        margin-left: 50px;
      }

      @include media-breakpoint-down(sm) {
        border-left: none;
        margin-left: -35px;
        padding-top: 20px;
        // margin-top: -25px;
      }

      div {
        margin-bottom: 30px;

        h3 {
          margin-bottom: 3%;
          font-size: 12pt;
          font-weight: 900;

          @include media-breakpoint-down(sm) {
            font-size: 11pt;
          }
        }

        a {
          color: #4cb4e7;
          font-weight: 800;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .support-email {
        color: $radivision-blue;
        text-decoration: underline;
      }

      .or {
        margin-top: -5px;
        margin-bottom: 4px;
      }
    }
  }
}