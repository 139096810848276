#onPanel {
  text-align: center;
  padding-top: 0px;
  height: 277px;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // background-color: #8c8c8c;
  background-color: rgba(22, 22, 22, 1);

  @include media-breakpoint-down(sm) {
    padding: 35px !important;
    height: 350px;
  }

  h3 {
    font-family: 'MuliBold';
    font-size: 36px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    text-align: center;

    @media (min-width:2000px) {
      font-size: 48px;
    }
  }

  p {
    font-family: 'Muli';
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    margin-bottom: 40px;

    @media (min-width:2000px) {
      font-size: 22px;
    }
  }

  form {
    position: relative;

    button {
      position: absolute;
      right: 0;
      margin: 0;
      width: 190px;
      border-radius: 29px;
      font-family: 'Muli';
      font-size: 19px;
      font-weight: 800;
      color: rgba(255, 255, 255, 1);
      letter-spacing: 0.03em;
      border: 0;
      height: 57px;
      width: 190px;
      text-transform: capitalize;
      background-image: -webkit-linear-gradient(90deg, rgba(25, 172, 247, 1) 0%, rgba(76, 180, 231, 1) 100%);
      background-image: -moz-linear-gradient(90deg, rgba(25, 172, 247, 1) 0%, rgba(76, 180, 231, 1) 100%);
      background-image: -o-linear-gradient(90deg, rgba(25, 172, 247, 1) 0%, rgba(76, 180, 231, 1) 100%);
      background-image: linear-gradient(90deg, rgba(25, 172, 247, 1) 0%, rgba(76, 180, 231, 1) 100%);

      @media (min-width:2000px) {
        height: 60px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        position: relative;
      }
    }

    input {
      border: 1px solid #c2c2c2;
      border-radius: 6px;
      padding-left: 6px;
      height: 57px;
      width: 488px;
      background: transparent;
      border-radius: 29px;
      padding-left: 30px;
      color: white;
      font-family: 'Muli';
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      letter-spacing: 0.03em;

      // color: #c2c2c2;
      @media (min-width:2000px) {
        width: 600px;
        height: 60px;
        font-size: 18px;

      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .msg-alert {
      position: absolute;
      // color: #721c24;
      // background-color: #f8d7da;
      // border-color: #f5c6cb;
      width: 100%;
      color: black;
      text-align: center;
      top: 75px;
      padding: 15px;
      border-radius: 29px;

      @include media-breakpoint-down(sm) {
        top: 130px;
      }

      p {
        margin: 0;

      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      flex-direction: column !important;
    }
  }
}