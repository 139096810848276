/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-09-03
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////

.cover-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .iframe {
    width: 100%;
    border: 0;

    @media (min-width: 1025px) {
      height: 91vh;
    }

    @media (max-width: 1024px) {
      height: 100%;
      min-height: 70vh;
    }
  }

  @media (min-width: 1025px) {
    max-height: 91vh;
  }

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  @include media-breakpoint-down(sm) {
    height: 100%;
  }

  .video {
    iframe {
      width: 100%;
      height: 100%;

      @media (min-width: 1024px) {
        height: 91vh;
      }
    }

    .back {
      z-index: 9999;
    }
  }

  video {
    width: 100%;
    height: 100%;
    position: unset !important;
    object-fit: cover;

    @media (min-width: 1024px) {
      max-height: 91vh;
    }
  }

  .video-js .vjs-big-play-button {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .btn-radivision.about-video {
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin: 0 auto;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0px solid #fff;
    background: none;

    svg {
      width: 85px;
      height: 85px;
      vertical-align: middle;
      text-align: center;

      @include media-breakpoint-down(sm) {
        width: 65px;
        height: 65px;
      }
    }

    .playSvg {
      .cls-1 {
        fill: none;
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 2px;
      }

      .cls-2 {
        fill: #4cb4e7;
      }
    }
  }

  .about-video:focus:not(:disabled),
  .about-video:hover:not(:disabled),
  .about-video:active:not(:disabled) {
    background-color: transparent;
    background-image: none;
    border-color: transparent;
  }

  .pauseSvg {

    .cls-1,
    .cls-2 {
      fill: none;
      stroke-miterlimit: 10;
    }

    .cls-1 {
      stroke: #fff;
      stroke-width: 2px;
    }

    .cls-2 {
      stroke: #4cb4e7;
      stroke-linecap: round;
      stroke-width: 7px;
    }
  }

  .volume-control {
    cursor: pointer;
    max-width: 60px;
    position: absolute;
    right: 0;
    top: auto;
    bottom: 0;
    width: auto;
    height: auto;
    border-radius: 50%;
    position: absolute;
    margin: 0 auto;
    padding: 0;
    transform: translate(-50%, -70%);
    border: 0px solid #fff;
    background: none;

    @include media-breakpoint-down(sm) {
      max-width: 50px;
    }
  }
}

.text-content {
  h3 {
    font-family: "Muli";
    font-size: 30px;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);

    @media (min-width: 1921px) {
      font-size: 40px;
    }

    @media (min-width: 1024px) and (max-width: 1440px) {
      font-size: 28px;
    }

    @include media-breakpoint-down(md) {
      font-size: 22px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }

    span {
      color: rgba(79, 180, 230, 1);
    }
  }

  .divContent {
    padding: 110px;
    margin: 0;

    @media (min-width: 1024px) and (max-width: 1440px) {
      padding: 35px 70px;
    }

    @media (max-width: 1024px) {
      padding: 35px;
    }

    div {
      padding: 0;
      padding-right: 110px;

      p {
        margin-top: 30px;
        text-align: left;
        line-height: normal;
        font-family: "Muli";
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        display: inline-block;

        @media (min-width: 2000px) {
          font-size: 20px;
        }

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }

      @media (min-width: 2000px) {
        padding-right: 125px;
      }

      @media (min-width: 1024px) and (max-width: 1440px) {
        padding-right: 70px;
      }

      @media (min-width: 992px) and (max-width: 1366px) {
        padding-right: 15px !important;
      }

      @include media-breakpoint-down(md) {
        padding-right: 35px;
      }

      @include media-breakpoint-down(sm) {
        padding-right: 0 !important;
      }

      &:last-child {
        padding-right: 45px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}

.from-the-founder {
  text-align: left;
  padding: 3% 20%;

  @media (min-width: 768px) and (max-width: 1359px) {
    padding: 35px;
  }

  @include media-breakpoint-down(md) {
    padding: 35px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 10pt;
  }

  img {
    float: right;
    width: 315px;
    margin-left: 30px;

    @include media-breakpoint-down(md) {
      width: 180px;
    }

    @include media-breakpoint-down(sm) {
      width: 150px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 18pt;

    @include media-breakpoint-down(sm) {
      font-size: 12pt;
      white-space: nowrap;
    }
  }

  .signature {
    h2 {
      font-size: 14pt;

      @include media-breakpoint-down(sm) {
        font-size: 12pt;
      }
    }

    h4 {
      font-size: 12pt;

      @include media-breakpoint-down(sm) {
        font-size: 10pt;
      }
    }
  }
}

.north-star-vision {
  padding: 70px 110px;

  &.addbackground {
    background: url("./../static/star_strip.png") no-repeat center;

    @media (min-width: 2000px) {
      background-size: 100%;
    }
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    padding: 35px 70px;
  }

  @media (max-width: 1024px) {
    padding: 0 35px;
  }

  h3 {
    font-family: "Muli";
    font-size: 55px;
    font-weight: 800;
    color: $radivision-blue;
    margin-bottom: 30px;

    @media (min-width: 992px) and (max-width: 1399px) {
      font-size: 35px;
    }

    @include media-breakpoint-down(md) {
      font-size: 38px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 25px;
    }
  }

  p {
    font-family: "Muli";
    font-size: 16px;
    color: rgba(255, 255, 255, 1);

    @media (min-width: 2000px) {
      font-size: 20px;
    }
  }

  .removeMargin {
    margin: 0;

    .northStar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 35px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      @media (max-width: 1024px) {
        padding: 35px 0;
      }

      .info {
        width: 56%;
        padding: 35px 64px;
        padding-right: 25px;

        @media (min-width: 992px) and (max-width: 1536px) {
          width: 68%;
        }

        @include media-breakpoint-down(md) {
          width: 70%;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          padding: 35px 0;
        }
      }

      .northStarSvg {
        width: 112px;

        .st0 {
          fill: #4cb4e7;
        }

        .st1 {
          fill: #ffffff;
        }

        .st2 {
          fill: #a7a9ac;
        }
      }
    }

    .vision {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding: 0px;
      }

      svg {
        display: none;
        width: 112px;

        @include media-breakpoint-down(md) {
          display: unset;
        }
      }

      .ourVision {
        width: 112px;

        .st0 {
          fill: #4cb4e7;
        }

        .st1 {
          fill: #ffffff;
        }

        .st2 {
          fill: #a7a9ac;
        }
      }

      .visionImage {
        max-width: 745px;

        @include media-breakpoint-down(md) {
          display: none;
        }

        @media (min-width: 2000px) {
          max-width: 45%;
        }

        @media (min-width: 992px) and (max-width: 1366px) {
          max-width: 52%;
        }
      }

      .info {
        width: 36%;
        padding: 35px 70px;
        padding-right: 25px;

        @media (min-width: 992px) and (max-width: 1536px) {
          width: 42%;
        }

        @include media-breakpoint-down(md) {
          width: 80%;
          padding-right: 0;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          padding: 35px 0;
        }
      }
    }
  }
}

.team {
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  padding-right: 100px;

  @media (min-width: 1024px) and (max-width: 1440px) {
    padding: 35px 95px;
    // padding-right: 60px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 25px;
    padding-bottom: 0;
    // padding-right: 25px;
  }

  @include media-breakpoint-down(sm) {
    padding: 25px;
    padding-bottom: 0;
  }

  .col-3 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .col-4 {
    padding-left: 2px;
    padding-right: 2px;
    margin-bottom: 15px;
  }

  .name {
    font-size: 18px;
    font-family: "MuliBold";
  }

  .title {
    font-family: "MuliBold";
    font-size: 16px;
  }

  .name,
  .title,
  .linkedProfile {
    padding-left: 0;
  }

  h3 {
    font-family: "Muli";
    font-size: 32px;
    font-weight: 800;
    color: $radivision-blue;
    margin-bottom: 3.5rem;

    @media (min-width: 1921px) {
      font-size: 60px;
    }

    @media (min-width: 1024px) and (max-width: 1440px) {
      margin-bottom: 3rem;
    }
  }

  .advisor-intro {
    p {
      font-size: 22px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      padding: 0px 0px;
      margin-top: -50px;
      margin-bottom: 0px;
    }

    ul {
      list-style: none;
      margin: 30px 0px 30px 0px;
      padding: 0px;
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-items: center;

      &.group1 {
        padding: 0px 0px;
        margin: 30px 0px 0px 0px;
      }

      &.group2 {
        padding: 0px 0px;
        margin: 0px 0px 30px 0px;
      }

      li {
        margin: 0px;
        padding: 0px;
        list-style: none;
        margin: 10px 10px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 80px;
          max-height: 40px;

          &#disney {}

          &#apple {
            max-width: 50px;
          }

          &#morgan-stanley {
            width: 110px;
            max-width: 110px;
          }

          &#envestnet {
            width: 120px;
            max-width: 120px;
          }

          &#tribune {
            margin-top: 5px;
          }

          &#trinet {
            transform: translateY(-10px);
          }
        }
      }
    }

    @media (min-width: 600px) {}

    @media (min-width: 800px) {
      p {
        font-size: 24px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        padding: 0px 40px;
        margin-top: -30px;
        margin-bottom: 30px;
      }

      ul {
        list-style: none;
        margin: 0px 120px 70px 120px;
        padding: 0px;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: center;

        &.group1 {
          padding: 0px 0px;
          margin-bottom: 0px;
        }

        li {
          margin: 0px;
          padding: 0px;
          list-style: none;
          margin: 10px 20px;
          width: auto;

          img {
            max-width: 120px;
            max-height: 70px;

            &#microsoft {
              max-width: 120px;
              width: 120px;
            }

            &#amazon {
              max-width: 90px;
            }

            &#disney {}

            &#apple {
              max-width: 40px;
            }

            &#morgan-stanley {
              max-width: 180px;
              width: 180px;
            }

            &#gilead {
              max-width: 150px;
            }

            &#tribune {
              margin-top: 5px;
              max-width: 130px;
            }

            &#envestnet {
              max-width: 150px;
              width: 150px;
            }

            &#f {
              max-width: 40px;
            }

            &#truebill {
              max-width: 130px;
            }

            &#sony {
              transform: translateY(5px);
            }

            &#trinet {
              transform: translateY(-10px);
            }

            &#goldman-sacks {
              max-width: 100px;
            }

            &#pendry {
              max-width: 100px;
            }

            &#gracenote {
              max-width: 100px;
            }
          }
        }
      }
    }

    @media (min-width: 1600px) {
      p {
        font-size: 32px;
        color: $radivision-blue;
        text-align: center;
        padding: 0px 70px;
        margin-top: -30px;
        margin-bottom: 30px;
      }

      ul {
        list-style: none;
        margin: 0px 120px 70px 120px;
        padding: 0px;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: center;

        &.group1 {
          padding: 0px 80px;
          margin-bottom: 30px;
        }

        li {
          margin: 0px;
          padding: 0px;
          list-style: none;
          margin: 10px 30px;

          img {
            max-width: 240px;
            max-height: 70px;

            &#microsoft {
              max-width: 160px;
              width: 120px;
            }

            &#amazon {
              max-width: 150px;
            }

            &#goldman-sacks {
              max-width: 120px;
            }

            &#pendry {
              max-width: 180px;
            }

            &#gracenote {
              max-width: 180px;
            }

            &#disney {}

            &#apple {
              max-width: 50px;
            }

            &#morgan-stanley {
              max-width: 300px;
              width: 300px;
            }

            &#gilead {
              max-width: 150px;
            }

            &#tribune {
              margin-top: 5px;
              max-width: 180px;
            }

            &#envestnet {
              max-width: 220px;
              width: 220px;
            }

            &#f {
              max-width: 40px;
            }

            &#truebill {
              max-width: 180px;
            }

            &#sony {
              transform: translateY(5px);
            }

            &#trinet {
              transform: translateY(-10px);
            }
          }
        }
      }
    }
  }

  .img-thumbnail {
    border: none;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;

    .profileImage {
      width: 100%;
      background-color: #161616;
      border: 3px solid rgba(1, 161, 245, 1);
      border-radius: 50%;
      padding: 8px;

      &.clickable {
        cursor: pointer;
      }
    }

    figcaption {
      align-items: flex-start;
      justify-content: center;
      display: flex;

      &.title {
        display: flex;
        justify-content: center;

        a {
          flex-shrink: 0;
          width: 18px !important;
          height: 18px !important;
          cursor: pointer;
          margin: 0px 0px 0px 5px;
          border-radius: 3px;
          overflow: visible;

          svg {
            width: 18px !important;
            height: 18px;
            background-color: #fff;
            border-radius: 3px;

            path {
              fill: #0a66c2;
            }
          }
        }
      }

      &.second-line {
        padding-right: 0px;

        span {
          color: #ffffff;
        }
      }
    }

    .col-12 {
      .fullWidth {
        // padding-right: 15px;
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
          padding-left: 0px !important;
          padding-right: 10px !important;
        }

        @include media-breakpoint-down(sm) {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
    }

    .four-cols {
      width: 100%;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include media-breakpoint-down(lg) {
        flex: 100%;
        max-width: 100%;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
      }

      .fullWidth {
        flex: 0 0 25%;
        max-width: 25%;
        margin-bottom: 50px;
        width: 100%;

        &>div {
          width: 100%;
        }

        @include media-breakpoint-down(lg) {
          flex: 0 0 25%;
          max-width: 25%;
          padding: 0 5px;
          margin-bottom: 35px;
        }

        @include media-breakpoint-down(sm) {
          flex: 0 0 75%;
          max-width: 75%;
          padding: 0;

          &.empty-cell {
            display: none;
          }
        }
      }
    }

    .advisor {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;

      .profileImage {
        border-color: $radivision-blue;
      }

      .name {
        color: $radivision-blue;
      }

      @include media-breakpoint-down(lg) {
        flex: 100%;
        max-width: 100%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 75%;

        div {
          flex: 100%;
          max-width: 100%;
        }
      }
    }

    div,
    a {
      width: 100%;
      padding: 0;
      // padding-right: 10px;
      display: inline-block;

      @media (max-width: 992px) {
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        display: block;
      }

      .name {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Muli";
        font-size: 18px;
        font-weight: 800;
        color: rgba(76, 180, 231, 1);
        text-align: center;
        margin-top: 20px;

        @media (min-width: 1921px) {
          font-size: 28px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        line-height: normal;
        margin-bottom: 3px;
        font-family: "Muli";
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        margin: auto;

        @media (min-width: 1921px) {
          font-size: 20px;
        }
      }
    }
  }
}

.work-with-us {
  .row {
    .careers {
      &.col-6 {
        @include media-breakpoint-down(md) {
          padding-right: 0;
        }
      }

      .careers-background {
        margin-top: 30px;
        background-color: #424c4f;
        color: white;
        padding: 35px 15%;
        text-align: center;

        @media (min-width: 768px) and (max-width: 1359px) {
          padding: 35px 40px;
        }

        @include media-breakpoint-down(md) {
          margin-top: 0px;
        }

        h3 {
          font-size: 34px;
          font-family: "MuliBold";

          @media (min-width: 1921px) {
            font-size: 38px;
          }

          @media (min-width: 1200px) and (max-width: 1536px) {
            font-size: 27px;
          }

          @include media-breakpoint-down(lg) {
            font-size: 26px;
          }

          @include media-breakpoint-down(md) {
            font-size: 26px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 20px;
          }
        }

        p {
          font-size: 24px;
          font-family: "MuliBold";
          margin-top: 20px;
          margin-bottom: 0;

          @media (min-width: 1200px) and (max-width: 1536px) {
            font-size: 17px;
          }

          @include media-breakpoint-down(lg) {
            font-size: 14px;
          }

          @include media-breakpoint-down(md) {
            font-size: 14px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 9pt;
          }
        }

        .btn-radivision {
          margin: 10px 0;
        }
      }

      p {
        font-weight: lighter;
        font-size: 15pt;
        margin-top: 35px;

        @include media-breakpoint-down(sm) {
          h3 {
            font-size: 1.6rem;
          }

          font-size: 12pt;
          z-index: 100;
        }
      }
    }

    .image {
      &.col-6 {
        @include media-breakpoint-down(md) {
          padding-left: 0;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.locations {
  padding: 0px 0px;
  padding-left: 70px;
  padding-right: 60px;

  @media (min-width: 1170px) and (max-width: 1536px) {
    padding-left: 70px;
    padding-right: 60px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 35px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  h3 {
    font-size: 24px;
    text-align: left;
    margin-bottom: 25px;
  }

  .row {
    margin: 0;

    .col-md-4 {
      padding: 10px 10px;
      padding-left: 0;

      @include media-breakpoint-down(sm) {
        margin-bottom: 5px;
        flex: 100%;
        max-width: 100%;
      }

      @media (min-width: 768px) and (max-width: 1359px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }

    &:last-child {
      padding-right: 0;
    }

    div {
      img {
        width: 100%;
        height: 100%;
        cursor: default;
        transition: 0.6s ease-in-out;

        // &:hover {
        //   // transform: scale(1.1, 1.1);
        // }
      }

      figcaption {
        position: inherit;
        bottom: 45px;
        text-align: center;
        color: white;
        font-size: 24px;

        @media (min-width: 1921px) {
          font-size: 26px;
        }

        @media (min-width: 1200px) and (max-width: 1536px) {
          font-size: 19px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 10pt;
        }
      }
    }
  }
}

.about-danger {
  margin: 35px 70px;

  @include media-breakpoint-down(md) {
    margin: 35px;
  }

  @include media-breakpoint-down(sm) {
    margin: 35px;
  }
}

@keyframes ytp-bezel-fadeout {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.pbAnimation {
  z-index: 19;
  width: 57px;
  height: 57px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 1) !important;
  transform: translate(0, 0) !important;

  svg {
    width: 24px !important;
    height: 24px !important;
    vertical-align: middle;
    text-align: center;
    fill: #fff !important;
  }
}

.In {
  -moz-animation: ytp-bezel-fadeout 0.7s linear 1 normal forwards;
  -webkit-animation: ytp-bezel-fadeout 0.7s linear 1 normal forwards;
  animation: ytp-bezel-fadeout 0.7s linear 1 normal forwards;
  pointer-events: none;
}

.cover-Image {
  position: absolute;
  width: 100%;
  top: 0;
  margin: 0 auto;
  background: #32323b;

  @media (min-width: 1024px) {
    height: 91vh;
  }
}

.item-panel {
  margin: 85px 70px;

  @include media-breakpoint-down(md) {
    margin-right: 35px;
    margin-left: 35px;
  }

  .list_info {
    margin-bottom: 35px;

    span {
      margin-right: 8px;
      font-size: 12px;
      font-weight: 100;
      color: #a7a9ac;

      &:first-child {
        font-weight: 700;
        font-size: 14px;

        @media (min-width: 2500px) {
          font-size: 28px;
        }
      }

      @media (min-width: 2500px) {
        font-size: 24px;
      }
    }

    @media (min-width: 2500px) {
      font-size: 22px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .upcoming-show-details {
    padding-left: 0;

    h3 {
      margin-top: 45px;
      font-weight: bold;
      font-family: "MuliExtraBold";
      font-size: 1.9rem;

      @media (min-width: 1921px) {
        font-size: 2.75rem !important;
      }

      @media (min-width: 1537px) {
        font-size: 2.2rem;
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;

      @media (min-width: 1921px) {
        font-size: 22px;
      }
    }
  }

  .rdv-sep {
    width: 100%;
  }

  .row {
    margin-left: 0;
    margin-right: 0;

    .extend_media {
      padding: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }

  p {
    margin-top: 20px;
    font-size: 12px;
    margin-bottom: 35px;

    @media (min-width: 2500px) {
      font-size: 22px;
      margin-bottom: 45px;
    }
  }

  .location {
    color: #4cb4e7;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 0;

    @media (min-width: 2000px) {
      font-size: 17px;
    }

    svg {
      width: 26px;
      vertical-align: bottom;

      @media (min-width: 2000px) {
        width: 30px;
      }
    }

    .cls-1 {
      fill: #4cb4e7;
    }
  }

  .socialMedia {
    float: right;

    svg {
      margin-left: 7px;
      width: 45px;
      height: 45px;
      cursor: pointer;

      @media (min-width: 2500px) {
        width: 64px;
        height: 64px;
      }

      @media (min-width: 1024px) and (max-width: 1280px) {
        width: 40px;
        height: 40px;
      }

      @include media-breakpoint-down(md) {
        width: 40px;
        height: 40px;
      }
    }

    .facebookProfile {
      .cls-1 {
        fill: url(#radial-gradient);
      }

      .cls-2 {
        fill: #fff;
      }
    }

    .twitterProfile {
      .cls-1 {
        fill: #1da1f2;
      }

      .cls-2 {
        fill: #fff;
      }
    }

    .linkedProfile {
      .cls-1 {
        fill: #007bb5;
      }

      .cls-2 {
        fill: #fff;
      }
    }

    .redditProfile {
      .cls-1 {
        fill: #ff4500;
      }

      .cls-2,
      .cls-3 {
        fill: #eeeff0;
      }

      .cls-2 {
        fill-rule: evenodd;
      }
    }
  }
}

.default_image {
  margin-bottom: 20px;
}

.back {
  position: absolute;
  left: 12px;
  top: 75px;
  font-size: 18px !important;
  color: #fff !important;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  z-index: 999;

  svg {
    width: 25px;
    height: 25px;
    vertical-align: bottom;
    margin-right: 5px;
    fill: #fff;

    @include media-breakpoint-down(md) {
      width: 18px;
      height: 18px;
    }
  }

  @media (min-width: 2500px) {
    span {
      font-size: 26px;
    }

    svg {
      width: 30px;
      height: 30px;
      vertical-align: text-bottom;
    }
  }

  @include media-breakpoint-down(md) {
    top: 60px;
    font-size: 13px !important;
  }
}