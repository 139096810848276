/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-09-04
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.press {
    .coverImage {
        img {
            filter: brightness(50%);
        }

        h2 {
            position: relative;
            top: -203px;
            color: white;
            font-weight: bold;
            font-size: 20pt;
        }
    }

    .peopleSay {
        padding: 0% 18%;

        .title {
            font-size: 15pt;

            &:after {
                display: block;
                content: "";
                width: 70%;
                height: 0.1rem;
                background-color: #edeaea;
                margin: 2% 15% 4% 15%;
            }
        }

        .row {
            div {
                margin-bottom: 50px;

                h3 {
                    font-weight: bold;
                    font-size: 11pt;
                }

                h2 {
                    font-size: 10pt;
                    font-style: italic;
                    font-weight: lighter;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 10pt;
                    font-weight: 200;
                }

                h6 {
                    color: #4cb4e7;
                    font-size: 10pt;
                    cursor: pointer;
                }
            }
        }
    }
}