.small-pitch-carousel {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  padding: 0px 0px 0px 0px;
  width: 100vw;
  box-sizing: border-box;
  display: block;

  &.is-standalone {
    margin-bottom: 30px;
  }

  &.is-home-carousel {
    margin-bottom: -100px !important;
  }

  .loader-container.spinner {
    background: none;
    background-color: none;
  }
  .panel-title {
  }
  .bottom-shadow {
    pointer-events: none;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(22, 22, 22, 1) 0%,
      rgba(22, 22, 22, 1) 2%,
      rgba(22, 22, 22, 0) 80%,
      rgba(22, 22, 22, 0) 100%
    );
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }
  .top-shadow {
    pointer-events: none;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(22, 22, 22, 1) 0%,
      rgba(22, 22, 22, 1) 2%,
      rgba(22, 22, 22, 0.6) 40%,
      rgba(22, 22, 22, 0.6) 100%
    );
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }

  .pitch-carousel-header {
    display: flex;
    min-height: 200px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: none;
    margin: 20px 24px 10px 24px;
    position: relative;
    h3 {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 0.075rem !important;
      font-family: "MuliExtraBold";
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 30vw;
        margin-right: 20px;
      }
      a {
        display: none;
      }
    }
    .view-all {
      display: flex;
      visibility: hidden;
      a {
        text-decoration: none;
        color: $radivision-blue;
        cursor: pointer;
        font-family: "Muli";
        font-weight: 500;
        font-size: 16px;
        align-items: center;
        display: flex;
        align-items: center;
        svg {
          margin-top: 2px;
          width: 14px;
          fill: $radivision-blue;
        }
      }
    }
    p {
      display: flex;
    }
    h3.pitch-panel-title {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: flex-start;
    }
  }
  &.is-standalone {
    margin-bottom: 30px;
    .pitch-carousel-header {
      .view-all {
        visibility: visible !important;
      }
    }
  }
  .pitch-video {
    & > div {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }

  .slick-slider {
    margin: 0px 0px;
    min-height: 460px;
    position: relative;
    z-index: 1;
    display: block;
  }
  .slick-track {
    display: flex;
  }
  .pitch-card {
    color: #fff;
    font-size: 36px;
    margin: 10px 18px 0px 18px;
    position: relative;
    text-align: center;
    .referral {
      height: 42px;
      min-height: 42px;
      top: -46px;
      right: 5px;
      position: absolute;
      width: auto;
      transform: translate(0px, -5px);
      display: flex;
      visibility: hidden;
      flex-flow: row;
      justify-content: flex-end;
      align-items: center;
      font-size: 13px !important;
      color: white !important;
      .referral-name {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-end;
      }
      p.nominated-by {
        font-size: 12px !important;
        color: white !important;
        padding: 0px;
        margin: 0px;
      }
      a.nominated-by {
        font-size: 13px !important;
        color: white !important;
        font-family: MuliBold;
        &:hover {
          color: $radivision-blue !important;
          text-decoration: none;
        }
      }
      .profile-picture-name {
        display: none;
        flex-flow: row-reverse nowrap;
        .profile-picture {
          width: 34px !important;
          height: 34px !important;
          margin-left: 10px;
          margin-right: 0px;
          background-size: 120%;
          border: 0px !important;
        }
        .profile-name {
          display: none;
        }
      }
    }
    .pitch-card-text {
      transition: opacity 0.3s ease-in;
      font-size: 14px;
      color: #888;
      text-align: left;
      margin-bottom: 0px;
      .ellipsis {
        color: #888;
        font-size: 14px;
      }
      .read-more {
        color: #aaa;
        font-size: 14px;
        font-family: MuliBold;
      }
    }
    .pitch-card-title {
      transition: opacity 0.3s ease-in;
      margin: 10px 10px;
      display: flex;
      justify-content: space-between;
      .pitch-card-actions {
        display: flex;
        flex-flow: row;
        .pitch-card-button {
          cursor: pointer;
          max-height: 36px;
          text-decoration: none;
          background-image: none;
          background-color: #313131;
          white-space: nowrap;
          font-size: 15px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 0px;
          flex-grow: 1;
          font-size: 14px;
          max-width: 50vw;
          min-width: 50vw;
          padding: 0px 36px;
          height: 36px !important;
          letter-spacing: 0.03em;
          border-radius: 16px;
          &.outline-button {
          }
          &.outline-button {
            background-color: transparent;
            border: 2px solid #ffffff;
          }
          &.disabled {
            background-image: none;
            background-color: grey !important;
            color: #bbbbbb;
            cursor: not-allowed;
          }
          svg {
            margin: 0px 0px 0px 10px;
            width: 15px;
            height: 15px;
            color: white;
            fill: white;
          }
        }
        .btn-radivision {
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          font-size: 14px;
          padding: 0px 25px;
          height: 36px;
          color: white;
          margin: 10px 0px 10px 0px;
          background-image: none;
          background-color: #313131;
          max-width: 50vw;
          min-width: 50vw;
        }
        svg {
          margin: 0px 0px 0px 10px;
          width: 15px;
          height: 15px;
          color: white;
          fill: white;
        }
      }
      .profile-picture-name {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        .profile-picture {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 2px solid white;
          margin-right: 10px;
          background-color: white;
          background-size: cover;
          background-position: center center;
          box-sizing: border-box;
          overflow: hidden;
        }
        .profile-name {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
  .pitch-video-container {
    overflow: hidden;
    border-radius: 20px;
    overflow: hidden;
  }
  .slick-slide:not(.slick-current) {
    .pitch-poster {
      filter: brightness(50%);
    }
  }
  .slick-current {
    .pitch-video {
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.5);
    }
    .referral {
      visibility: visible;
    }
  }
  .pitch-video-container {
    padding-bottom: 56.25%;
    height: 0px;
    position: relative;
    .pitch-video {
      border-radius: 20px;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-color: #171717;
      flex: 1;
      border-radius: 20px;
      overflow: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }
    .pitch-poster {
      border-radius: 20px;
      pointer-events: none;
      overflow: hidden;
      transition: all 0.8s linear;
      background-size: cover;
      background-position: center center;
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
    }
  }
  .center {
  }
  .center .pitch-card {
    // transition: transform 300ms ease;
    // transform: scale(0.95);
  }
  .center .slick-center .pitch-card {
    color: #e67e22;
    // tranlsform: scale(1.05);
  }
  .content {
    padding: 0px;
    margin: auto;
  }
  @media (max-width: 700px) {
    .content {
      width: 70%;
    }
  }
  .slick-slide .image {
    padding: 10px;
  }
  .slick-slide img.slick-loading {
    border: 0;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    width: 100%;
    height: 100%;
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
  .slick-vertical .slick-slide {
    height: 280px;
  }
  .slick-arrow:hover {
  }
  .slick-arrow:focus {
  }

  .button {
    padding: 10px 20px;
    margin: 0px 20px;
    border: none;
    color: white;
    font-size: 20px;
    border-radius: 5px;
    min-height: 45px;
  }
  .slick-arrow svg {
    color: #ffffff;
    width: 50px;
    height: 50px;
  }
  .slick-next {
    right: 10px;
  }
  .slick-prev {
    left: 10px;
  }
  .slick-arrow:before,
  [dir="rtl"] .slick-prev:before {
    content: "";
    display: none;
  }
  .slick-slide,
  .pitch-card {
    outline: none;
    border-radius: 20px;
  }

  .pitch-video-container {
    background: #171717;
  }
  .pitch-get-discovered {
    top: unset;
    bottom: 40px;
    left: 40px;
    right: unset;
  }

  .slick-arrow {
    width: 20px;
    height: 49vw;
    display: flex;
    top: 15px;
    position: absolute;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: rgba(80, 80, 80, 0.5);
    z-index: 1;
  }
  .slick-prev {
    left: -3px;
    border-radius: 0px 10px 10px 0px;
    svg {
      transform: translateX(0px);
    }
  }
  .slick-next {
    right: -3px;
    border-radius: 10px 0px 0px 10px;
    svg {
      transform: translateX(0px);
    }
  }
  .slick-arrow svg {
    width: 30px;
    height: 30px;
  }
}
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
