/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-18
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
#changeBG {
  .modal-header {
    background-color: white;

    svg {
      fill: $radivision-dk-gray;
    }

    h4 {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .modal-body {
    background-color: #ececec;

    .row {
      div {
        border: 2px solid whitesmoke;
        margin-right: 8%;
        margin-bottom: 2%;
        height: 150px;
      }
    }
  }

  .modal-footer {
    text-align: center;
    padding: 4%;

    button {
      background-color: transparent;
      border: 1px solid lightgray;
      padding: 15px 55px;
      margin-top: 2%;
      margin-right: 26px;

      &:hover {
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
          0 17px 50px 0 rgba(0, 0, 0, 0.19);
        color: white;
        background-color: #d0d0d0;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}