/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-05
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
#contact-us {
  // padding: 0% 5% 3% 5%;
  position: relative;
  width: 70%;
  margin: 0 auto;

  @media (max-width:1280px) {
    width: 85%;
  }

  @media (max-width:1024px) {
    width: 100%;
    padding: 35px;

  }

  @media (min-width:2000px) {
    width: 62%
  }

  @include media-breakpoint-down(md) {
    padding: 35px;
    width: 100%;
  }

  .row {
    margin: 0
  }

  div:not(.alert) {
    padding: 0;
    margin: 0 auto;
  }

  // @media (min-width:1025px) and (max-width:1280px) {
  //   padding: 0 35px !important;
  // }

  @include media-breakpoint-down(lg) {
    padding: 0 35px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 35px;
  }

  @include media-breakpoint-down(sm) {
    // padding: 10% 4% 4% 4%;
  }

  p {
    text-align: center;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }

    a {
      margin-left: 5px;
      color: #4cb4e7 !important
    }
  }

  h1 {
    font-size: 2rem;
    padding-top: 5rem;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 1.7rem;
      padding-top: 4rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
      padding-top: 1rem;
    }
  }

  h4 {
    font-size: 16pt;
    text-align: center;

    @media (min-width:1024px) and (max-width:1280px) {
      font-size: 14pt;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }

    // &::after {
    //   display: block;
    //   content: "";
    //   height: 0.2rem;
    //   background-color: #edeaea;
    //   margin: 3% 0%;

    //   @include media-breakpoint-down(sm) {
    //     margin-right: 15%;
    //   }
    // }
  }

  form {
    @include media-breakpoint-down(lg) {
      margin: 9% 0% 0% 0%;
    }

    .required-text {
      margin-bottom: 15px;
    }

    .required-star {
      color: red;
      position: relative;
      top: 1px;
      left: 1px;
    }

    h3 {
      font-weight: 600;

      @include media-breakpoint-down(md) {
        font-size: 17pt;
      }

      @include media-breakpoint-down(sm) {
        font-size: 16pt;
      }
    }

    h6 {
      margin-bottom: 10%;
      margin-top: 4%;
      font-weight: 600;
      max-width: 88%;

      @include media-breakpoint-down(md) {
        max-width: 100%;
        font-size: 10pt;
      }
    }

    input {
      // border-left: none;
      // border-radius: 0;
      // border-right: none;
      // border-top: none;
      padding-top: 15px;
    }

    .hide {
      display: none;
    }

    .show {
      display: block;
      top: 0;
      color: red;
      font-weight: bold;
      font-style: italic;
      font-size: 11pt;
    }

    .padding-top {
      // padding-top: 20px;
      color: #b8bdc3;
    }

    textarea {
      // border-left: none;
      // border-radius: 0;
      // border-right: none;
      // border-top: none;
      min-height: 6rem;

      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    button {
      width: 100%;
    }
  }

  .right-section {
    @include media-breakpoint-down(sm) {
      margin-left: 4%;
    }

    div {
      h3 {
        font-weight: 600;

        @media (min-width:1024px) and (max-width:1280px) {
          font-size: 24px;
        }

        @include media-breakpoint-down(md) {
          font-size: 17pt;
        }

        @include media-breakpoint-down(sm) {
          font-size: 16pt;
          margin-top: 15px;
        }
      }

      p {
        font-weight: 600;
        font-size: 11pt;

        @include media-breakpoint-down(md) {
          font-size: 10pt;
        }

        @include media-breakpoint-down(sm) {
          font-size: 9pt;
        }
      }

      a {
        color: $radivision-blue;
        margin-left: 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    ////////////////////////////////////////////////////////////////////
    .branch-one {
      margin-top: 40px;

      h3 {
        font-weight: 600;
        font-size: 15pt;
      }

      h5 {
        font-weight: 400;
        font-size: 13pt;
        margin-bottom: 30px;
      }
    }

    .branch-two {
      h3 {
        font-weight: 600;
        margin-top: 24px;
        font-size: 15pt;
      }

      h5 {
        font-weight: 400;
        margin-top: -5px;
        margin-bottom: 30px;
        font-size: 13pt;
      }
    }

    .branch-three {
      h3 {
        font-weight: 600;
        margin-top: 23px;
        font-size: 15pt;
      }

      h5 {
        font-weight: 400;
        font-size: 13pt;
        margin-top: -5px;
      }
    }
  }
}