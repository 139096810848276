.account-settings-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: max-content;

  .subscription-section-btn {
    margin-bottom: 4rem;
  }
  .action-btn {
    float: right;
  }
  .subscription-section {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
  }
  .section-text {
    float: left;
    width: 30%;
  }
  .section-info {
    width: 70%;
  }

  .settings-inner {
    flex: 1;
    max-width: 1000px;
    min-height: 600px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  h4.company-name {
    font-family: MuliSemiBold;
    font-size: 18px;
  }
  .settings-section {
    max-width: 500px;
    margin: 30px 0px;
    input,
    button,
    h3 {
      font-family: Muli;
    }
    button {
      margin-top: 0px;
      font-size: 13px;
      padding: 10px 20px;
    }
  }
  .settings-actions-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    flex-grow: 0;
    & > div {
      margin-left: 10px;
    }
  }
  .cancel-subscription-button {
    display: flex;
    justify-content: flex-end;
    .btn-radivision {
      padding: 5px 20px;
      background-image: none;
      background-color: #444444;
      color: #dddddd;
      &:hover {
        background-color: #d00;
        color: white;
      }
    }
  }
  .change-payment-plan-button {
    display: flex;
    justify-content: flex-end;
    .btn-radivision {
      padding: 5px 20px;
      background-image: none;
      background-color: transparent;
      color: $radivision-blue !important;
      border: 1px solid $radivision-blue;
      &:hover {
        background-color: $radivision-blue;
        border: 1px solid transparent;
        color: white !important;
      }
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  #my-promotions {
    max-width: 800px;
    .payment-plan-footer {
      margin-bottom: 60px !important;
    }
    .payment-plan-card {
      .plan-benefit {
        h4, p {
          font-size: 18px;
        }
      }
    }
  }
  #cancel-promotion {
    max-width: 800px;
    display: flex;
    width: 100%;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  #cancel-promotion-modal,
  #deactivate-account-modal {
    .modal-body {
      padding: 10px 40px 40px 40px;
    }
    .content {
      & > p {
        text-align: left;
        margin: 20px 0px;
      }
    }
    textarea {
      border: 1px solid #2e2e2e;
      background-color: #2e2e2e;
      padding-left: 52px;
      width: 100%;
      border-radius: 22px;
      font-family: Muli;
      font-size: 14px;
      color: #fff;
      position: relative;
    }
  }
  #account-deactivation {
    max-width: 800px;
    display: flex;
    width: 100%;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  .is-highlight {
    button {
      background-image: none;
      background-color: $radivision-blue;
      text-transform: capitalize;
      border: 1px solid $radivision-blue;
    }
  }
  .is-grayed {
    button {
      background-image: none;
      background-color: #999999;
      text-transform: capitalize;
      border: 1px solid #999999;
      &:hover {
        border: 1px solid #d00;
        background-color: #d00;
        color: white;
      }
    }
  }
  .settings-menu {
    background-color: #000000;
    width: 300px;
    min-width: 300px;
    border-right: 1px solid #212121;
    padding: 40px 0px 20px 0px;
    box-sizing: border-box;
    h3 {
      text-transform: uppercase;
      font-size: 18px;
      color: #dddddd;
      font-family: MuliBold;
      padding: 0px 0px 0px 40px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        a {
          cursor: pointer;
          text-decoration: none;
          display: flex;
          width: 100%;
          font-size: 16px;
          padding: 15px 60px 15px 40px;
          color: #eeeeee;
          &.active {
            color: #ffffff;
            background-color: #212121;
          }
        }
      }
    }
  }
  .settings-page {
    flex: 1;
    padding: 40px;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      background-color: transparent;
      font-family: Muli;
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      text-align: left;
    }

    input {
      border: 1px solid #2e2e2e;
      background-color: #2e2e2e;
      border-radius: 22px;
      font-family: Muli;
      font-size: 13px;
      color: #fff;
      height: 43px;
      position: relative;

      &.warn {
        border-color: $danger;
      }

      &:disabled {
        background-color: $dark-mode-black;
        border-color: $dark-mode-black;
      }
    }

    textarea {
      border: 1px solid #2e2e2e;
      background-color: #2e2e2e;
      border-radius: 10px;
      font-family: Muli;
      font-size: 13px;
      color: #fff;
      position: relative;
      padding-right: 35px;
    }

    button:not(.custom-file-input) {
      font-family: "Muli";
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      padding: 0 20px;
      text-transform: capitalize;
      height: 43px;
      margin: 0;

      &.auto-fill {
        position: absolute;
        right: 15px;
        top: 30px;
        height: 43px;
        margin: 0;
        min-width: 100.43px;
      }

      .spinner-border {
        margin-right: 2px;
        width: 1rem;
        height: 1rem;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .settings-inner {
      flex-flow: column;
    }
    .settings-menu {
      min-height: auto;
      width: 100%;
    }
    #account-deactivation {
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
