$black: #343838;
$blue: #00dffc;
$dullWhite: #f6f6f6;
$easing: cubic-bezier(0.6, 0.05, 0.28, 0.91);

.external {
  &.main-navbar.navbar {
    background-color: $dark-mode-bg;
    transition: background linear 0.1s;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    height: auto;

    @media (min-width: 1024px) {
      height: 136px;
    }

    &.transparent {
      @include media-breakpoint-up(md) {
        background-color: rgba(22,22,22,0);
        background-image: -webkit-linear-gradient(0, rgba(22,22,22,0), #161616);
        background-image: -moz-linear-gradient(0, rgba(22,22,22,0), #161616);
        background-image: -o-linear-gradient(0, rgba(22,22,22,0), #161616);
        background-image: linear-gradient(0, rgba(22,22,22,0), #161616);
      }
    }

    &.darkBlue {
      background: $radivision-dk-gray !important;
    }

    div:not(.dropdown-menu) {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      width: 72%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .navbar-brand {
        max-width: 175px;
        padding-top: 6px;
        padding-bottom: 14px;
        margin: 0;
        flex: none;

        @media (min-width: 2000px) {
          max-width: 225px;
        }

        img {
          width: auto;
          height: auto;
        }
      }

      .nav-item {
        .nav-link {
          position: relative;
          color: white;
          margin: auto 0;
          padding: 0.5rem 1.75rem;
          text-align: center;
          height: 100%;
          cursor: pointer;
          font-family: "Muli";
          font-size: 18px;
          font-weight: bold;
          border-top: 0;
          border-bottom: 0;
          transition: all 0.3s;

          &.more-dropdown {
            padding-right: 1.45rem;
          }

          &::after {
            content: "";
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            height: 3px;
            background: $radivision-blue;
            transform: scaleX(0);
            transition: transform 0.35s ease;
          }

          &:hover::after {
            transform: scaleX(0.25);
          }

          &:active::after {
            transform: scaleX(0.25);
          }
          &.active::after {
            transform: scaleX(0.25);
          }

          &:hover,
          &:active,
          .active {
            color: $radivision-blue;
          }

          svg {
            fill: #fff;
            width: 14px;
            height: auto;
            margin-left: 5px;
            margin-top: 3px;
            margin-right: 0;

            & .goToHome {
              .st0 {
                fill: #ffffff;
              }
            }
          }

          @media (min-width: 2000px) {
            font-size: 21px;
            padding: 0.75rem 2.2rem;
          }

          @media (min-width: 1024px) and (max-width: 1440px) {
            font-size: 14px;
          }

          @media (max-width: 1024px) {
            font-size: 14px;
            padding: 0.75rem 1rem;
          }

          @include media-breakpoint-down(md) {
            &.active {
              color: $radivision-blue;
            }
          }

          @include media-breakpoint-down(sm) {
            padding: 0.75rem 2rem;
          }
        }

        &:hover,
        &:active {
          .dropdown-menu {
            top: 73px;

            &::before {
              left: 36%;
            }
          }
        }
        .dropdown-menu {
          &::before {
            left: 36%;
          }
        }
      }

      .nav-right-menu {
        margin-right: 0;

        .nav-link {
          border: none;
          font-family: "Muli";
          font-size: 15px;
          font-weight: 800;
          height: auto;
          padding: 0;

          &.join {
            background-color: $radivision-blue;
            border-radius: 16px;
            padding: 0 18px;
            margin-right: 0;

            @include media-breakpoint-down(md) {
              padding: 0 15px;
            }
          }

          @include media-breakpoint-down(md) {
            font-size: 13px;
          }

          &:hover,
          &:active {
            color: #fff;
          }

          &:hover::after {
            transform: scaleX(0);
          }

          &:active::after {
            transform: scaleX(0);
          }

          @media (min-width: 2000px) {
            font-size: 21px;
            padding: 5px 18px;
          }
        }

        @include media-breakpoint-down(md) {
          width: auto;
        }
      }

      //styling for mobile screens
      .navbar-toggler {
        border: none;
        border-radius: 0;
        width: 60px;
        background: transparent;
        padding-top: 10px;

        @media (min-width: 1024px) and (max-width: 1170px) {
          display: none;
        }

        .animated-icon3 {
          width: 26px;
          height: 22px;
          position: relative;
          margin: 0px auto;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.5s ease-in-out;
          -moz-transition: 0.5s ease-in-out;
          -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out;
          cursor: pointer;

          span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
            background: $radivision-blue;

            &:nth-child(1) {
              top: 0px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

            &:nth-child(2) {
              top: 8px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

            &:nth-child(3) {
              top: 16px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }
          }
        }
      }

      .navbar-collapse {
        justify-content: center;
        align-items: center;
        display: flex !important;
        height: 100%;

        .navbar-nav {
          height: 100%;
        }

        @include media-breakpoint-down(md) {
          display: none !important;
          width: 100%;
          height: 100vh;
          position: fixed;

          &.expanded {
            display: flex !important;
            width: 100%;
            height: 100vh;
            justify-content: flex-end;

            .nav-link {
              &::after {
                content: none;
              }
              &.active {
                content: none;
                color: $radivision-blue;
              }
            }

            .dropdown-menu {
              top: unset;

              .dropdown-item.active {
                color: $radivision-blue;
              }
            }

            .navbar-collapse-backdrop {
              position: fixed;
              width: 100vw;
              display: block;
            }
          }
        }

        @media (max-width: 1024px) {
          width: auto;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          flex: auto;
          display: none !important;

          &.show {
            display: block !important;
            position: absolute;
            top: 70px;
            right: 35px;
            height: 250px;
            width: 175px;

            &::before {
              display: block;
              content: "";
              position: absolute;
              top: -28px;
              right: 8%;
              z-index: 1;
              width: 0;
              height: 0;
              border-color: transparent;
              border-width: 8px;
              border-top-width: 14px;
              border-style: solid;
              border-bottom: 14px solid #161616;
            }
          }
        }
      }
    }
  }

  &.cover-image {
    width: 100%;
    height: 100%;
    position: relative;

    @media (min-width: 1024px) {
      height: 84.3vh;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-logo {
      position: absolute;
      margin: auto;
      width: 46%;
      height: auto;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  &.footer {
    padding: 40px 110px;

    &>div {
      margin: 0 auto;
      width: 100%;
      max-width: 1139px;
      color: #fff;
      padding: 45px 0px 0;
    }
    .disclaimer {
      p {
        font-size: 12px;
        color: #555555;
      }
      @include media-breakpoint-down(md) {
        padding: 10px 0px;
      }
    }
    .logo {
      margin-top: 20px;
      width: 200px;
      height: auto;
      margin-bottom: 32px;
      max-width: unset!important;
    }
    @media (min-width: 1024px) and (max-width: 1440px) {
      padding: 40px 70px;
    }

    @media (max-width: 1024px) {
      padding: 35px;
    }
    @include media-breakpoint-down(sm) {
      padding: 35px 15px;
      .disclaimer {
        padding: 20px 15px;
        p {
          font-size: 12px;
          color: #555;
        }
      }
    }

    img {
      max-width: 175px;
      margin-bottom: 25px;
    }

    .social-icons {
      padding: 0;
      display: flex;
      justify-content: flex-start;

      @media (max-width: 1024px) {
        padding: 0;
      }

      @include media-breakpoint-down(sm) {
        display: flex;
      }

      li {
        @include media-breakpoint-down(md) {
          margin-right: 5px;
        }

        margin-right: 13px;
        display: inline;

        button {
          border-radius: 50%;
          justify-content: center;
          background: white;
          border: 1px;
          cursor: pointer;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
        }

        button svg {
          margin: -5px;
          vertical-align: inherit;
          width: auto;
          height: 16px;

          &#facebookIcon {
            transform: translateX(4px);
          }

          &#twitterIcon {
            margin-top: -1px;
          }
        }
      }
    }

    .list-unstyled {
      display: flex;
      margin: 3rem 0;

      li {
        a {
          font-family: "Muli";
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          padding: 1rem 0;
          padding-right: 3rem;
          cursor: pointer;

          @media (max-width: 1024px) {
            padding-right: 1rem;
            font-size: 14px;
            color: #aaa;
          }
        }
      }

      @media (max-width: 1024px) {
        margin: 1rem 0;
      }
      @include media-breakpoint-down(sm) {
        flex-flow: row wrap;
        li {
          min-width: 160px;
          padding: 4px 0px;
        }
      }
    }

    .content-right {
      font-family: "Muli";
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }

  &.inner-pages {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 70px;

    @media (max-width: 1280px) {
      width: 85%;
    }

    @media (max-width: 1024px) {
      width: 100%;
      padding: 35px;
    }

    @media (min-width: 2000px) {
      width: 62%;
    }

    @include media-breakpoint-down(md) {
      padding: 35px;
      width: 100%;
    }

    h1 {
      font-family: "Muli";
      font-size: 50px;
      font-weight: 800;
      padding-top: 80px;
      color: white;

      @media (min-width: 1024px) and (max-width: 1280px) {
        font-size: 24px;
      }

      @include media-breakpoint-down(md) {
        padding-top: 0;
        font-size: 16pt;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.7rem;
      }

      @media (min-width: 2500px) {
        font-size: 60px;
        padding-top: 10rem;
      }
    }

    h2 {
      color: white;
    }

    h4 {
      font-size: 16px;
      color: white;

      @media (min-width: 1024px) and (max-width: 1280px) {
        font-size: 12pt;
      }

      @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
      }

      &::after {
        display: block;
        content: "";
        height: 0.2rem;
        background-color: #8b8b8b;
        margin: 3% 0%;
      }
    }

    h3 {
      font-size: 13pt;
      color: white;

      @include media-breakpoint-down(md) {
        font-size: 12pt;
      }

      @include media-breakpoint-down(sm) {
        font-size: 11pt;
      }


      @media (min-width: 2500px) {
        font-size: 22pt;
      }
    }

    p,
    ol li {
      font-size: 16px;
      color: white;

      @media (min-width: 1024px) and (max-width: 1280px) {
        font-size: 12pt;
      }

      @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
      }

      input[type="radio"],
      input[type="checkbox"] {
        margin-right: 10px;
      }
    }

    .issue-types {
      div {
        font-size: 16px;
        color: white;

        @media (min-width: 1024px) and (max-width: 1280px) {
          font-size: 12pt;
        }

        @include media-breakpoint-down(sm) {
          font-size: 0.9rem;
        }

        input[type="radio"],
        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
    }

    a {
      font-family: "Muli";
      font-size: 18px;
      font-weight: 800;
      color: rgba(76, 180, 231, 1);
    }

    .alignItems {
      justify-content: center;
      align-items: center;
    }

    .contactButton {
      width: 100%;
      font-family: "Muli";
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      padding: 10px 30px;
      text-transform: capitalize;
      margin-top: 10px;
    }

    .question-and-answer {
      div {
        margin-bottom: 4%;
        border-bottom: 2px solid #8b8b8b;
        position: relative;

        @keyframes topFadeIn {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 0.5;
          }

          100% {
            opacity: 1;
          }
        }

        svg {
          width: 17px;
          fill: #4cb4e7;
          position: absolute;
          top: 0;
          right: 20px;
          cursor: pointer;

          @include media-breakpoint-down(sm) {
            width: 10px;
          }
        }

        .hide {
          display: none;
        }

        .show {
          margin-top: 0px;
          animation-name: topFadeIn;
          animation-duration: 1.5s;
        }

        .answer {
          position: relative;
          bottom: 22px;
          margin-left: 23px;
          color: white;

          @include media-breakpoint-down(md) {
            font-size: 10pt;
          }

          @include media-breakpoint-down(sm) {
            font-size: 10pt;
          }

          a {
            color: #4cb4e7;
            margin-right: 2px;
            margin-left: 4px;
            font-size: 11pt;

            @media (min-width: 2500px) {
              font-size: 18pt;
            }

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
        h3 {
          &:hover {
            color: #4cb4e7;
            cursor: pointer;
          }
        }

        .noImageIcon {
          position: relative;
          left: 1px;
          top: 3px;
          width: 18px;
          border: 0.7px solid;
          border-radius: 50%;
          padding: 2px;
          cursor: auto;
        }

        span {
          font-weight: bold;
          color: #4cb4e7;
        }

        &:last-child {
          border: 0;
        }
      }
    }

    .right-side {
      .right-side-content {
        border-left: 6px solid #8b8b8b;
        border-left-width: 3px;
        padding-left: 36px;

        @include media-breakpoint-down(sm) {
          border-left: none;
          margin-left: -35px;
          padding-top: 50px;
          margin-top: -25px;
        }

        div {
          margin-bottom: 30px;

          h3 {
            margin-bottom: 3%;
            font-weight: 900;

            @media (min-width: 2500px) {
              font-size: 18pt;
            }

            @include media-breakpoint-down(md) {
              font-size: 10pt;
            }
          }

          a {
            color: #4cb4e7;

            &:hover {
              color: #4cb4e7;
              cursor: pointer;
            }
          }
        }

        .email-link {
          color: #4cb4e7;
          text-decoration: underline;
        }

        .or {
          margin-top: -5px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.fixed-top {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
