/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-02
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
#TermsAndConditions {
  // padding: 0% 18% 3% 18%;
  width: 70%;
  margin: 0 auto;

  @media (max-width:1280px) {
    width: 85%;
  }

  @media (max-width:1024px) {
    width: 100%;
    padding: 35px;

  }

  @media (min-width:2000px) {
    width: 62%
  }

  @include media-breakpoint-down(md) {
    padding: 35px;
    width: 100%;
  }

  .title {
    padding-top: 5rem;
    font-weight: bold;

    @media (min-width:1024px) and (max-width:1280px) {
      font-size: 22px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 3rem;
      font-size: 16pt;
    }

    @include media-breakpoint-down(sm) {
      // font-size: 12pt;
      padding-top: 2rem;
    }

    &::after {
      display: block;
      content: "";
      height: 0.2rem;
      background-color: #edeaea;
      margin: 3% 0%;
    }
  }

  .introduction {
    margin-bottom: 2.5rem;

    h5 {
      font-size: 0.9rem;
      font-weight: 600;


      @include media-breakpoint-down(md) {
        font-size: 9pt;
      }

      // @include media-breakpoint-down(sm) {
      //   font-size: 7pt;
      // }
    }
  }

  .content {
    ol {
      padding-left: 35px;

      @include media-breakpoint-down(sm) {
        padding-left: 10px;
      }

      >li {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 1.25rem;

        @include media-breakpoint-down(md) {
          font-size: 13pt;
        }

        @include media-breakpoint-down(sm) {
          font-size: 12pt;
        }

        >h5 {
          font-weight: bold;

          @media (min-width:1024px) and (max-width:1280px) {
            font-size: 18px;
          }

          @include media-breakpoint-down(md) {
            font-size: 13pt;
          }

          @include media-breakpoint-down(sm) {
            font-size: 12pt;
          }
        }

        span {
          font-weight: normal;
          font-size: 16px;

          @include media-breakpoint-down(md) {
            text-align: justify;
            font-size: 10pt;

          }

          @include media-breakpoint-down(sm) {
            text-align: justify;
            font-size: 9pt;
          }

          h5 {
            @include media-breakpoint-down(md) {
              font-size: 11pt;
              font-weight: bold;
            }

            @include media-breakpoint-down(sm) {
              font-size: 8pt;
              font-weight: bold;
            }
          }

          a {
            color: #4cb4e7;
            cursor: pointer;
            margin-left: 4px;

            &:hover {
              text-decoration: underline;
            }
          }

          ul {
            margin-bottom: 15px;
          }
        }

      }
    }
  }
}