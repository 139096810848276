.portfolio-container {
  .portfolio-list {
    // padding: 0px 10px;
    margin-bottom: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid #313131;
    // &:last-child {
    //   border: 0px !important;
    // }
    .portfolio-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .list-title {
        font-size: 22px;
        font-family: MuliSemiBold;
        // margin: 0px 10px;
      }
      .list-add-button {
        .btn-radivision {
          margin: 0px !important;
          padding: 7px 20px;
          font-size: 16px;
        }
      }
    }
    .portfolio-list-items {
      display: flex;
      flex-flow: row wrap;
      margin: 0px -10px;
      justify-content: flex-start;
      .portfolio-item {
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        width: 18%;
        height: 0px;
        margin: 10px 5px 50px 5px;
        padding-top: calc(18% + 10px);
        .portfolio-picture {
          width: 100%;
          border-radius: 50%;
          background-color: #313131;
          position: absolute;
          top: 0px;
          bottom: 10px;
          right: 0px;
          left: 0px;
          img {
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
            width: 100%;
          }
        }
        .delete-item {
          position: absolute;
          top: 0;
          right: 0;
          width: 32px;
          height: 32px;
          border-radius: 16px;
          overflow: hidden;
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
          svg {
            fill: white;
            background-color: black;
          }
          &.is-busy {
            display: block !important;
            background-color: white;
          }
          &:hover {
            svg {
              fill: $radivision-blue;
              background-color: white;
            }
          }
          .spinner {
            background-color: white;
            transform: scale(0.3) translateX(-20px) translateY(2px);
          }
        }
        .portfolio-item-title {
          margin: 10px 0px;
          font-size: 15px;
          text-align: center;
          a {
            color: white;
            &:hover {
              color: $radivision-blue;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .portfolio-list {
      .portfolio-list-items {
        justify-content: space-around;
        .portfolio-item {
          width: 40% !important;
          max-width: 40% !important;
          padding-top: calc(40% + 10px) !important;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .portfolio-item {
      width: 20%;
      max-width: 20%;
      .delete-item {
        display: none;
        &.is-busy {
          display: block;
        }
      }
      &:hover {
        .delete-item {
          display: block;
        }
      }
    }
  }
}

#manage-portfolio-modal {
  .added-items {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 25px 0px 25px 0px;
    min-height: 150px;
    .added-item {
      cursor: pointer;
      position: relative;
      width: 20%;
      height: 0px;
      margin: 10px 10px 50px 10px;
      padding-top: calc(20% + 10px);
      .item-picture {
        background-color: #313131;
        border-radius: 50%;
        position: absolute;
        background-size: cover;
        background-position: center center;
        top: 0px;
        bottom: 10px;
        right: 0px;
        left: 0px;
        img {
          object-fit: cover;
          border-radius: 50%;
          overflow: hidden;
        }
        .ring-loader {
          transform: translateX(5px);
        }
      }
      .item-title {
        font-size: 15px;
        font-family: MuliSemiBold;
        text-align: center;
        margin: 0px 0px 10px 0px;
      }
      .remove-item {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
        svg {
          fill: white;
          background-color: black;
        }
        &:hover {
          svg {
            fill: $radivision-blue;
            background-color: white;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .added-item {
      .remove-item {
        display: none;
      }
      &:hover {
        .remove-item {
          display: block;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
  }
}

#People.tab-pane.fade,
#Portfolio.tab-pane.fade {
  transition: none !important;
}
