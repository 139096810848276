/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-08-29
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
.FAQS {
  // padding: 0% 15% 3% 15%;
  width: 70%;
  margin: 0 auto;

  @media (max-width:1280px) {
    width: 85%;
  }

  @media (max-width:1024px) {
    width: 100%;
    padding: 35px;

  }

  @media (min-width:2000px) {
    width: 62%
  }

  @include media-breakpoint-down(md) {
    padding: 35px;
    width: 100%;
  }


  h1 {
    font-size: 2rem;
    padding-top: 5rem;

    @media (min-width:1024px) and (max-width:1280px) {
      font-size: 24px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 3rem;
      font-size: 16pt;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.7rem;
      padding-top: 2rem;
    }

    @media (min-width: 2500px) {
      font-size: 2.6rem;
      padding-top: 10rem;
    }
  }

  h4 {
    font-size: 16pt;

    @media (min-width:1024px) and (max-width:1280px) {
      font-size: 12pt;
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.9rem;
    }

    &::after {
      display: block;
      content: "";
      height: 0.2rem;
      background-color: #edeaea;
      margin: 3% 0%;
    }
  }

  .question-and-answer {
    div {
      margin-top: 4%;
      border-bottom: 2px solid #edeaea;
      position: relative;

      h3 {
        font-size: 13pt;

        @include media-breakpoint-down(md) {
          font-size: 12pt;
        }

        @include media-breakpoint-down(sm) {
          font-size: 11pt;
        }

        &:hover {
          color: #4cb4e7;
          cursor: pointer;
        }

        @media (min-width: 2500px) {
          font-size: 22pt;
        }
      }

      @keyframes topFadeIn {
        0% {

          opacity: 0;
        }

        50% {

          opacity: 0.5;
        }

        100% {
          opacity: 1;
        }
      }

      svg {
        width: 17px;
        fill: #4cb4e7;
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;

        // @include media-breakpoint-down(lg) {
        //   left: 23rem;
        // }

        // @include media-breakpoint-down(md)  {
        //   left: 17rem;
        // }

        @include media-breakpoint-down(sm) {
          width: 10px;
          // left: 16rem;
        }
      }

      .hide {
        display: none;
      }

      .show {
        margin-top: 0px;
        animation-name: topFadeIn;
        animation-duration: 1.5s;

        // @include media-breakpoint-down(md)  {
        //   margin-top: -15px;
        // }

        // @include media-breakpoint-down(sm) {
        //   margin-top: -20px;
        // }

      }

      .answer {
        position: relative;
        bottom: 22px;
        margin-left: 23px;

        @include media-breakpoint-down(md) {
          font-size: 10pt
        }

        @include media-breakpoint-down(sm) {
          font-size: 10pt;
        }

        a {
          color: #4cb4e7;
          margin-right: 2px;
          margin-left: 4px;
          font-size: 11pt;

          @media (min-width: 2500px) {
            font-size: 18pt;
          }

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .noImageIcon {
        position: relative;
        left: 1px;
        top: 3px;
        width: 18px;
        border: 0.7px solid;
        border-radius: 50%;
        padding: 2px;
        cursor: auto;
      }

      span {
        font-weight: bold;
        color: #4cb4e7;
      }
    }
  }

  .right-side {
    .right-side-content {
      border-left: 6px solid #edeaea;
      border-left-width: 3px;
      margin-left: 175px;
      padding-left: 36px;

      @include media-breakpoint-down(lg) {
        margin-left: 100px;
      }

      @include media-breakpoint-down(md) {
        margin-left: 50px;
      }

      @include media-breakpoint-down (sm) {
        border-left: none;
        margin-left: -35px;
        padding-top: 50px;
        margin-top: -25px;
      }

      div {
        margin-bottom: 30px;

        h3 {
          margin-bottom: 3%;
          font-size: 12pt;
          font-weight: 900;

          @media (min-width: 2500px) {
            font-size: 18pt;
          }

          @include media-breakpoint-down(md) {
            font-size: 10pt;
          }
        }

        a {
          color: #4cb4e7;

          &:hover {
            color: #4cb4e7;
            cursor: pointer;
          }
        }
      }

      .email-link {
        color: #4cb4e7;
        text-decoration: underline;
      }

      .or {
        margin-top: -5px;
        margin-bottom: 4px;
      }
    }
  }
}