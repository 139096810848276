/////////////////////////////////////////////////////////////////////////////////////////
// Created on: 2018-07-04
// author: Maria Osama
// Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
/////////////////////////////////////////////////////////////////////////////////////////
@import "../scss/imports.scss";

#Footer {
  margin: 0 auto;
  margin-bottom: 127px;

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 35px;
  }

  .disclaimer {
    p {
      font-size: 12px;
      color: #555555;
    }
    @include media-breakpoint-down(md) {
      padding: 10px 0px;
    }
  }

  & > div {
    margin: 0 auto;
    width: 90%;
    max-width: 1139px;
    color: white;
    padding: 45px 55px;
    padding-bottom: 0;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  img.img-fluid {
    @media (min-width: 2500px) {
      position: relative;
      margin-top: -20px;
      width: 320px;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 25px;
    padding-bottom: 0;
    margin-bottom: 0;

    .col-xs-6.col-md-5 {
      padding: 0;

      .col-4 {
        padding: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0px;
  }

  img.logo {
    margin-top: 20px;
    width: 68%;
    height: auto;
    margin-bottom: 32px;
    @media (min-width: 2000px) {
      width: 88%;
    }

    @include media-breakpoint-down(md) {
      width: 40%;
      max-width: 332px;
    }

    @include media-breakpoint-down(sm) {
      width: 60%;
    }
  }

  .social-icons {
    padding: 0;
    display: flex;
    justify-content: flex-start;

    span {
      display: none;
    }
    @include media-breakpoint-down(md) {
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      // justify-content: flex-end;
      display: flex;
    }

    li {
      margin-right: 13px;
      display: inline;

      button {
        border-radius: 50%;
        justify-content: center;
        background: white;
        border: 1px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;

        @include media-breakpoint-down(md) {
          width: 36px;
          height: 36px;
        }
      }

      button svg {
        margin: -5px;
        vertical-align: inherit;
        width: auto;
        height: 16px;

        &#facebookIcon {
          transform: translateX(4px);
        }

        &#twitterIcon {
          margin-top: -1px;
        }
      }
    }
  }

  h5 {
    color: $radivision-blue;
    margin-bottom: 32px !important;
    font-family: "Muli";
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;

    // text-transform: uppercase;
    // letter-spacing: 1px;

    @media (min-width: 2500px) {
      margin-bottom: 1rem;
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  ul {
    @include media-breakpoint-down(md) {
      margin-bottom: 0.5rem;
    }

    li {
      a {
        display: block;
        font-family: "Muli";
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
        color: white;
        cursor: pointer;
        margin-bottom: 18px;
        // letter-spacing: 1px;

        @media (min-width: 2500px) {
          font-size: 20px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }
  }

  .copyrights {
    @include media-breakpoint-down(sm) {
      font-size: 7pt;
    }
  }

  #facebookIcon :hover {
    fill: #3b5999;
  }

  #instagramIcom :hover {
    fill: #e4405f;
  }

  #twitterIcon :hover {
    fill: #55acee;
  }

  #linkedinIcon :hover {
    fill: #0077b5;
  }

  #youtubeIcon :hover {
    fill: #cd201f;
  }

  .newDesign {
    @include media-breakpoint-down(md) {
      .md4Responsive {
        div {
          flex: 0 0 33.3%;
          max-width: 33.3%;
          padding: 0;

          h5 {
            margin-bottom: 15px !important;
          }

          .list-unstyled {
            display: flex;
            flex-direction: column;
            // flex-flow: wrap;

            li {
              flex: calc(100% / 3);
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: row;

      .col-md-12 {
        padding: 0px !important;
      }
      .md4Responsive {
        flex-direction: row;
        margin: 0px !important;
        div {
          flex-basis: 1;
          max-width: 33%;
          min-width: 20%;
          h5 {
            margin-top: 18px;
          }
        }
        .col-4 {
          ul {
            display: flex;
            flex-direction: column;
            margin: 0px;
            padding: 0px;
            li {
              padding: 4px 0px;
              margin: 3px 0px;
              a {
                padding: 0px;
                margin: 0px;
                color: #aaa;
              }
            }
          }
          &:nth-child(1) {
            width: 25%;
            max-width: 25%;
          }
          &:nth-child(2) {
            width: 35%;
            max-width: 35%;
          }
          &:nth-child(3) {
            width: 40%;
            max-width: 40%;
          }
        }
      }

      // .col-title {
      //   display: none;
      // }
      .col-4 {
      }
    }
  }
}
